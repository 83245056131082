/* eslint-disable no-lone-blocks */
/* eslint-disable eqeqeq */
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Avatar,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonStyle from "../../Common/style/Style";
import styled from "@emotion/styled";
import TableDetails from "../../Common/Component/TableDetails";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { makeGetRequest } from "../../service";
import {
  OptionForFilter,
  OptionForFilterGoalie,
  optionForGoalie,
  optionForSkater,
  PageRoutes,
} from "../../utils/constant";
import SelectDropdownTeams from "../../Common/SelectDropdownTeam";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import "./index.css";
import GoalieStat from "./GoalieStat";
import handleSort from "../../hooks/useSort";
import { HdrPlus } from "@mui/icons-material";
import PlayerStatRow from "./PlayerStatRow";
import ExpandedPlayerStatRow from "./ExpandedPlayerStatsRow";
import SelectDropdownStat from "../../Common/SelectDropdownStat";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface Column {
  id:
    | "rank"
    | "rookie"
    | "active"
    | "jersey"
    | "player_name"
    | "plays"
    | "team_ab"
    | "games_played"
    | "goals"
    | "assists"
    | "points"
    | "points_per_game"
    | "ppg"
    | "ppa"
    | "shg"
    | "sha"
    | "gwg"
    | "shg"
    | "pims"
    | "penalty_minutes_per_game"
    | "plusminus"
    | "shog"
    | "sog"
    | "shooting_percentage";
  label: string;
  align?: "right" | "center" | "left";
  sort?: "asc" | "desc";
}

interface ExpandedColumn {
  id:
    | "rank"
    | "rookie"
    | "active"
    | "jersey"
    | "player_name"
    | "plays"
    | "team_ab"
    | "games_played"
    | "goals"
    | "assists"
    | "points"
    | "pims"
    | "points_per_game"
    | "penalty_minutes_per_game"
    | "plusminus"
    | "sog"
    | "ppg"
    | "ppa"
    | "shg"
    | "sha"
    | "gwg"
    | "first_goals"
    | "insurance_goals"
    | "otg"
    | "unassisted_goals"
    | "eng"
    | "shooting_percentage"
    | "shoa"
    | "shog"
    | "shgf"
    | "shootout_gwg"
    | "shootout_percentage";
  label: string;
  align?: "right" | "center" | "left";
  sort?: "asc" | "desc";
}

const columns: readonly Column[] = [
  { id: "rank", label: "" },

  { id: "active", label: "", align: "left" },
  { id: "jersey", label: "#", sort: "asc" },
  { id: "player_name", label: "Name", sort: "asc" },
  { id: "plays", label: "Pos" },
  { id: "team_ab", label: "Team", align: "center", sort: "asc" },
  { id: "games_played", label: "GP", align: "center", sort: "desc" },
  {
    id: "goals",
    label: "G",
    align: "center",
    sort: "desc",
  },
  {
    id: "assists",
    label: "A",
    align: "center",
    sort: "desc",
  },
  {
    id: "points",
    label: "PTS",
    align: "center",
    sort: "desc",
  },
  {
    id: "points_per_game",
    label: "Pt/G",
    align: "center",
    sort: "desc",
  },
  {
    id: "ppg",
    label: "PPG",
    align: "center",
    sort: "desc",
  },
  { id: "ppa", label: "PPA", sort: "desc" },
  { id: "shg", label: "SHG", sort: "desc" },
  { id: "sha", label: "SHA", sort: "desc" },
  {
    id: "gwg",
    label: "GWG",
    sort: "desc",
  },
  {
    id: "shog",
    label: "SOG",
    sort: "desc",
  },
  {
    id: "pims",
    label: "PIM",
    sort: "desc",
  },
  {
    id: "penalty_minutes_per_game",
    label: "PIMPG",
    sort: "desc",
  },
  {
    id: "plusminus",
    label: "+/-",
    sort: "desc",
  },
  {
    id: "sog",
    label: "SH",
    sort: "desc",
  },
  {
    id: "shooting_percentage",
    label: "SH%",
    sort: "desc",
  },
];
const expandedColumns: readonly ExpandedColumn[] = [
  { id: "rank", label: "" },
  { id: "active", label: "" },
  { id: "jersey", label: "#", sort: "asc" },
  { id: "player_name", label: "Name", sort: "asc" },
  { id: "plays", label: "Pos" },
  { id: "team_ab", label: "Team", align: "center", sort: "asc" },
  { id: "games_played", label: "GP", align: "center", sort: "desc" },
  { id: "goals", label: "G", align: "center", sort: "desc" },
  { id: "assists", label: "A", align: "center", sort: "desc" },
  { id: "points", label: "PTS", align: "center", sort: "desc" },
  { id: "pims", label: "PIM", align: "center", sort: "desc" },
  { id: "points_per_game", label: "Pt/G", align: "center", sort: "desc" },
  { id: "penalty_minutes_per_game", label: "PIMPG", sort: "desc" },
  { id: "plusminus", label: "+/-", align: "center", sort: "desc" },
  { id: "sog", label: "SH", align: "center", sort: "desc" },
  { id: "ppg", label: "PPG", sort: "desc" },
  { id: "ppa", label: "PPA", sort: "desc" },
  { id: "shg", label: "SHG", sort: "desc" },
  { id: "sha", label: "SHA", sort: "desc" },
  { id: "gwg", label: "GWG", sort: "desc" },
  { id: "first_goals", label: "FG", align: "center", sort: "desc" },
  { id: "insurance_goals", label: "IG", align: "center", sort: "desc" },
  { id: "otg", label: "OTG", align: "center", sort: "desc" },
  { id: "unassisted_goals", label: "UA", align: "center", sort: "desc" },
  { id: "eng", label: "EN", align: "center", sort: "desc" },
  { id: "shooting_percentage", label: "SH%", align: "center", sort: "desc" },
  { id: "shgf", label: "SHGF", sort: "desc" },
  { id: "shoa", label: "SSH", sort: "desc" },
  { id: "shootout_gwg", label: "SOWG", sort: "desc" },
  { id: "shootout_percentage", label: "SO%", align: "center", sort: "desc" },
];

const PlayerStat = (props: any) => {
  const {
    isAllTeam = true,
    isHeaderVisible = true,
    isGoalie = false,
    teamLogo,
  } = props;
  const [playersData, setPlayersData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [league, setLeague] = useState([]);
  const [teams, setTeams] = useState([]);
  const [stats, setStats] = useState([]);
  const [currentTeam, setCurrentTeam] = useState<any>({});
  const { id } = useParams();
  const [addRow, setAddRow] = useState(false);
  const level_id = searchParams.get("level_id");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: searchParams.get("sort") || "points",
    direction: searchParams.get("direction") || "desc",
  });
  const location = useLocation();

  const isGoalies =
    searchParams.get("player_type") == "goalie" ||
    (!searchParams.get("player_type") && isGoalie) ||
    (!searchParams.get("player_type") &&
      location.pathname.includes("/goalie-stats"))
      ? true
      : false;
  const isSakaters =
    searchParams.get("player_type") == "skater" ||
    (!searchParams.get("player_type") && !isGoalies)
      ? true
      : false;

  const isStanderdView =
    searchParams.get("view") == "standerd" || !searchParams.get("view")
      ? true
      : false;
  const [isStandard, setIsStandard] = useState<boolean>(
    isStanderdView ? true : false
  );

  const sortTable = (key: string, flag: any, sort: string) => {
    searchParams.set("sort", key);
    if (flag == "filter") {
      if (key) {
        {
          (isStandard ? columns : expandedColumns).forEach(() => {
            if (key == "rs") {
              searchParams.set("rookie", "true");
              setSearchParams(searchParams);
            } else {
            }
          });
        }

        searchParams.set("direction", sort);
        if (key == "goalies") {
          searchParams.set("direction", "asc");
          setSortConfig({ key: "goals_against_ave", direction: "asc" });
        } else {
          setSortConfig({ key, direction: sort });
        }
        setSearchParams(searchParams);
      } else {
        {
          (isStandard ? columns : expandedColumns).forEach((column) => {
            if (column.id == key) {
              const elementsList: any = document.querySelectorAll(`#points`);
              const elementsArray = [...elementsList];
              elementsArray.forEach((element) => {
                element?.classList.add(
                  "wtd-statview-table-highlight-column-color"
                );
              });
            }
          });
        }

        searchParams.delete("sort");
        searchParams.delete("direction");
        fetachSeason();
      }
    } else {
      {
        (isStandard ? columns : expandedColumns).forEach((column) => {
          if (column.id == key) {
          } else {
          }
        });
      }

      const currentSortDirection = searchParams.get("direction");
      if (sortConfig.key === key) {
        setSortConfig({
          key,
          direction: currentSortDirection === "asc" ? "desc" : "asc",
        });
        searchParams.set(
          "direction",
          currentSortDirection === "asc" ? "desc" : "asc"
        );
      } else {
        searchParams.set("direction", sort);
        setSortConfig({ key, direction: sort });
      }
      setSearchParams(searchParams);
    }
  };

  const fetchData = async (season_id: any, team_id: any, stat_id: any) => {
    let qurey: any;
    if (team_id && team_id != "all-teams") {
      qurey = {
        league_id: window?.customconfiguration?.league_id || 4,
        season_id: season_id,
        team_id: team_id,
      };
    } else {
      qurey = {
        league_id: window?.customconfiguration?.league_id || 4,
        season_id: season_id,
        team_id: "",
      };
    }
    if (level_id) {
      qurey.level_id = level_id;
    }
    if (stat_id) {
      qurey.stat_class = stat_id;
    }

    setIsSkeleton(true);
    const skater =
      searchParams.get("player_type") == "skater" ||
      (!searchParams.get("player_type") && !isGoalies)
        ? true
        : false;
    try {
      const response = await makeGetRequest(
        skater ? "get_skaters" : "get_goalies",
        new URLSearchParams(qurey).toString()
      );
      if (skater) {
        setPlayersData(response.skaters);
      } else {
        setPlayersData(response.goalies);
      }
      setTimeout(() => {
        const id: any = searchParams.get("sort") || "points";
        const elementsList: any = document.querySelectorAll(`#${id}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.add("wtd-statview-table-highlight-column-color");
        });
      }, 1);

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const fetachSeason = async () => {
    setIsSkeleton(true);
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );
      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);
      let filterdSeasonIdForTeam;
      if (
        searchParams.get("team_id") &&
        searchParams.get("team_id") != "all-teams"
      ) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!searchParams.get("season_id") || !searchParams.get("stat_class")) {
        setMultiplaleParams(qurey);
      }

      await fetchTeamData(
        filterSeasonId,
        searchParams.get("team_id"),
        searchParams.get("stat_class") || defaultStat[0].stat_class_id
      );

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const handleChangeTeam = async (e: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: searchParams.get("season_id"),
      team_id: e.target.value,
    };
    setMultiplaleParams(qurey);

    const filterData = teams?.filter((el: any) => el.team_id == e.target.value);
    setCurrentTeam(filterData[0]);
    await fetchData(
      searchParams.get("season_id"),
      e.target.value == "all-teams" ? "" : e.target.value,
      searchParams.get("stat_class")
    );
  };
  const fetchTeamData = async (
    season_id: any,
    team_id: any,
    stat_class: any
  ) => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id,
      stat_class,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    try {
      const respone = await makeGetRequest(
        "get_teams",
        new URLSearchParams(qurey).toString()
      );

      const findCurrntHomeTeam = respone.teams?.filter(
        (el: any) => el.team_id == team_id
      );

      let filterTeamId: any;
      if (findCurrntHomeTeam.length >= 1) {
        filterTeamId = findCurrntHomeTeam[0]?.team_id;
      } else {
        filterTeamId = "all-teams";
      }

      qurey = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: season_id,
        team_id:
          team_id == "all-teams"
            ? "all-teams"
            : isAllTeam
            ? filterTeamId
            : team_id,
      };
      const filterData = respone.teams?.filter(
        (el: any) => el.team_id == filterTeamId
      );
      setCurrentTeam(filterData[0]);
      setMultiplaleParams(qurey);

      setTeams(respone.teams);

      await fetchData(
        season_id,
        isAllTeam ? filterTeamId : team_id,
        stat_class
      );
    } catch (error) {}
  };

  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
    };

    fetchTeamData(
      event.target.value,
      searchParams.get("team_id") || "",
      searchParams.get("stat_class") || ""
    );

    setMultiplaleParams(params);
  };

  const handleStatChange = async (event: any) => {
    const params: any = {
      season_id: searchParams.get("season_id"),
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: event.target.value,
    };
    fetchTeamData(
      searchParams.get("season_id"),
      searchParams.get("team_id"),
      event.target.value
    );

    setMultiplaleParams(params);
  };

  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    isAllTeam && fetachSeason();
  }, []);

  useEffect(() => {
    if (!isAllTeam)
      fetchData(
        searchParams.get("season_id"),
        searchParams.get("team_id"),
        searchParams.get("stat_class")
      );
  }, [
    searchParams.get("season_id"),
    searchParams.get("team_id"),
    searchParams.get("stat_class"),
  ]);
  const selectFilter = (value: any) => {
    const filter = (
      isGoalies ? OptionForFilterGoalie : OptionForFilter
    )?.filter((el) => el.key == value);
    if (filter.length > 0) {
      return value;
    } else {
      return "";
    }
  };

  const newPlayersData = playersData?.map((player: any) => ({
    ...player,
    points_per_game: (Number(player?.games_played) !== 0
      ? Number(player.points) / Number(player.games_played)
      : 0
    )?.toFixed(2),
    penalty_minutes_per_game: (Number(player?.games_played) !== 0
      ? Number(player.pims) / Number(player.games_played)
      : 0
    )?.toFixed(2),
    shooting_percentage: (Number(player?.sog) !== 0
      ? Number(player?.goals) / Number(player?.sog)
      : 0
    ).toFixed(1),
    shootout_percentage: (Number(player.shoa) !== 0
      ? Number(player.shog) / Number(player.shoa)
      : 0
    ).toFixed(1),
  }));

  return (
    <>
      {isHeaderVisible && (
        <Box
          className="wtd-statview-filter"
          sx={{
            ...CommonStyle.tableControl,
            display: "flex",
            gap: 1,
            flexWrap: "wrap",
            px: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Select
            id="demo-simple-select-autowidth"
            autoWidth
            displayEmpty
            size="small"
            value={selectFilter(searchParams.get("sort")) || ""}
            onChange={(e: any) => sortTable(e.target.value, "filter", "desc")}
            defaultValue={""}
            sx={{
              minWidth: "180px",
              ".MuiSelect-select": {
                py: "4px",
              },
              border: "none",
              backgroundColor: "#fff",
              borderRadius: "0",
            }}
          >
            {(isGoalies ? OptionForFilterGoalie : OptionForFilter).map(
              (el: any) => (
                <MenuItem value={el.key}>{el.lable}</MenuItem>
              )
            )}
          </Select>

          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.get("rookie") ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    searchParams.set("rookie", "true");
                    setSearchParams(searchParams);
                  } else {
                    searchParams.delete("rookie");
                    if (searchParams.get("sort") == "rs") {
                      searchParams.delete("sort");
                    }
                    setTimeout(() => {
                      const id: any = searchParams.get("sort") || "points";
                      const elementsList: any = document.querySelectorAll(
                        `#${id}`
                      );
                      const elementsArray = [...elementsList];
                      elementsArray.forEach((element) => {
                        element?.classList.add(
                          "wtd-statview-table-highlight-column-color"
                        );
                      });
                    }, 1);
                    setSearchParams(searchParams);
                  }
                }}
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            }
            label="Rookie"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={searchParams.get("inactive") ? true : false}
                onChange={(e) => {
                  if (e.target.checked) {
                    searchParams.set("inactive", "true");
                    setSearchParams(searchParams);
                  } else {
                    searchParams.delete("inactive");
                    if (searchParams.get("sort") == "rs") {
                      searchParams.delete("sort");
                    }
                    setTimeout(() => {
                      const id: any = searchParams.get("sort") || "points";
                      const elementsList: any = document.querySelectorAll(
                        `#${id}`
                      );
                      const elementsArray = [...elementsList];
                      elementsArray.forEach((element) => {
                        element?.classList.add(
                          "wtd-statview-table-highlight-column-color"
                        );
                      });
                    }, 1);
                    setSearchParams(searchParams);
                  }
                }}
                sx={{
                  color: "#ffffff",
                  "&.Mui-checked": {
                    color: "#ffffff",
                  },
                }}
              />
            }
            label="Inactive"
          />
          <Select
            id="demo-simple-select-autowidth"
            autoWidth
            size="small"
            displayEmpty
            value={
              searchParams.get("position") && isGoalies
                ? searchParams.get("position")
                : !searchParams.get("position") && isGoalies
                ? "Q"
                : !searchParams.get("position") && !isGoalies
                ? "S"
                : searchParams.get("position")
            }
            onChange={(e: any) => {
              searchParams.set("position", e.target.value);
              setSearchParams(searchParams);
            }}
            defaultValue={"Q"}
            sx={{
              minWidth: "180px",
              ".MuiSelect-select": {
                py: "4px",
              },
              border: "none",
              backgroundColor: "#fff",
              borderRadius: "0",
            }}
          >
            {isGoalies
              ? optionForGoalie.map((el) => (
                  <MenuItem value={el.key}>{el.lable}</MenuItem>
                ))
              : optionForSkater.map((el) => (
                  <MenuItem value={el.key}>{el.lable}</MenuItem>
                ))}
          </Select>
          <SelectDropdownLeague league={league} handleChange={handleChange} />
          <SelectDropdownTeams
            teams={teams}
            handleChangeTeam={handleChangeTeam}
          />
          <SelectDropdownStat stats={stats} handleChange={handleStatChange} />
          {!isGoalies && (
            <ButtonGroup
              sx={{
                boxShadow: "none",
                borderColor: "transparent",
                border: "1px solid transparent",
                ".MuiButtonGroup-grouped:not(:last-of-type)": {
                  borderColor: "transparent",
                },
              }}
              variant="contained"
            >
              <Button
                className={`wtd-statview-button-color ${
                  isStandard ? "active" : ""
                }`}
                sx={{
                  ...CommonStyle.btnSwitch,
                  backgroundColor: isStandard ? "#ffb931" : "#000000",
                  fontWeight: isStandard ? 600 : 200,
                }}
                onClick={() => {
                  searchParams.set("view", "standerd");
                  setSearchParams(searchParams);
                  setIsStandard(false);
                  setIsStandard(true);
                }}
              >
                Standard
              </Button>
              <Button
                className={`wtd-statview-button-color ${
                  !isStandard ? "active" : ""
                }`}
                sx={{
                  ...CommonStyle.btnSwitch,
                  backgroundColor: !isStandard ? "#ffb931" : "#000000",
                  fontWeight: !isStandard ? 600 : 200,
                }}
                onClick={() => {
                  searchParams.set("view", "expanded");
                  setSearchParams(searchParams);
                  setIsStandard(false);
                }}
              >
                Expanded
              </Button>
            </ButtonGroup>
          )}
        </Box>
      )}
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "80vh" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box>
          {!isGoalies ? (
            <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
              <TableContainer sx={{ maxHeight: "auto" }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {(isStandard && isSakaters
                        ? columns
                        : expandedColumns
                      ).map((column) => (
                        <TableCell
                          id={column.id}
                          key={column.id}
                          align={column.align}
                          sx={{
                            ...CommonStyle.tableHeader,
                            padding: "0px 5px 0px 5px",
                            backgroundColor:
                              sortConfig.key === column.id
                                ? "#cecece"
                                : "#ffb931",
                          }}
                          className="wtd-statview-header-color"
                        >
                          {["rank", "rookie", "active", "plays"].includes(
                            column.id
                          ) ? (
                            column.label
                          ) : (
                            <a
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: column.align,
                              }}
                              onClick={() =>
                                sortTable(
                                  column.id,
                                  "table",
                                  column.sort ? column.sort : "desc"
                                )
                              }
                            >
                              {column.label}
                              {sortConfig.key === column.id && (
                                <span style={{ display: "flex" }}>
                                  {sortConfig.direction === "asc" ? (
                                    <ArrowDropUpIcon />
                                  ) : (
                                    <ArrowDropDownIcon />
                                  )}
                                </span>
                              )}
                            </a>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {newPlayersData
                      .sort((a, b) =>
                        handleSort(a, b, {
                          ...sortConfig,
                          key:
                            sortConfig.key == "player_name"
                              ? "lname"
                              : sortConfig.key,
                        })
                      )
                      .filter((el: any) => {
                        if (searchParams.get("rookie")) {
                          return el?.display_flags == "R";
                        } else {
                          return el;
                        }
                      })
                      .filter((el: any) => {
                        if (searchParams.get("inactive")) {
                          return el;
                        } else {
                          console.log(el.active);
                          return el?.active == 1;
                        }
                      })
                      .filter((el: any) => {
                        if (searchParams.get("position")) {
                          return el.plays == searchParams.get("position");
                        } else {
                          return el;
                        }
                      })
                      .map((playerItem: any, index: number) => {
                        if (isStandard && isSakaters) {
                          return (
                            <PlayerStatRow
                              teamLogo={props?.teamLogo}
                              playerItem={playerItem}
                              index={index}
                              sortKey={sortConfig.key}
                            />
                          );
                        } else {
                          return (
                            <ExpandedPlayerStatRow
                              teamLogo={props?.teamLogo}
                              index={index}
                              playerItem={playerItem}
                              sortKey={sortConfig.key}
                            />
                          );
                        }
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          ) : (
            <GoalieStat
              teamLogo={props?.teamLogo}
              isGoalie={isGoalie}
              playersData={playersData
                .sort((a, b) =>
                  handleSort(a, b, {
                    ...sortConfig,
                    key:
                      sortConfig.key == "player_name"
                        ? "lname"
                        : sortConfig.key,
                  })
                )
                .filter((el: any) =>
                  searchParams.get("position") == "Q" ||
                  !searchParams.get("position")
                    ? el?.qualified_goalie == 1
                    : el
                )}
              sortConfig={sortConfig}
              setSortConfig={setSortConfig}
              currentTeam={currentTeam}
              fetachSeason={() => fetachSeason()}
            />
          )}
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          py: 2,
          px: 1,
        }}
      >
        <Box>* indicates rookie</Box>
        <Box>x indicates inactive</Box>
      </Box>
      <TableDetails />
    </>
  );
};

export default PlayerStat;
