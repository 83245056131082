import { Box } from "@mui/material";
import React from "react";

import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Style from "../Component/MainContainer/MainContainer.style";

function PublicRoute({
  children,
}: {
  children: React.ReactElement;
}): React.ReactElement {
  return (
    <Box sx={{ ...Style.MainContainerStyle, ml: 0, minHeight: "100vh" }}>
      {/* <Header /> */}
      <Box>{children}</Box>
      {/* <Footer /> */}
    </Box>
  );
}

export default PublicRoute;
