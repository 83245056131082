import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Paper,
  TableCell,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableTitle from "../../../Pages/GameCenter/TableTitle";
import styled from "@emotion/styled";
import CommonStyle from "../../style/Style";
import TableMain from "../../../Pages/GameCenter/TableMain";
import teamLogo from "../../images/team-logo.png";
import { PageRoutes } from "../../../utils/constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import { makeGetRequest } from "../../../service";

const StandingTable = () => {
  const [selectedDiv, setSelectedDiv] = useState({ name: "", id: "1" });
  const [standings, setStandings] = useState([]);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const team_id = window?.customconfiguration?.team_id || "";
  const navigation = useNavigate();
  const level_id = searchParams.get("level_id");

  const ButtonItem = styled(Paper)(({ theme }: any) => ({
    padding: theme.spacing(1),
    textAlign: "center",
    borderRadius: 0,
  }));
  const getStanding = async () => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4,
      season_id: 0,
      stat_class: 1,
      team_id: team_id,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    setIsSkeleton(true);
    const resTeamData = await makeGetRequest(
      "get_team_info",
      `team_id=${team_id}`
    );
    const currentSeason = resTeamData?.leagues[0]?.current_season;
    const filterData = resTeamData?.levels?.filter(
      (el: any) => el?.season_id == currentSeason
    );

    try {
      const res = await makeGetRequest(
        "get_standings",
        new URLSearchParams(qurey).toString()
      );
      const array = res?.standings?.leagues[0].levels[0]?.conferences;
      setStandings(res?.standings?.leagues[0].levels[0]?.conferences);
      const currentDiv = array.filter(
        (el: any) => el.id == filterData[0].conf_id
      );
      setSelectedDiv({
        name: currentDiv[0].conf_short_name,
        id: currentDiv[0].id,
      });
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    getStanding();
  }, []);
  if (isSkeleton) {
    return (
      <Box sx={{ position: "relative", height: "400px", width: "350px" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }
  return (
    <Box sx={{ width: "100%", maxWidth: "350px" }}>
      <TableTitle title={"Standings"} />
      <Grid container rowSpacing={1} columnSpacing={1}>
        {standings?.map((el: any, index) => (
          <Grid key={index + 1} item xs={6}>
            <ButtonItem
              className={`wtd-statview-button-color ${
                selectedDiv.id == el?.id ? "active" : ""
              }`}
              sx={{
                ...CommonStyle.btnSwitch,
                cursor: "pointer",
                backgroundColor:
                  selectedDiv.id == el?.id ? "#ffb931" : "#000000",
                fontWeight: selectedDiv.id == el?.id ? 600 : 200,
                color: selectedDiv.id == el?.id ? "#000000" : "#ffffff",
              }}
              onClick={() => {
                setSelectedDiv({ name: el.conf_short_name, id: el.id });
              }}
            >
              {el.conf_short_name}
            </ButtonItem>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} sx={{ py: 2 }}>
        <Grid item lg={12} xs={12} md={12}>
          {/* {standings.filter((el: any) => el?.id == selectedDiv)} */}
          <TableMain
            headers={[
              {
                id: "logo",
                label: selectedDiv.name,
                type: "img",
              },

              { id: "games_played", label: "GP" },
              { id: "pts", label: "PTS" },
              { id: "record", label: "Record" },
            ]}
            listData={
              (standings as any)?.filter(
                (el: any) => el?.id == selectedDiv.id
              )[0]?.teams
            }
          />
        </Grid>
      </Grid>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Box style={{ textDecoration: "none" }}>
            <ButtonItem
              className={`wtd-statview-button-color`}
              sx={{
                ...CommonStyle.btnSwitch,
                backgroundColor: "#000000",
                color: "#ffffff",
                cursor: "pointer",
              }}
            >
              <Link
                sx={{ color: "black", textDecoration: "none" }}
                href={`${PageRoutes.StandingStat}?league_id=${
                  window?.customconfiguration?.league_id || 4 || 4
                }`}
              >
                See Full Standings
              </Link>
            </ButtonItem>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StandingTable;
