const defaultRoutes = { ///change to defaultRoutes was PageRoutes
  RosterPage: "/roster",
  GoalieStat: "/goalie-stats",
  PlayerStat: "/skater-stats",
  StandingStat: "/standings",
  TeamStats: "/team-stats",
  GameCenter: "/game-center",
  TeamScore: "/score",
  PlayerProfile: "/player-profile",
  DailyReport: "/league-today",
  Schedule: "/schedule",
  DailySchedule: "/daily-schedule",
  Empty: "/",
  Attendace: "/attendance",
  Streaks: "/streaks",
  Transaction: "/transactions",
  PlayerSearch: "/player-search",
  TeamDetail: "/team-details",
  HeadToHeadTeams: "/head-to-head-teams",
};

function getRoutes() {
  const customRoutes = window.customconfiguration?.routes;///routes entered in script element of html
  return { ...defaultRoutes, ...customRoutes };
}

const PageRoutes = getRoutes();

const APIRoutes = {
  RefreshToken: "",
};

export const CoachRole = [
  { role: "Head Coach", postion: "X" },
  { role: "Assistant Coach", postion: "Y" },
  { role: "Student Coach ", postion: "Z" },
];
export const PlayerPosition = {
  forward: ["F", "RW", "LW", "C"],
  defense: ["D", "RD", "LD"],
  goalie: ["G"],
};
export const DisplayFlag = [
  { name: "Rookie", sign: "R" },
  { name: "Injured", sign: "+" },
  { name: "Suspended", sign: "S" },
  { name: "Affiliate", sign: "A" },
  { name: "Committed", sign: "C" },
  { name: "Emergency", sign: "E" },
  { name: "Goaltender", sign: "G" },
];
export const OptionForFilter = [
  { key: "", lable: "Choose Quick View" },
  { key: "points", lable: "Top Scorers" },

  { key: "goals", lable: "Goals" },
  { key: "assists", lable: "Assists" },
  { key: "ppg", lable: "Power Play Goals" },
  { key: "ppa", lable: "Power Play Assists" },
  { key: "shg", lable: "Short Handed Goals" },
  { key: "sha", lable: "Short Handed Assists" },
  { key: "og", lable: "Overtime Goals" },
  { key: "gwg", lable: "Game Winning Goals" },
  { key: "fg", lable: "First Goals" },
  { key: "ig", lable: "Insurance Goals" },
  { key: "plusminus", lable: "Plus/Minus" },
  { key: "sog", lable: "Shootout Goals" },
  { key: "rs", lable: "Rookie Scoring" },
];
export const OptionForFilterGoalie = [
  { key: "", lable: "Choose Quick View" },
  { key: "goalies", lable: "Top Goalies" },

  {
    key: "games_played",
    lable: "Games Played",
  },
  {
    key: "wins",
    lable: "Wins",
  },
  {
    key: "losses",
    lable: "Losses",
  },
  {
    key: "ot_losses",
    lable: "OT Losses",
  },
  {
    key: "so_losses",
    lable: "Shootout Losses",
  },
  {
    key: "shots_against",
    lable: "Shots Against",
  },
  {
    key: "goals_against",
    lable: "Goals Against",
  },
  {
    key: "goals_against_ave",
    lable: "GAA",
  },
  {
    key: "save_pct",
    lable: "SV%",
  },
  {
    key: "shutouts",
    lable: "Shutouts",
  },
  {
    key: "toi",
    lable: "Minutes",
  },
];
export const optionForSkater = [
  { key: "S", lable: "Skaters" },
  { key: "F", lable: "Forwards" },
  { key: "D", lable: "Defensemen" },
];
export const optionForGoalie = [
  { key: "A", lable: "All Goalies" },
  { key: "Q", lable: "Qualified Goalies" },
];
export const MonthsOption = [
  { key: "01", label: "January" },
  { key: "02", label: "February" },
  { key: "03", label: "March" },
  { key: "04", label: "April" },
  { key: "05", label: "May" },
  { key: "06", label: "June" },
  { key: "07", label: "July" },
  { key: "08", label: "August" },
  { key: "09", label: "September" },
  { key: "10", label: "October" },
  { key: "11", label: "November" },
  { key: "12", label: "December" },
];
export const optionForStanding = [
  { key: "", lable: "Choose Quick View" },
  { key: "league-overall", lable: "League (Overall)" },
  { key: "division-overall", lable: "Division (Overall" },
  { key: "division-home", lable: "Division (Home)" },
  { key: "division-away", lable: "Division (Away)" },

  { key: "power_play_pct-overall-spacial", lable: "Power Play (Overall)" },
  { key: "power_play_pct-home-spacial", lable: "Power Play (Home)" },
  { key: "power_play_pct-away-spacial", lable: "Power Play (Away)" },
  {
    key: "penalty_kill_pct-overall-spacial",
    lable: "Penalty Killing (Overall)",
  },
  { key: "penalty_kill_pct-home-spacial", lable: "Penalty Killing (Home)" },
  { key: "penalty_kill_pct-away-spacial", lable: "Penalty Killing (Away)" },
  { key: "ot-overall", lable: "Overtime Games (Overall)" },
  { key: "ot-home", lable: "Overtime Games (Home)" },
  { key: "ot-away", lable: "Overtime Games (Away)" },
  { key: "pims-overall-filter", lable: "Penalty Minutes (Overall)" },
];
export { PageRoutes, APIRoutes };
