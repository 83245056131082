import { Box, Button, ButtonGroup, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PlayerSearchTable from "./table";
import CommonStyle from "../../Common/style/Style";
import { useSearchParams } from "react-router-dom";
import SelectDropdownTeams from "../../Common/SelectDropdownTeam";
import { makeGetRequest } from "../../service";

const PlayerSearch = () => {
  const [active, setActive] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState<any>({});

  const handleChangeTeam = async (e: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: searchParams.get("season_id"),
      team_id: e.target.value,
    };

    setSearchParams(qurey);
    const filterData = teams?.filter((el: any) => el.team_id == e.target.value);
    setCurrentTeam(filterData[0]);
  };
  const level_id = searchParams.get("level_id");
  const fetchTeamData = async (season_id: any, team_id: any) => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id,
    };

    if (level_id) {
      qurey.level_id = level_id;
    }
    try {
      const respone = await makeGetRequest(
        "get_teams",
        new URLSearchParams(qurey).toString()
      );
      const findCurrntHomeTeam = respone.teams?.filter(
        (el: any) => el.team_id == team_id
      );

      let filterTeamId: any;
      if (findCurrntHomeTeam.length >= 1) {
        filterTeamId = findCurrntHomeTeam[0]?.team_id;
      } else {
        filterTeamId = respone.teams[0].team_id;
      }
      qurey = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: season_id,
        team_id: filterTeamId,
      };

      const filterData = respone.teams?.filter(
        (el: any) => el.team_id == filterTeamId
      );

      setCurrentTeam(filterData[0]);
      setSearchParams(qurey);

      setTeams(respone.teams);
    } catch (error) {}
  };
  useEffect(() => {
    fetchTeamData(1, searchParams.get("team_id"));
  }, []);
  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 800, fontSize: 18, my: 1 }}
        ml={2}
      >
        Player Search
      </Typography>
      <Box
        className="wtd-statview-filter"
        sx={{
          ...CommonStyle.tableControl,
          display: "flex",
          gap: 1,
          px: 1,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <TextField
          variant="outlined"
          placeholder=""
          size="small"
          sx={{
            minWidth: "180px",
            border: "none",
            backgroundColor: "#fff",
            borderRadius: "0",
          }}
        />
        <SelectDropdownTeams
          teams={teams}
          handleChangeTeam={handleChangeTeam}
        />
        <ButtonGroup
          sx={{
            boxShadow: "none",
            borderColor: "transparent",
            border: "1px solid transparent",
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "transparent",
            },
          }}
          variant="contained"
        >
          <Button
            className={`wtd-statview-button-color ${active ? "active" : ""}`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: active ? "#ffb931" : "#000000",
              fontWeight: active ? 600 : 200,
            }}
            onClick={() => {
              searchParams.set("player", "active");
              setSearchParams(searchParams);
              setActive(false);
              setActive(true);
            }}
          >
            Active
          </Button>
          <Button
            className={`wtd-statview-button-color ${!active ? "active" : ""}`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: !active ? "#ffb931" : "#000000",
              fontWeight: !active ? 600 : 200,
              ml: 0.5,
            }}
            onClick={() => {
              searchParams.set("player", "all");
              setSearchParams(searchParams);
              setActive(false);
            }}
          >
            All Players
          </Button>
          <Button
            className={`wtd-statview-button-color `}
            sx={{
              ...CommonStyle.btnSwitch,
              ml: 1,
            }}
            onClick={() => {
              console.log("click");
            }}
          >
            Search
          </Button>
        </ButtonGroup>
      </Box>
      <PlayerSearchTable />
    </Box>
  );
};

export default PlayerSearch;
