import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CommonStyle from "../../Common/style/Style";
import TableMain from "../GameCenter/TableMain";
import TableWithTotal from "../GameCenter/TableWithTotal";
import SelectDropdownLeagueForPlayer from "./SelectDropdownLeagueForPlayer";
import SelectDropdownLeagueStat from "../../Common/SelectDropdownLeagueStat";

const GoalieProfile = ({
  handleChange,
  playerData,
  isTeamSkeleton,
  playerStates,
  league,
  id,
  handleLeagueChange,
  selectedStat,
  playerLeague,
  selectedLeague,
  playerStat,
  filteredPlayerData,
  handleStatChange,
}: any) => {
  const [imgOpen, setImgOpen] = useState({ imgSrc: "", open: false });
  console.log(filteredPlayerData);
  return (
    <Box>
      <Grid container spacing={2} sx={{ px: 2, py: 1 }}>
        {/* Image */}
        <Grid item lg={2} md={3} sm={2} xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center" },
              margin: "10px 6px",
            }}
          >
            <Avatar
              src={playerData?.player_image}
              sx={{
                borderRadius: "50%",
                border: "4px solid #000000",
                width: { md: "170px", xs: "100px" },
                height: { md: "170px", xs: "100px" },
              }}
            />
          </Box>
        </Grid>

        {/* Info */}
        <Grid
          item
          lg={8}
          md={9}
          sm={10}
          xs={12}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Grid container>
            {/* Player Details */}
            <Grid
              item
              lg={7}
              md={7}
              sm={6}
              xs={12}
              sx={{
                display: "flex",
              }}
            >
              <Box
                sx={{
                  padding: "15px 10px 0 10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: "5rem",
                    fontSize: { md: "350%", xs: "200%" },
                    fontWeight: "800",
                    paddingRight: "10px",
                    paddingBottom: "20px",
                    borderRight: "1px solid #999999",
                  }}
                >
                  {playerData?.jersey}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "15px 10px 0 0",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: { md: "152%", xs: "120%" },
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }}
                >
                  {playerData.player_name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "600",
                    fontSize: { md: "132%", xs: "100%" },
                    textTransform: "uppercase",
                    color: "#666666",
                    margin: "15px 0",
                  }}
                >
                  {playerData?.team_name}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={5} md={5} xs={12} sm={6}>
              <Box sx={{ padding: "15px 10px 0 40px", color: "#666666" }}>
                <Typography>Position:{playerData?.plays}</Typography>
                <Typography>
                  {playerData?.height} / {playerData?.weight}lbs
                </Typography>
                <Typography>Catches: {playerData?.shoots}</Typography>
                <Typography>Birthdate: {playerData?.birthdate}</Typography>
                <Typography>
                  Home town: {playerData?.town}
                  {playerData?.town ? "," : ""} {playerData?.state}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* Team */}
        <Grid
          item
          lg={2}
          sx={{
            display: { lg: "block", md: "none", xs: "none", sm: "none" },
          }}
        >
          <img
            src={playerData?.team_smlogo}
            alt={playerData?.team_smlogo}
            style={{
              padding: "15px",
              width: "170px",
              height: "auto",
            }}
          />
        </Grid>
      </Grid>
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "600",
            fontSize: "110%",
            margin: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Player Totals
        </Typography>
        <Grid
          container
          sx={{ py: 1, display: "flex", justifyContent: "center" }}
        >
          <Grid
            item
            lg={1}
            md={1}
            sm={1.5}
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
            }}
          >
            <Typography variant="body1">GP</Typography>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {playerData?.goalie_games_played}
            </Typography>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1.5}
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderLeft: "1px solid #000000",
              borderRight: "1px solid #000000",
            }}
          >
            <Typography variant="body1">W</Typography>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {playerData?.wins}
            </Typography>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1.5}
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRight: "1px solid #000000",
            }}
          >
            <Typography variant="body1">L</Typography>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {playerData?.losses}
            </Typography>
          </Grid>

          <Grid
            item
            lg={1}
            md={1}
            sm={1.5}
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRight: "1px solid #000000",
            }}
          >
            <Typography variant="body1">SO</Typography>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {playerData?.shutouts}
            </Typography>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1.5}
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRight: "1px solid #000000",
            }}
          >
            <Typography variant="body1">GAA</Typography>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {playerData?.goals_against_ave}
            </Typography>
          </Grid>
          <Grid
            item
            lg={1}
            md={1}
            sm={1.5}
            xs={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              borderRight: "1px solid #000000",
            }}
          >
            <Typography variant="body1">SV%</Typography>
            <Typography variant="h5" sx={{ fontWeight: "600" }}>
              {playerData?.save_pct}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {playerData?.player_bios && (
        <Box>
          <Box
            className="wtd-statview-filter"
            sx={{
              ...CommonStyle.tableControl,
              display: "flex",
              gap: 1,
              px: 1,
              fontWeight: "600",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 800, fontSize: 18 }}
            >
              {playerData.player_name} Biography
            </Typography>
          </Box>
          <Box>
            {playerData?.player_bios &&
              playerData?.player_bios.map((el: any) => (
                <Box sx={{ p: 2 }}>
                  {el?.player_bio_url ? (
                    <a
                      dangerouslySetInnerHTML={{
                        __html: el?.player_bio_title,
                      }}
                      href={el?.player_bio_url}
                      target="blank"
                    ></a>
                  ) : (
                    <Typography
                      dangerouslySetInnerHTML={{
                        __html: el?.player_bio_title,
                      }}
                      variant="body1"
                    ></Typography>
                  )}
                </Box>
              ))}
          </Box>
        </Box>
      )}
      <Box sx={{ py: 5 }}>
        <Box
          className="wtd-statview-filter"
          sx={{
            ...CommonStyle.tableControl,
            display: "flex",
            gap: 1,
            px: 1,
            fontWeight: "600",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 800, fontSize: 18 }}
          >
            {playerData.player_name} Player Stats
          </Typography>
          <Box sx={{ display: "flex", gap: 2 }}>
            <SelectDropdownLeagueStat
              data={playerLeague}
              value={selectedLeague}
              handleChange={handleLeagueChange}
            />
            <SelectDropdownLeagueStat
              data={playerStat}
              value={selectedStat}
              handleChange={handleStatChange}
            />
          </Box>
        </Box>
        {/* <TableContainer sx={{ maxHeight: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={CommonStyle.tableTitle}
                    className="wtd-statview-group-header-color"
                    colSpan={7}
                  >
                    Regular Season
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer> */}
        <TableWithTotal
          headers={[
            { id: "season_name", label: "Season Name", total: false },
            { id: "team_name", label: "Team Name", total: false },
            { id: "goalie_games_played", label: "GP", total: true },
            { id: "wins", label: "W", total: true },
            { id: "losses", label: "L", total: true },
            { id: "ot_losses", label: "OTL", total: true },
            { id: "so_losses", label: "SOL", total: true },
            { id: "toi", label: "Min", total: true },
            { id: "goals_against", label: "GA", total: true },
            { id: "shutouts", label: "SO", total: true },
            { id: "goals_against_ave", label: "GAA", total: true },
            { id: "shots_against", label: "SA", total: true },
            { id: "saves", label: "SVS", total: true },
            { id: "save_pct", label: "SV%", total: true },
          ]}
          listData={
            filteredPlayerData?.teams &&
            filteredPlayerData?.teams
              ?.sort((a: any, b: any) => (a.start_date < b.start_date ? 1 : -1))
              .map((el: any) => el)
          }
          playerData={filteredPlayerData}
        />
      </Box>
      <Box sx={{ py: 2 }}>
        <Box
          className="wtd-statview-filter"
          sx={{
            ...CommonStyle.tableControl,
            display: "flex",
            gap: 1,
            px: 1,
            fontWeight: "600",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 800, fontSize: 18 }}
          >
            {playerData.player_name} Game by Game
          </Typography>
          <SelectDropdownLeagueForPlayer
            league={league}
            handleChange={handleChange}
          />
        </Box>
        {isTeamSkeleton ? (
          <Box sx={{ position: "relative", height: "80vh" }}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <TableMain
            headers={[
              { id: "matchup_name", label: "Game" },
              { id: "date", label: "Date" },
              { id: "win", label: "W" },
              { id: "loss", label: "L" },

              { id: "ot_loss", label: "OTL" },
              { id: "so_loss", label: "SOL" },
              { id: "toi", label: "Min" },
              { id: "goals_against", label: "GA" },
              { id: "shutout", label: "SO" },
              { id: "goals_against_ave", label: "GAA" },
              { id: "shots", label: "SA" },
              { id: "saves", label: "SVS" },
              { id: "save_pct", label: "SV%" },
            ]}
            listData={playerStates?.map((el: any) => el)}
          />
        )}
      </Box>
      <Box sx={{ py: 2 }}>
        <Box
          className="wtd-statview-filter"
          sx={{
            ...CommonStyle.tableControl,
            display: "flex",
            gap: 1,
            px: 1,
            fontWeight: "600",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 800, fontSize: 18 }}
          >
            {playerData.player_name} Player Media
          </Typography>
        </Box>
        {playerData?.player_media ? (
          <Box>
            <TableMain
              headers={[{ id: "img", label: "Images" }]}
              listData={[]}
            />
            <Box
              sx={{
                my: 2,
                gap: 1,
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(100px, 1fr))",
              }}
            >
              {playerData?.player_media &&
                playerData?.player_media.map((item: any) => (
                  <img
                    width="100px"
                    height="100px"
                    src={item?.player_media_url}
                    srcSet={item}
                    alt={item}
                    style={{ objectFit: "cover", cursor: "pointer" }}
                    loading="lazy"
                    onClick={() => {
                      setImgOpen({
                        imgSrc: item?.player_media_url,
                        open: true,
                      });
                    }}
                  />
                ))}
              <Dialog
                sx={{ maxWidth: "500px", margin: "auto" }}
                onClose={() => setImgOpen({ imgSrc: "", open: false })}
                open={imgOpen.open}
              >
                <img src={imgOpen.imgSrc} alt={imgOpen.imgSrc} width="100%" />
              </Dialog>
            </Box>
          </Box>
        ) : (
          <Box sx={{ textAlign: "center", py: 2 }}>NO DATA WAS FOUND</Box>
        )}
      </Box>
    </Box>
  );
};

export default GoalieProfile;
