import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";

const LastGamesTable = (props: any) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.headers?.map((headItem: any) => (
                <TableCell
                  key={headItem.id}
                  align={headItem?.align ? headItem.align : "center"}
                  sx={CommonStyle.tableHeader}
                  className="wtd-statview-header-color"
                  colSpan={2}
                >
                  {headItem.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listData?.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index + 1}
                  className="wtd-statview-table-row-color"
                >
                  <TableCell
                    sx={{ py: 1 }}
                    align={props?.align ? props.align : "left"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 5,
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography sx={{ width: "40px" }}>
                        {row.away_goals}-{row?.home_goals}
                      </Typography>{" "}
                      <Typography>{row?.result}</Typography>
                      <Typography>
                        {row.opp_seperator} {row.opp_name}
                      </Typography>
                    </Box>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default LastGamesTable;
