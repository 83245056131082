import {
    Avatar,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
import React from 'react'
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonStyle from "../../Common/style/Style";
import { CoachRole, PageRoutes } from "../../utils/constant";



interface Column {
    id:
      | "player"
      | "currteam"
      | "dob"
      | "birthplace"
    label: string;
    align?: "right";
  }
  
  const columns: readonly Column[] = [
   {
      id: "player",
      label: "Player",
    },
    {
      id: "currteam",
      label: "Team",
    },
    {
      id: "dob",
      label: "Birthdate",
    },
    {
      id: "birthplace",
      label: "Birth Place",
    },
  ];
  export const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#cecece",
    },
  }));


const PlayerSearchTable = () => {
    const navigate = useNavigate();
    const player = ["player1","player2","player3","player4","player5","player6","player7","player8","player9","player10","player11","player12"];
    // const [searchParams, set,[SearchParams] = useSearchParams();
    return (
      <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
        <TableContainer sx={{ maxHeight: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                      <TableCell
                        // key={column.id}
                        align="left"
                        sx={CommonStyle.tableHeader}
                        className="wtd-statview-header-color"
                      >
                        Player
                      </TableCell>
                      <TableCell
                        // key={column.id}
                        align="left"
                        sx={CommonStyle.tableHeader}
                        className="wtd-statview-header-color"
                      >
                        Current Team
                      </TableCell>
                      <TableCell
                        // key={column.id}
                        align="left"
                        sx={CommonStyle.tableHeader}
                        className="wtd-statview-header-color"
                      >
                        Birthdate
                      </TableCell>
                      <TableCell
                        // key={column.id}
                        align="left"
                        sx={CommonStyle.tableHeader}
                        className="wtd-statview-header-color"
                      >
                        Birth place
                      </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                {player.map((item:any,idx)=>(
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    className="wtd-statview-table-row-color"
                  >
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Avatar src="" />
                        <Typography
                          variant="body2"
                          sx={{
                            ":hover": {
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontWeight: 600,
                            },
                          }}
                        >
                          {item}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        <Avatar src="" />
                        <Typography
                          variant="body2"
                          sx={{
                            ":hover": {
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontWeight: 600,
                            },
                          }}
                        >
                          Team {idx}
                        </Typography>
                      </Box>
                    </TableCell>
  
                    <TableCell sx={{ py: 1 }} align={"left"}>
                     2004-04-10
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                     {item.display_hometown}
                    </TableCell>
                  </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
}


export default PlayerSearchTable