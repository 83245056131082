import {
  Avatar,
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import CommonStyle from "../../Common/style/Style";
import styled from "@emotion/styled";
import { CoachRole, PageRoutes } from "../../utils/constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import handleSort from "../../hooks/useSort";

interface Column {
  id:
    | "jersey"
    | "player_name"
    | "play"
    | "catches"
    | "position"
    | "shoots"
    | "role"
    | "birthdate"
    | "height"
    | "weight"
    | "town";
  label: string;
  align?: "right";
  sort?: "asc" | "desc";
}

const columns: readonly Column[] = [
  { id: "jersey", label: "#", sort: "asc" },
  { id: "player_name", label: "Name" },
  { id: "play", label: "" },
  { id: "position", label: "Pos" },
  {
    id: "shoots",
    label: "Shoots",
  },
  {
    id: "birthdate",
    label: "DOB",
  },
  {
    id: "height",
    label: "Height",
  },
  {
    id: "weight",
    label: "Weight",
  },
  {
    id: "town",
    label: "Home town",
  },
];

const columnsCoach: readonly Column[] = [
  { id: "role", label: "Role" },
  { id: "player_name", label: "Name" },
];
const columnsGollie: readonly Column[] = [
  { id: "jersey", label: "#", sort: "asc" },
  { id: "player_name", label: "Name" },
  { id: "play", label: "" },
  { id: "position", label: "Pos" },
  {
    id: "shoots",
    label: "Shoots",
  },
  {
    id: "catches",
    label: "Catches",
  },
  {
    id: "birthdate",
    label: "DOB",
  },
  {
    id: "height",
    label: "Height",
  },
  {
    id: "weight",
    label: "Weight",
  },
  {
    id: "town",
    label: "Home town",
  },
];
export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    backgroundColor: "#cecece",
  },
}));

const PlayerTable = ({
  playersData,
  playerPosition,
  sortConfig,
  getColumnLabel,
}: any) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const GiveDisplayArray = (flag: any) => {
    let data = [];
    for (var i = 0; i < flag.length; i++) {
      data.push(flag.charAt(i));
    }
    return data;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={CommonStyle.tableTitle}
                className="wtd-statview-group-header-color"
                colSpan={
                  playerPosition == "Goalies"
                    ? columnsGollie.length
                    : playerPosition == "Coaches"
                    ? columnsCoach.length
                    : columns.length
                }
              >
                {playerPosition}
              </TableCell>
            </TableRow>
            <TableRow>
              {playerPosition == "Goalies"
                ? columnsGollie?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        ...CommonStyle.tableHeader,
                        backgroundColor:
                          sortConfig.key === column.id ? "#cecece" : "#ffb931",
                      }}
                      className="wtd-statview-header-color"
                    >
                      {getColumnLabel(column)}
                    </TableCell>
                  ))
                : playerPosition == "Coaches"
                ? columnsCoach?.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        ...CommonStyle.tableHeader,
                        backgroundColor:
                          sortConfig.key === column.id ? "#cecece" : "#ffb931",
                      }}
                      className="wtd-statview-header-color"
                    >
                      {getColumnLabel(column)}
                    </TableCell>
                  ))
                : columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        ...CommonStyle.tableHeader,
                        backgroundColor:
                          sortConfig.key === column.id ? "#cecece" : "#ffb931",
                      }}
                      className="wtd-statview-header-color"
                    >
                      {getColumnLabel(column)}
                    </TableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {playersData
              ?.sort((a: any, b: any) =>
                handleSort(a, b, {
                  ...sortConfig,
                  key: sortConfig.key,
                })
              )
              ?.map((row: any, index: number) => {
                return playerPosition == "Goalies" ? (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                    className="wtd-statview-table-row-color"
                  >
                    <TableCell
                      sx={{
                        py: 1,
                        backgroundColor:
                          sortConfig.key === "jersey" ? "#cecece" : "",
                      }}
                      align={"left"}
                    >
                      {row.jersey}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={row.player_image}
                          sx={{
                            ">img": {
                              width: "40px !important",
                              height: "40px !important",
                              objectFit: "cover",
                            },
                          }}
                        />
                        <Link
                          sx={{ color: "black", textDecoration: "none" }}
                          href={`${PageRoutes.PlayerProfile}?player_id=${
                            row.player_id
                          }${
                            searchParams.get("stat_class")
                              ? `&stat_class=${searchParams.get("stat_class")}`
                              : ""
                          }&league_id=${
                            window?.customconfiguration?.league_id || 4 || 4
                          }`}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              ":hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontWeight: 600,
                              },
                            }}
                          >
                            {row.player_name}
                          </Typography>
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        {row.display_flags &&
                          GiveDisplayArray(row.display_flags)?.map((el) => (
                            <Box
                              sx={{
                                border: "1px solid black",
                                borderRadius: "50%",

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "18px",
                                height: "18px",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 800, fontSize: "14px" }}
                              >
                                {el}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.plays}
                    </TableCell>

                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.shoots}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.catches}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.birthdate}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.height}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.weight}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.town}
                      {row.country_code && `,${row.country_code}`}
                    </TableCell>
                  </StyledTableRow>
                ) : playerPosition == "Coaches" ? (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                    className="wtd-statview-table-row-color"
                  >
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {
                        CoachRole.filter(
                          (el: any) => el.postion == row.position
                        )[0].role
                      }
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.player_name}
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                    className="wtd-statview-table-row-color"
                  >
                    <TableCell
                      sx={{
                        py: 1,
                        backgroundColor:
                          sortConfig.key === "jersey" ? "#cecece" : "",
                      }}
                      align={"left"}
                    >
                      {row.jersey}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <Avatar
                          src={row.player_image}
                          sx={{
                            ">img": {
                              width: "40px !important",
                              height: "40px !important",
                              objectFit: "cover",
                            },
                          }}
                        />
                        <Link
                          sx={{ color: "black", textDecoration: "none" }}
                          href={`${PageRoutes.PlayerProfile}?player_id=${
                            row.player_id
                          }${
                            searchParams.get("stat_class")
                              ? `&stat_class=${searchParams.get("stat_class")}`
                              : ""
                          }&league_id=${
                            window?.customconfiguration?.league_id || 4 || 4
                          }`}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              ":hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontWeight: 600,
                              },
                            }}
                          >
                            {row.player_name}
                          </Typography>
                        </Link>
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        {row.display_flags &&
                          GiveDisplayArray(row.display_flags)?.map((el) => (
                            <Box
                              sx={{
                                border: "1px solid black",
                                borderRadius: "50%",

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "18px",
                                height: "18px",
                              }}
                            >
                              <Typography
                                variant="body1"
                                sx={{ fontWeight: 800, fontSize: "14px" }}
                              >
                                {el}
                              </Typography>
                            </Box>
                          ))}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.plays}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.shoots}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.birthdate}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.height}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.weight}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.town}
                      {row.country_code && `,${row.country_code}`}
                    </TableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PlayerTable;
