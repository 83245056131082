import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

export default function SelectDropdownLeagueStat({
  data,
  value,
  handleChange,
}: any) {
  return (
    <Select
      value={value}
      defaultValue={value}
      id="demo-simple-select-autowidth"
      onChange={(e) => handleChange(e)}
      autoWidth
      size="small"
      sx={{
        minWidth: "180px",
        ".MuiSelect-select": {
          py: "4px",
        },
        border: "none",
        backgroundColor: "#fff",
        borderRadius: "0",
      }}
    >
      {data?.map((el: any) => (
        <MenuItem value={el.value} key={el.label}>
          {el.label}
        </MenuItem>
      ))}
    </Select>
  );
}
