import {
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  Grid,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CommonStyle from "../../Common/style/Style";
import TableMain from "../GameCenter/TableMain";
import { useSearchParams } from "react-router-dom";
import { makeGetRequest } from "../../service";
import TableWithTotal from "../GameCenter/TableWithTotal";
import SelectDropdownLeagueForPlayer from "./SelectDropdownLeagueForPlayer";
import GoalieProfile from "./GoalieProfile";
import SelectDropdownLeagueStat from "../../Common/SelectDropdownLeagueStat";

const PlayerProfile = () => {
  const [imgOpen, setImgOpen] = useState({ imgSrc: "", open: false });
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("player_id");
  const [isTeamSkeleton, setIsTeamSkeleton] = useState(false);
  const [isProfileSkeleton, setIsProfileSkeleton] = useState(false);
  const [playerStates, setPlayerStates] = useState([]);
  const [playerData, setPlayerData] = useState<any>({});
  const [league, setLeague] = useState([]);
  const [playerLeague, setPlayerLeague] = useState([]);
  const [playerStat, setPlayerStat] = useState([]);
  const [playerTeamStat, setPlayerTeamStat] = useState([]);
  const [selectedLeague, setSelectedLeague] = useState(
    Number(searchParams.get("league_id")) ||
      window?.customconfiguration?.league_id ||
      4
  );
  const [selectedStat, setSelectedStat] = useState(
    Number(searchParams.get("stat_class")) || 0
  );
  const [filteredPlayerData, setFilteredPlayerData] = useState<any>({});

  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };
  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      league_id:
        searchParams.get("league_id") || window?.customconfiguration?.league_id,
    };
    const filterData = league?.filter(
      (el: any) => el.season_id == event.target.value
    );
    fetchTeamData(
      event.target.value,
      searchParams.get("stat_class"),
      searchParams.get("league_id") || window?.customconfiguration?.league_id
    );
    setMultiplaleParams(params);
  };
  const handleLeagueChange = (event: any) => {
    setSelectedLeague(event.target.value);
    const stat_class_list = playerData?.leagues
      .filter((el: any) => el.league == event.target.value)
      ?.map((item: any) => item.stat_classes)?.[0]
      ?.map((stat: any) => {
        return { value: stat.stat_class_tag, label: stat.stat_class_name };
      });
    setPlayerStat(stat_class_list);
    setSelectedStat(stat_class_list?.[0]?.value);
    setMultiplaleParams({
      league_id: event.target.value,
      stat_class: stat_class_list?.[0]?.value,
    });
    // TODO: Add stat_class filter
    fetchSeason();
  };

  const handleStatChange = (event: any) => {
    setSelectedStat(event.target.value);
    setMultiplaleParams({
      stat_class: event.target.value,
    });
    // TODO: Add stat_class filter
    fetchTeamData(
      searchParams.get("season_id"),
      event.target.value,
      searchParams.get("league_id") || window?.customconfiguration?.league_id
    );
  };
  const fetchTeamData = async (
    season_id: any,
    stat_class: string | null,
    league_id?: any
  ) => {
    setIsTeamSkeleton(true);
    try {
      let qurey: any = {
        league_id,
        player_id: id,
        detail: 1,
        season_id: season_id,
      };
      if (stat_class) {
        qurey = {
          ...qurey,
          stat_class: stat_class,
        };
      }
      const res = await makeGetRequest(
        "get_player_stats",
        new URLSearchParams(qurey).toString()
      );
      setPlayerStates(res?.detailed_player_stats);
      setPlayerTeamStat(
        res?.team_player_stats?.filter(
          (el: any) => el?.team_id !== null && el?.season_id !== null
        )
      );

      setIsTeamSkeleton(false);
    } catch (error) {
      setIsTeamSkeleton(false);
    }
  };
  const level_id = searchParams.get("level_id");
  const fetchSeason = async () => {
    const qureyforSeason: any = {
      league_id:
        searchParams.get("league_id") || window?.customconfiguration?.league_id,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );

      setLeague(response.leagues[0].seasons);
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        response.leagues[0].seasons[0].season_id;
      const filterData = response.leagues[0].seasons?.filter(
        (el: any) => el.season_id == filterSeasonId
      );

      const qurey: any = {
        league_id:
          searchParams.get("league_id") ||
          window?.customconfiguration?.league_id,
        season_id: filterData?.length
          ? filterSeasonId
          : response.leagues[0].current_season,
      };

      setMultiplaleParams(qurey);

      await fetchTeamData(
        filterData?.length
          ? filterSeasonId
          : response.leagues[0].current_season,
        searchParams.get("stat_class"),
        searchParams.get("league_id") || window?.customconfiguration?.league_id
      );

      setIsProfileSkeleton(false);
    } catch (error) {
      setIsProfileSkeleton(false);
    }
  };
  const getPlayerData = async () => {
    let query: any = {
      player_id: id,
    };
    if (searchParams.get("stat_class")) {
      query = {
        player_id: id,
        stat_class: searchParams.get("stat_class"),
      };
    }
    try {
      const res = await makeGetRequest(
        "get_player_info",
        new URLSearchParams(query).toString()
      );
      setPlayerData(res?.player_info);
      if (selectedLeague) {
        setPlayerStat(
          res?.player_info?.leagues
            ?.filter((el: any) => el.league == selectedLeague)
            ?.map((item: any) => item.stat_classes)?.[0]
            ?.map((stat: any) => {
              return {
                value: stat.stat_class_tag,
                label: stat.stat_class_name,
              };
            })
        );
      } else {
        setSelectedLeague(
          res?.player_info.leagues?.map((el: any) => {
            return { value: el.league, label: el.league_name };
          })?.[0]?.value
        );
      }
      setPlayerLeague(
        res?.player_info?.leagues?.map((el: any) => {
          return { value: el.league, label: el.league_name };
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    getPlayerData();
    fetchSeason();
  }, []);

  useEffect(() => {
    setFilteredPlayerData({
      ...playerData,
      teams: playerTeamStat,
    });
  }, [playerTeamStat]);
  console.log(filteredPlayerData);
  return (
    <Box>
      {isProfileSkeleton ? (
        <Box sx={{ position: "relative", height: "80vh" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : playerData.plays !== "G" ? (
        <Box>
          <Grid container spacing={2} sx={{ px: 2, py: 1 }}>
            {/* Image */}
            <Grid item lg={2} md={3} sm={2} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center" },
                  margin: "10px 6px",
                }}
              >
                <Avatar
                  src={playerData?.player_image}
                  sx={{
                    borderRadius: "50%",
                    border: "4px solid #000000",
                    width: { md: "170px", xs: "100px" },
                    height: { md: "170px", xs: "100px" },
                  }}
                />
              </Box>
            </Grid>

            {/* Info */}
            <Grid
              item
              lg={8}
              md={9}
              sm={10}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Grid container>
                {/* Player Details */}
                <Grid
                  item
                  lg={7}
                  md={7}
                  sm={6}
                  xs={12}
                  sx={{
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      padding: "15px 10px 0 10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        height: "5rem",
                        fontSize: { md: "350%", xs: "200%" },
                        fontWeight: "800",
                        paddingRight: "10px",
                        paddingBottom: "20px",
                        borderRight: "1px solid #999999",
                      }}
                    >
                      {playerData?.jersey}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: "15px 10px 0 0",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: { md: "152%", xs: "120%" },
                        textTransform: "uppercase",
                        fontWeight: "600",
                      }}
                    >
                      {playerData.player_name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "600",
                        fontSize: { md: "132%", xs: "100%" },
                        textTransform: "uppercase",
                        color: "#666666",
                        margin: "15px 0",
                      }}
                    >
                      {playerData?.team_name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={5} md={5} xs={12} sm={6}>
                  <Box sx={{ padding: "15px 10px 0 40px", color: "#666666" }}>
                    <Typography>Position:{playerData?.plays}</Typography>
                    <Typography>
                      {playerData?.height} / {playerData?.weight}lbs
                    </Typography>
                    <Typography>Shoots: {playerData?.shoots}</Typography>
                    <Typography>Birthdate: {playerData?.birthdate}</Typography>
                    <Typography>
                      Home town: {playerData?.town}
                      {playerData?.town ? "," : ""} {playerData?.state}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            {/* Team */}
            <Grid
              item
              lg={2}
              sx={{
                display: { lg: "block", md: "none", xs: "none", sm: "none" },
              }}
            >
              <img
                src={playerData?.team_smlogo}
                alt={playerData?.team_smlogo}
                style={{
                  padding: "15px",
                  width: "170px",
                  height: "auto",
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <Typography
              variant="body1"
              sx={{
                fontWeight: "600",
                fontSize: "110%",
                margin: 0,
                display: "flex",
                justifyContent: "center",
              }}
            >
              Player Totals
            </Typography>
            <Grid
              container
              sx={{ py: 1, display: "flex", justifyContent: "center" }}
            >
              <Grid
                item
                lg={1}
                md={1}
                sm={1.5}
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderLeft: "1px solid #000000",
                  borderRight: "1px solid #000000",
                }}
              >
                <Typography variant="body1">GP</Typography>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  {playerData?.games_played}
                </Typography>
              </Grid>
              <Grid
                item
                lg={1}
                md={1}
                sm={1.5}
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRight: "1px solid #000000",
                }}
              >
                <Typography variant="body1">G</Typography>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  {playerData?.goals}
                </Typography>
              </Grid>
              <Grid
                item
                lg={1}
                md={1}
                sm={1.5}
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRight: "1px solid #000000",
                }}
              >
                <Typography variant="body1">A</Typography>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  {playerData?.assists}
                </Typography>
              </Grid>
              <Grid
                item
                lg={1}
                md={1}
                sm={1.5}
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRight: "1px solid #000000",
                }}
              >
                <Typography variant="body1">PTS</Typography>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  {playerData?.points}
                </Typography>
              </Grid>
              <Grid
                item
                lg={1}
                md={1}
                sm={1.5}
                xs={2}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  borderRight: "1px solid #000000",
                }}
              >
                <Typography variant="body1">PT/G</Typography>
                <Typography variant="h5" sx={{ fontWeight: "600" }}>
                  {(playerData?.points / playerData?.games_played).toFixed(2)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {playerData?.player_bios && (
            <Box>
              <Box
                className="wtd-statview-filter"
                sx={{
                  ...CommonStyle.tableControl,
                  display: "flex",
                  gap: 1,
                  px: 1,
                  fontWeight: "600",
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 800, fontSize: 18 }}
                >
                  {playerData.player_name} Biography
                </Typography>
              </Box>
              <Box>
                {playerData?.player_bios &&
                  playerData?.player_bios.map((el: any) => (
                    <>
                      {el?.player_bio_url ? (
                        <a
                          dangerouslySetInnerHTML={{
                            __html: el?.player_bio_title,
                          }}
                          href={el?.player_bio_url}
                          target="blank"
                        ></a>
                      ) : (
                        <Typography
                          dangerouslySetInnerHTML={{
                            __html: el?.player_bio_title,
                          }}
                          variant="body1"
                        ></Typography>
                      )}
                    </>
                  ))}
              </Box>
            </Box>
          )}
          <Box sx={{ py: 5 }}>
            <Box
              className="wtd-statview-filter"
              sx={{
                ...CommonStyle.tableControl,
                display: "flex",
                gap: 1,
                px: 1,
                fontWeight: "600",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 800, fontSize: 18 }}
              >
                {playerData.player_name} Player Stats
              </Typography>
              <Box sx={{ display: "flex", gap: 2 }}>
                <SelectDropdownLeagueStat
                  data={playerLeague}
                  value={selectedLeague}
                  handleChange={handleLeagueChange}
                />
                <SelectDropdownLeagueStat
                  data={playerStat}
                  value={selectedStat}
                  handleChange={handleStatChange}
                />
              </Box>
            </Box>

            <TableWithTotal
              headers={[
                { id: "season_name", label: "Season", total: false },
                { id: "team_name", label: "Team", total: false },
                { id: "games_played", label: "GP", total: true },
                { id: "goals", label: "G", total: true },
                { id: "assists", label: "A", total: true },
                { id: "points", label: "PTS", total: true },
                { id: "gwg", label: "GWG", total: true },
                { id: "pims", label: "PIM", total: true },
                { id: "plusminus", label: "+/-", total: true },
                { id: "sog", label: "SH", total: true },
                { id: "ppg", label: "PPG", total: true },
                { id: "shg", label: "SHG", total: true },
                { id: "shog", label: "SOG", total: true },
              ]}
              listData={
                filteredPlayerData?.teams &&
                filteredPlayerData?.teams
                  ?.sort((a: any, b: any) =>
                    a.start_date < b.start_date ? 1 : -1
                  )
                  .map((el: any) => el)
              }
              playerData={filteredPlayerData}
            />
          </Box>
          <Box sx={{ py: 2 }}>
            <Box
              className="wtd-statview-filter"
              sx={{
                ...CommonStyle.tableControl,
                display: "flex",
                gap: 1,
                px: 1,
                fontWeight: "600",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 800, fontSize: 18 }}
              >
                {playerData.player_name} Game by Game
              </Typography>
              <SelectDropdownLeagueForPlayer
                league={league}
                handleChange={handleChange}
              />
            </Box>
            {isTeamSkeleton ? (
              <Box sx={{ position: "relative", height: "80vh" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            ) : (
              <TableMain
                headers={[
                  { id: "matchup_name", label: "" },
                  { id: "date", label: "Date" },
                  { id: "goals", label: "G" },
                  { id: "assists", label: "A" },
                  { id: "points", label: "PTS" },
                  { id: "sog", label: "SH" },
                  { id: "pims", label: "PIM" },
                  { id: "plusminus", label: "+/-" },
                  { id: "ppg", label: "PPG" },
                  { id: "shg", label: "SHG" },
                  { id: "shog", label: "SOG" },
                  { id: "gwg", label: "GWG" },
                  { id: "tying_goal", label: "TG" },
                ]}
                listData={playerStates?.map((el) => el)}
                isClickableRow={true}
              />
            )}
          </Box>
          <Box sx={{ py: 2 }}>
            <Box
              className="wtd-statview-filter"
              sx={{
                ...CommonStyle.tableControl,
                display: "flex",
                gap: 1,
                px: 1,
                fontWeight: "600",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: 800, fontSize: 18 }}
              >
                {playerData.player_name} Player Media
              </Typography>
            </Box>
            {playerData?.player_media ? (
              <Box>
                <Table>
                  <TableCell
                    className={`wtd-statview-header-color`}
                    sx={{
                      ...CommonStyle.tableHeader,
                      width: "100% !important",
                      px: "15px",
                    }}
                  >
                    Images
                  </TableCell>
                </Table>
                <Box
                  sx={{
                    my: 2,
                    gap: 1,
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(100px, 1fr))",
                  }}
                >
                  {playerData?.player_media &&
                    playerData?.player_media.map((item: any) => (
                      <img
                        width="100px"
                        height="100px"
                        src={item?.player_media_url}
                        srcSet={item}
                        alt={item}
                        style={{ objectFit: "cover", cursor: "pointer" }}
                        loading="lazy"
                        onClick={() => {
                          setImgOpen({
                            imgSrc: item?.player_media_url,
                            open: true,
                          });
                        }}
                      />
                    ))}
                  <Dialog
                    sx={{ maxWidth: "500px", margin: "auto" }}
                    onClose={() => setImgOpen({ imgSrc: "", open: false })}
                    open={imgOpen.open}
                  >
                    <img
                      src={imgOpen.imgSrc}
                      alt={imgOpen.imgSrc}
                      width="100%"
                    />
                  </Dialog>
                </Box>
              </Box>
            ) : (
              <Box sx={{ textAlign: "center", py: 2 }}>NO DATA WAS FOUND</Box>
            )}
          </Box>
        </Box>
      ) : (
        <GoalieProfile
          handleChange={handleChange}
          handleLeagueChange={handleLeagueChange}
          selectedStat={selectedStat}
          playerLeague={playerLeague}
          selectedLeague={selectedLeague}
          playerStat={playerStat}
          handleStatChange={handleStatChange}
          playerData={playerData}
          filteredPlayerData={filteredPlayerData}
          playerStates={playerStates}
          isTeamSkeleton={isTeamSkeleton}
          league={league}
          id={id}
        />
      )}
    </Box>
  );
};

export default PlayerProfile;
