import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import HBHTableOne from "../../GameCenter/HBHTableOne";
import { makeGetRequest } from "../../../service";
import { useSearchParams } from "react-router-dom";
import Schedule from "../../Schedule";
import PlayerTable from "../../Schedule/ScheduleTable";

const SchedulesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(true);
  const [playersData, setPlayersData] = useState([]);

  const season_id = searchParams.get("season_id") || "";
  const team_id = searchParams.get("team_id") || "";
  const stat_class = searchParams.get("stat_class") || "";

  const fetchData = async () => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id,
      team_id,
      stat_class,
    };
    setIsSkeleton(true);

    try {
      const response = await makeGetRequest(
        "get_schedule",
        new URLSearchParams(qurey).toString()
      );

      setPlayersData(response?.games);

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [season_id, team_id, stat_class]);

  const lastGames = playersData
    .sort((a: any, b: any) => b.date.localeCompare(a.date))
    .filter((item: any) => new Date(item.date) < new Date())
    .slice(0, 5);

  const nextGames = playersData
    .sort((a: any, b: any) => a.date.localeCompare(b.date))
    .filter((item: any) => new Date(item.date) > new Date())
    .slice(0, 5);

  if (isSkeleton) {
    return (
      <Box sx={{ position: "relative", height: "80vh" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Last 5 Games
        </Typography>
        {lastGames?.length > 0 ? (
          <PlayerTable playersData={lastGames} />
        ) : (
          <Box sx={{ textAlign: "center", py: 2 }}>
            NO GAMES MATCH THE SELECTED CRITERIA
          </Box>
        )}
      </Box>
      <br />
      <Box>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Next 5 Games
        </Typography>
        {nextGames?.length > 0 ? (
          <PlayerTable playersData={nextGames} />
        ) : (
          <Box sx={{ textAlign: "center", py: 2 }}>
            NO GAMES MATCH THE SELECTED CRITERIA
          </Box>
        )}
      </Box>
      <br />
      <Box>
        <Schedule
          isTeamVisible={false}
          isSeasonsVisible={false}
          isStatsVisible={false}
        />
      </Box>
    </>
  );
};

export default SchedulesPage;
