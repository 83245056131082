import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import TableDetails from "../../../Common/Component/TableDetails";
import { makeGetRequest } from "../../../service";
import { PlayerPosition } from "../../../utils/constant";
import PlayerTable from "../../TableComponent/Table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function RostersPage() {
  const [playersData, setPlayersData] = useState({
    forward: [],
    defense: [],
    goalie: [],
    coaches: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: searchParams.get("sort") || "",
    direction: searchParams.get("direction") || "",
  });
  const level_id = searchParams.get("level_id");
  const season_id = searchParams.get("season_id");
  const team_id = searchParams.get("team_id");
  const stat_class = searchParams.get("stat_class") || 1;
  const filteredData = (data: any, plays: any) =>
    data.filter(
      (resItem: any) => plays.includes(resItem.plays) && resItem?.coach != "1"
    );
  const filterDataCoches = (data: any) => {
    return data?.filter((resItem: any) => resItem.coach == "1");
  };
  const fetchData = async () => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id,
      team_id,
      stat_class,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    setIsSkeleton(true);
    try {
      const response = await makeGetRequest(
        "get_roster",
        new URLSearchParams(qurey).toString()
      );

      setPlayersData({
        ...playersData,
        forward: filteredData(response.players, PlayerPosition.forward),
        defense: filteredData(response.players, PlayerPosition.defense),
        goalie: filteredData(response.players, PlayerPosition.goalie),
        coaches: filterDataCoches(response.players),
      });

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [season_id, team_id, stat_class]);

  const sortTable = (key: string, sort: string) => {
    searchParams.set("sort", key);
    const currentSortDirection = searchParams.get("direction");
    if (sortConfig.key === key) {
      setSortConfig({
        key,
        direction: currentSortDirection === "asc" ? "desc" : "asc",
      });
      searchParams.set(
        "direction",
        currentSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      searchParams.set("direction", sort);
      setSortConfig({ key, direction: sort });
    }
    setSearchParams(searchParams);
  };

  const getColumnLabel = (column: {
    id: string;
    label: string;
    sort: string;
    align: string;
  }) => {
    return !["jersey"].includes(column.id) ? (
      column.label
    ) : (
      <a
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          display: "flex",
          alignItems: "center",
          justifyContent: column.align,
        }}
        onClick={() => sortTable(column.id, column.sort ? column.sort : "desc")}
      >
        {column.label}
        {sortConfig.key === column.id && (
          <span style={{ display: "flex" }}>
            {sortConfig.direction === "asc" ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </span>
        )}
      </a>
    );
  };

  return (
    <Box>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "80vh" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            {playersData?.forward.length > 0 && (
              <PlayerTable
                playersData={playersData?.forward}
                playerPosition={"Forwards"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
            {playersData?.defense.length > 0 && (
              <PlayerTable
                playersData={playersData?.defense}
                playerPosition={"Defensemen"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
            {playersData?.goalie.length > 0 && (
              <PlayerTable
                playersData={playersData?.goalie}
                playerPosition={"Goalies"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
            {playersData?.coaches.length > 0 && (
              <PlayerTable
                playersData={playersData?.coaches}
                playerPosition={"Coaches"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
          </Box>
          <TableDetails />
        </Box>
      )}
    </Box>
  );
}
