import {
  TableCell,
  Box,
  Typography,
  Avatar,
  IconButton,
  Link,
} from "@mui/material";
import { PageRoutes } from "../../utils/constant";
import { StyledTableRow } from "../TableComponent/Table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
const PlayerStatRow = ({ index, playerItem, sortKey, teamLogo }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [addRow, setAddRow] = useState(false);
  const allTeams = searchParams.get("team_id");
  const handleAddRow = () => {
    setAddRow(!addRow);
  };

  const newPlayersData = playerItem?.teams?.map((player: any) => ({
    ...player,
    points_per_game: (Number(player?.games_played) !== 0
      ? Number(player.points) / Number(player.games_played)
      : 0
    )?.toFixed(2),
    penalty_minutes_per_game: (Number(player?.games_played) !== 0
      ? Number(player.pims) / Number(player.games_played)
      : 0
    )?.toFixed(2),
    shooting_percentage: (Number(player?.sog) !== 0
      ? Number(player?.goals) / Number(player?.sog)
      : 0
    ).toFixed(1),
    shootout_percentage: (Number(player.shoa) !== 0
      ? Number(player.shog) / Number(player.shoa)
      : 0
    ).toFixed(1),
  }));

  return (
    <>
      {newPlayersData
        ?.filter((el: any, index: any) => index == 0)
        .map((el: any) => (
          <StyledTableRow
            hover
            key={index + 1}
            role="checkbox"
            tabIndex={-1}
            className="wtd-statview-table-row-color"
            sx={{ py: 1 }}
          >
            <TableCell
              id={"rank"}
              sx={{ paddingRight: 0, paddingLeft: 1 }}
              align={"left"}
            >
              {index + 1}
            </TableCell>
            <TableCell sx={{ paddingRight: 0, paddingLeft: 0 }} align={"left"}>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    visibility:
                      playerItem.display_flags == "R" ? "visible" : "hidden",
                  }}
                >
                  {playerItem.display_flags == "R" ? "*" : ""}
                </Typography>
                <Typography variant="body2">
                  {playerItem.active == 1 ? "" : "X"}
                </Typography>
              </Box>
            </TableCell>

            <TableCell
              sx={{
                paddingRight: 0,
                paddingLeft: 1,
                backgroundColor: sortKey === "jersey" ? "#cecece" : "",
              }}
              align={"left"}
            >
              {playerItem.jersey}
            </TableCell>
            <TableCell
              id={"player_name"}
              sx={{
                py: 1,
                paddingRight: 0,
                paddingLeft: 1,
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                backgroundColor: sortKey === "player_name" ? "#cecece" : "",
              }}
              align={"left"}
            >
              <Link
                sx={{
                  color: "black",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  width: "120px",
                }}
                href={`${PageRoutes.PlayerProfile}?player_id=${
                  playerItem.player_id
                }${
                  searchParams.get("stat_class")
                    ? `&stat_class=${searchParams.get("stat_class")}`
                    : ""
                }&league_id=${
                  window?.customconfiguration?.league_id || 4 || 4
                }`}
              >
                <Avatar
                  src={playerItem.player_image}
                  sx={{
                    ">img": {
                      width: "40px !important",
                      height: "40px !important",
                      objectFit: "cover",
                    },
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    textOverflow: "ellipsis",
                    ":hover": {
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: 600,
                    },
                  }}
                >
                  {playerItem.player_name}
                </Typography>
              </Link>
              {playerItem?.teams?.length >= 2 && (
                <IconButton onClick={handleAddRow}>
                  {addRow ? (
                    <RemoveCircleOutlineIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}{" "}
                </IconButton>
              )}
            </TableCell>
            <TableCell
              sx={{ paddingRight: 0, paddingLeft: 1 }}
              align={"center"}
            >
              {playerItem?.plays}
            </TableCell>
            <TableCell
              sx={{
                py: 1,
                backgroundColor:
                  sortKey === "team_ab" ? "#cecece" : "transparent",
              }}
              align={"left"}
            >
              <Avatar
                sx={{ bgcolor: "transparent" }}
                src={teamLogo ? teamLogo : playerItem?.smlogo}
              />
            </TableCell>

            <TableCell
              id="games_played"
              sx={{
                py: 1,
                backgroundColor: sortKey === "games_played" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.games_played}
            </TableCell>
            <TableCell
              id="goals"
              sx={{
                py: 1,
                backgroundColor: sortKey === "goals" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.goals}
            </TableCell>
            <TableCell
              id="assists"
              sx={{
                py: 1,
                backgroundColor: sortKey === "assists" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.assists}
            </TableCell>
            <TableCell
              id="points"
              sx={{
                py: 1,
                backgroundColor: sortKey === "points" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.points}
            </TableCell>
            <TableCell
              id={"points_per_game"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "points_per_game" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.points_per_game}
            </TableCell>
            <TableCell
              id={"ppg"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "ppg" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.ppg}
            </TableCell>
            <TableCell
              id="ppa"
              sx={{
                py: 1,
                backgroundColor: sortKey === "ppa" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.ppa}
            </TableCell>
            <TableCell
              id="shg"
              sx={{
                py: 1,
                backgroundColor: sortKey === "shg" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.shg}
            </TableCell>
            <TableCell
              id="sha"
              sx={{
                py: 1,
                backgroundColor: sortKey === "sha" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.sha}
            </TableCell>
            <TableCell
              id="gwg"
              sx={{
                py: 1,
                backgroundColor: sortKey === "gwg" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.gwg}
            </TableCell>
            <TableCell
              id="shog"
              sx={{
                py: 1,
                backgroundColor: sortKey === "shog" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.shog}
            </TableCell>
            <TableCell
              id="pims"
              sx={{
                py: 1,
                backgroundColor: sortKey === "pims" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.pims}
            </TableCell>
            <TableCell
              id="penalty_minutes_per_game"
              sx={{
                py: 1,
                backgroundColor:
                  sortKey === "penalty_minutes_per_game" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.penalty_minutes_per_game}
            </TableCell>
            <TableCell
              id="plusminus"
              sx={{
                py: 1,
                backgroundColor: sortKey === "plusminus" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.plusminus}
            </TableCell>
            <TableCell
              id="sog"
              sx={{
                py: 1,
                backgroundColor: sortKey === "sog" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.sog}
            </TableCell>
            <TableCell
              id="shooting_percentage"
              sx={{
                py: 1,
                backgroundColor:
                  sortKey === "shooting_percentage" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {playerItem.shooting_percentage}
            </TableCell>
          </StyledTableRow>
        ))}
      {addRow &&
        newPlayersData?.map((el: any) => (
          <StyledTableRow
            hover
            key={index + 1}
            role="checkbox"
            tabIndex={-1}
            className="wtd-statview-table-row-color"
          >
            <TableCell
              id={"rank"}
              sx={{ paddingRight: 0, paddingLeft: 1 }}
              align={"left"}
            ></TableCell>
            <TableCell sx={{ paddingRight: 0, paddingLeft: 0 }} align={"left"}>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    visibility: el.display_flags == "R" ? "visible" : "hidden",
                  }}
                >
                  {el.display_flags == "R" ? "*" : ""}
                </Typography>
                <Typography variant="body2">
                  {el.active == 1 ? "" : "X"}
                </Typography>
              </Box>
            </TableCell>

            <TableCell sx={{ paddingRight: 0, paddingLeft: 1 }} align={"left"}>
              {el.jersey}
            </TableCell>
            <TableCell
              id={"player_name"}
              sx={{ paddingRight: 0, paddingLeft: 1 }}
              align={"left"}
            ></TableCell>
            <TableCell
              sx={{ paddingRight: 0, paddingLeft: 1 }}
              align={"center"}
            >
              {playerItem?.plays}
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"left"}>
              <Avatar sx={{ bgcolor: "transparent" }} src={el?.smlogo} />
            </TableCell>

            <TableCell id="games_played" sx={{ py: 1 }} align={"center"}>
              {el.games_played}
            </TableCell>
            <TableCell id="goals" sx={{ py: 1 }} align={"center"}>
              {el.goals}
            </TableCell>
            <TableCell id="assists" sx={{ py: 1 }} align={"center"}>
              {el.assists}
            </TableCell>
            <TableCell id="points" sx={{ py: 1 }} align={"center"}>
              {el.points}
            </TableCell>
            <TableCell id={"points_per_game"} sx={{ py: 1 }} align={"center"}>
              {el.points_per_game}
            </TableCell>
            <TableCell id={"ppg"} sx={{ py: 1 }} align={"center"}>
              {el.ppg}
            </TableCell>
            <TableCell id="ppa" sx={{ py: 1 }} align={"center"}>
              {el.ppa}
            </TableCell>
            <TableCell id="shg" sx={{ py: 1 }} align={"center"}>
              {el.shg}
            </TableCell>
            <TableCell id="sha" sx={{ py: 1 }} align={"center"}>
              {el.sha}
            </TableCell>
            <TableCell id="gwg" sx={{ py: 1 }} align={"center"}>
              {el.gwg}
            </TableCell>
            <TableCell id="shog" sx={{ py: 1 }} align={"center"}>
              {el.shog}
            </TableCell>
            <TableCell id="pims" sx={{ py: 1 }} align={"center"}>
              {el.pims}
            </TableCell>
            <TableCell
              id="penalty_minutes_per_game"
              sx={{ py: 1 }}
              align={"center"}
            >
              {el.penalty_minutes_per_game}
            </TableCell>
            <TableCell id="plusminus" sx={{ py: 1 }} align={"center"}>
              {el.plusminus}
            </TableCell>
            <TableCell id="sog" sx={{ py: 1 }} align={"center"}>
              {el.sog}
            </TableCell>
            <TableCell id="shooting_percentage" sx={{ py: 1 }} align={"center"}>
              {el.shooting_percentage}
            </TableCell>
          </StyledTableRow>
        ))}
    </>
  );
};
export default PlayerStatRow;
