import {
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import "./index.css";
import HokeyIcon from "../../images/hokeyIcon";
import { makeGetRequest } from "../../../service";
import moment from "moment";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageRoutes } from "../../../utils/constant";
import Centers from "./Centers";
var moment_tz = require("moment-timezone");
declare global {
  interface Window {
    customconfiguration: any; // Replace 'any' with the appropriate type of your data
  }
}
const GameCenter = () => {
  const array = new Array(10).fill(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const level_id = searchParams.get("level_id");
  const [games, setGames] = useState([]);
  const [isSkeleton, setIsSkeleton] = useState(false);
  const team_id =
    window?.customconfiguration?.team_id || searchParams.get("team_id");
  interface ResponsiveSettings {
    breakpoint: number;
    settings: {
      slidesToShow: number;
      slidesToScroll: number;
    };
  }
  const responsiveSettings: ResponsiveSettings[] = [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ];

  const navigate = useNavigate();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const settings = {
    slidesToShow: 5,
    infinite: false,
    prevArrow: (
      <ArrowBackIosIcon
        sx={{ color: "#fcb31f", ":hover": { color: "#fcb31f" } }}
      />
    ),
    nextArrow: (
      <ArrowForwardIosIcon
        sx={{ color: "#fcb31f", ":hover": { color: "#fcb31f" } }}
      />
    ),
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const handleImageHover = (index: any) => {
    setHoveredIndex(index);
  };

  const handleImageLeave = () => {
    setHoveredIndex(null);
  };
  const getMatchData = async () => {
    const resTeamData = await makeGetRequest(
      "get_team_info",
      `team_id=${team_id}`
    );
    const currentSeason = resTeamData?.leagues[0]?.current_season;

    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4,
      season_id: currentSeason,
      team_id: team_id,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    setIsSkeleton(true);
    try {
      const res = await makeGetRequest(
        "get_schedule",
        new URLSearchParams(qurey).toString()
      );
      const today = moment().startOf("day");
      let sortGames = res?.games?.filter((el: any) => {
        if (moment(el?.date, "YYYY-MM-DD").isSameOrAfter(today)) {
          return el;
        }
      });

      if (sortGames.length >= 15) {
        sortGames = sortGames.filter?.((el: any, index: any) => index <= 14);
      }

      setGames(sortGames);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    getMatchData();
  }, []);

  return (
    <Box sx={{ px: 4, bgcolor: "#252524" }}>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "100px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Slider {...settings}>
          {games?.map((element: any, index: any) => (
            <Link
              key={index + 1}
              href={`${PageRoutes.GameCenter}?game_id=${element.game_id}`}
              sx={{
                display: "flex",
                color: "black",
                textDecoration: "none",
                borderColor: "#fcb31f !important",
                backgroundColor: "#000000",
                borderRight: "1px solid #000000",
                flexDirection: "column",

                borderBottom: "1px solid #fcb31f",
                borderLeft: "1px solid #000000",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  backgroundColor: "#ffb931",
                  height: "18px",
                  lineHeight: "18px",
                  fontSize: "11px",
                  color: "#ffffff",
                }}
              >
                {moment(element?.date, "YYYY-MM-DD hh:mm:ss.SSSSSS Z").format(
                  "ddd, MMM DD"
                )}
              </Box>

              <Box
                className={"container"}
                sx={{
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "flex-start",
                  color: "#ffffff",
                  width: "100%",

                  padding: "0",
                  cursor: "pointer",
                }}
              >
                <Box
                  sx={{
                    flexBasis: "60%",
                    width: "60%",
                    py: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "22px auto 24px",
                      padding: "3px 8px",
                      lineHeight: "1",
                      mb: 0.5,
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "22px", height: "22px" }}>
                      <Avatar
                        sx={{ width: 1, height: 1 }}
                        src={element?.away_smlogo}
                      />
                    </Box>
                    <span
                      style={{
                        fontWeight: "700",
                        paddingLeft: "6px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        minWidth: "38px",
                      }}
                    >
                      {element?.away_ab}
                    </span>
                    <span
                      style={{
                        fontWeight: "700",
                        paddingLeft: "5px",
                        textAlign: "right",
                        display: "inline-block",
                      }}
                    >
                      {element?.away_goals}
                    </span>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "22px auto 24px",
                      padding: "3px 8px",
                      lineHeight: "1",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "22px", height: "22px" }}>
                      <Avatar
                        sx={{ width: 1, height: 1 }}
                        src={element?.home_smlogo}
                      />
                    </Box>
                    <span
                      style={{
                        fontWeight: "700",
                        paddingLeft: "6px",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        minWidth: "38px",
                      }}
                    >
                      {element?.home_ab}
                    </span>
                    <span
                      style={{
                        fontWeight: "700",
                        paddingLeft: "5px",
                        textAlign: "right",
                        display: "inline-block",
                      }}
                    >
                      {element?.home_goals}
                    </span>
                  </Box>
                </Box>

                <Centers element={element} />

                <Box className="overlay">
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      px: 2,
                      gap: 0.5,
                    }}
                  >
                    <Box sx={{ width: "50px", height: "40px" }}>
                      <HokeyIcon />
                    </Box>
                    <Typography variant="body2">Game center</Typography>
                  </Box>
                </Box>
              </Box>
            </Link>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default GameCenter;
