const handleSort = (a: any, b: any, sortConfig: any, mode?: any) => {
  const convertToSeconds = (timeString: string) => {
    const [minutes, seconds] = timeString.split(":");
    return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
  };

  const getKey = (item: any) => {
    if (mode == "home") {
      return item[`home_${sortConfig.key}`];
    } else if (mode == "away") {
      return item[`away_${sortConfig.key}`];
    } else {
      return item[sortConfig.key];
    }
  };

  if (sortConfig.key === "toi") {
    const timeA = convertToSeconds(getKey(a));
    const timeB = convertToSeconds(getKey(b));

    if (timeA == null) return 1;
    if (timeB == null) return -1;

    if (timeA < timeB) {
      return sortConfig.direction === "asc" ? -1 : 1;
    } else if (timeA > timeB) {
      return sortConfig.direction === "asc" ? 1 : -1;
    } else {
      return 0;
    }
  } else {
    if (!isNaN(a[sortConfig.key]) && !isNaN(b[sortConfig.key])) {
      if (getKey(a) == null) return 1;
      if (getKey(b) == null) return -1;

      if (+getKey(a) < +getKey(b)) {
        return sortConfig.direction === "asc" ? -1 : 1;
      } else if (+getKey(a) > +getKey(b)) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    } else {
      if (getKey(a) < getKey(b)) {
        return sortConfig.direction === "asc" ? -1 : 1;
      } else if (getKey(a) > getKey(b)) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
    }

    return 0;
  }
};

export default handleSort;
