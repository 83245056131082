import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";

const HBHTableTwo = (props: any) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={CommonStyle.tableHeader}
                className="wtd-statview-header-color"
                colSpan={4}
              >
                {props.header}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listData?.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  className="wtd-statview-table-row-color"
                  key={index + 1}
                >
                  <TableCell
                    sx={{ width: "50px", py: 0.5 }}
                    align={props?.align ? props.align : "left"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={
                          row.team1_src
                            ? row.team1_src
                            : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                        }}
                      />

                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                          textTransform: "capitalize",
                        }}
                      >
                        {row.team1}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ minWidth: "8px" }}>
                    <Typography variant="body1">{row.score_board}</Typography>
                  </TableCell>
                  <TableCell
                    sx={{ py: 0.5, width: "50px" }}
                    align={props?.align ? props.align : "left"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={
                          row.team2_src
                            ? row.team2_src
                            : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                        }
                        style={{
                          width: "40px",
                          height: "40px",
                          textAlign: "center",
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          whiteSpace: "nowrap",
                          textTransform: "capitalize",
                        }}
                      >
                        {row.team2}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      float: "center",
                      paddingLeft: "10px",
                      width: "82%",
                      textAlign: "center",
                    }}
                  >
                    <Typography sx={{ whiteSpace: "nowrap" }} variant="body1">
                      {row.date} {row.mode}
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HBHTableTwo;
