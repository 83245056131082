import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useLocation, useSearchParams } from "react-router-dom";

export default function SelectDropdownTeams({
  teams,
  value,
  handleChangeTeam,
  allTeamNeeded = true,
  key = "team_id",
}: any) {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const selectValue = value !== undefined ? value : searchParams.get(key) || "";
  return (
    <Select
      value={selectValue}
      onChange={(e) => handleChangeTeam(e)}
      id="demo-simple-select-autowidth"
      defaultValue={searchParams.get("team_id") || ""}
      autoWidth
      displayEmpty
      size="small"
      sx={{
        minWidth: "180px",
        ".MuiSelect-select": {
          py: "4px",
        },
        border: "none",
        backgroundColor: "#fff",
        borderRadius: "0",
      }}
    >
      {selectValue === "" && (
        <MenuItem disabled value="">
          Select Team
        </MenuItem>
      )}
      {!location.pathname.includes("roster") && allTeamNeeded && (
        <MenuItem value={"all-teams"}>All teams</MenuItem>
      )}
      {teams
        ?.sort((a: any, b: any) => (a.team_name > b.team_name ? 1 : -1))
        ?.map((el: any) => (
          <MenuItem value={el.team_id}>{el.team_name}</MenuItem>
        ))}
    </Select>
  );
}
