import {
  Avatar,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonStyle from "../../Common/style/Style";
import { CoachRole, PageRoutes } from "../../utils/constant";
import handleSort from "../../hooks/useSort";

interface Column {
  id:
    | "jersey"
    | "player_name"
    | "play"
    | "gphome"
    | "ytdtotal"
    | "ytdavr"
    | "ytdmax";
  label: string;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "player_name", label: "Name" },
  // { id: "play", label: "" },
  { id: "gphome", label: "GP Home" },
  {
    id: "ytdtotal",
    label: "YTD TOTAL",
  },
  {
    id: "ytdavr",
    label: "YTD AVR",
  },
  {
    id: "ytdmax",
    label: "YTD MAX",
  },
];

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    backgroundColor: "#cecece",
  },
}));

const Attendance = (props: any) => {
  const { data } = props;
  const navigate = useNavigate();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  }>({
    key: "",
    direction: "asc",
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const sortTable = (key: string, flag: any) => {
    let direction: "desc" | "asc" = "desc";
    if (searchParams.get("sort") != key) {
      searchParams.set("sort", key);
      direction = "desc";
    } else {
      if (searchParams.get("direction") === "desc") {
        direction = "asc";
      } else {
        direction = "desc";
      }
    }
    searchParams.set("direction", direction);
    columns.forEach((column) => {
      if (column.id == key) {
        const elementsList: any = document.querySelectorAll(`#${column.id}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.add("wtd-statview-table-highlight-column-color");
        });
      } else {
        const elementsList: any = document.querySelectorAll(`#${column.id}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.remove(
            "wtd-statview-table-highlight-column-color"
          );
        });
      }
    });

    setSearchParams(searchParams);

    setSortConfig({ key, direction });
  };

  const player = [
    "player1",
    "player2",
    "player3",
    "player4",
    "player5",
    "player6",
    "player7",
    "player8",
    "player9",
    "player10",
    "player11",
    "player12",
  ];
  // const [searchParams, set,[SearchParams] = useSearchParams();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={CommonStyle.tableTitle}
                className="wtd-statview-group-header-color"
                colSpan={
                  // playerPosition == "Goalies"
                  //   ? columnsGollie.length
                  //   : playerPosition == "Coaches"
                  //   ? columnsCoach.length
                  //   : columns.length
                  columns.length
                }
              >
                ATTENDANCE
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                // key={column.id}
                align="left"
                onClick={() => sortTable("team_name", "table")}
                sx={{
                  ...CommonStyle.tableHeader,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                className="wtd-statview-header-color"
              >
                TEAM
              </TableCell>
              <TableCell
                // key={column.id}
                align="left"
                onClick={() => sortTable("games_played", "table")}
                sx={{
                  ...CommonStyle.tableHeader,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                className="wtd-statview-header-color"
              >
                GP HOME
              </TableCell>
              <TableCell
                // key={column.id}
                onClick={() => sortTable("total_att", "table")}
                align="left"
                sx={{
                  ...CommonStyle.tableHeader,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                className="wtd-statview-header-color"
              >
                YTD TOTAL
              </TableCell>
              <TableCell
                // key={column.id}
                onClick={() => sortTable("ave_att", "table")}
                align="left"
                sx={{
                  ...CommonStyle.tableHeader,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                className="wtd-statview-header-color"
              >
                YTD AVR
              </TableCell>
              <TableCell
                // key={column.id}
                onClick={() => sortTable("max_att", "table")}
                align="left"
                sx={{
                  ...CommonStyle.tableHeader,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                className="wtd-statview-header-color"
              >
                YTD MAX
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.filter((el: any, index: any) => index != data?.length - 1 && el)
              ?.sort((a: any, b: any) => handleSort(a, b, sortConfig))
              ?.map((item: any, index: any) => (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  className="wtd-statview-table-row-color"
                >
                  <TableCell sx={{ py: 1 }} align={"left"}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <Avatar src="" />
                      <Typography
                        variant="body2"
                        sx={{
                          ":hover": {
                            textDecoration: "underline",
                            cursor: "pointer",
                            fontWeight: 600,
                          },
                        }}
                      >
                        {item.team_name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 1 }} align={"left"}>
                    {item.games_played}
                  </TableCell>

                  <TableCell sx={{ py: 1 }} align={"left"}>
                    {item.total_att}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} align={"left"}>
                    {item.ave_att}
                  </TableCell>
                  <TableCell sx={{ py: 1 }} align={"left"}>
                    {item.max_att}
                  </TableCell>
                </StyledTableRow>
              ))}
            <StyledTableRow
              hover
              role="checkbox"
              tabIndex={-1}
              className="wtd-statview-table-row-color"
            >
              <TableCell sx={{ py: 1 }} align={"left"}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Avatar src="" />
                  <Typography
                    variant="body2"
                    sx={{
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: 600,
                      },
                    }}
                  >
                    {data[data.length - 1]?.team_name}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell sx={{ py: 1 }} align={"left"}>
                {data[data.length - 1]?.games_played}
              </TableCell>

              <TableCell sx={{ py: 1 }} align={"left"}>
                {data[data.length - 1]?.total_att}
              </TableCell>
              <TableCell sx={{ py: 1 }} align={"left"}>
                {data[data.length - 1]?.ave_att}
              </TableCell>
              <TableCell sx={{ py: 1 }} align={"left"}>
                {data[data.length - 1]?.max_att}
              </TableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Attendance;
