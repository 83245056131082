import React, { useCallback, useEffect, useState } from "react";
import SpecialCardDetail from "../Card/SpecialCard";
import { Box, CircularProgress, Grid } from "@mui/material";
import { makeGetRequest } from "../../../service";
import { useSearchParams } from "react-router-dom";

const SpecialTeams = () => {
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [specialTeamData, setSpecialTeamData] = useState<any>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const team_id = searchParams.get("team_id");
  const season_id = searchParams.get("season_id");
  const stat_class = searchParams.get("stat_class");

  const fetchDataforSpecialTeam = async () => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4,
      season_id,
      do_conf: 1,
      team_id,
      stat_class,
    };
    if (searchParams.get("stat_class")) {
      qurey.stat_class = searchParams.get("stat_class");
    }
    setIsSkeleton(true);
    try {
      const res = await makeGetRequest(
        "get_special_teams_stats",
        new URLSearchParams(qurey).toString()
      );
      setTimeout(() => {
        const id: any = searchParams.get("sort") || "pts";
        const elementsList: any = document.querySelectorAll(`#${id}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.add("wtd-statview-table-highlight-column-color");
        });
      }, 1);
      setTimeout(() => {
        const id: any = searchParams.get("sort") || "pts";
        const elementsList: any = document.querySelectorAll(`#${id}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.add("wtd-statview-table-highlight-column-color");
        });
      }, 1);

      const teams: any = [];
      res?.special_teams_stats?.conferences?.map((el: any) => {
        return el.teams?.map((item: any) => {
          return teams.push(item);
        });
      });
      setSpecialTeamData(teams);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    fetchDataforSpecialTeam();
  }, [season_id, team_id, stat_class]);

  const selectedTeam: any = () => {
    return specialTeamData.find((el: any) => el.team_id === team_id);
  };

  function getOrdinalSuffix(number: number) {
    let suffix;
    if (10 <= number % 100 && number % 100 <= 20) {
      suffix = "th";
    } else {
      const lastDigit = number % 10;
      suffix = { 1: "st", 2: "nd", 3: "rd" }[lastDigit] || "th";
    }
    return `${number}${suffix}`;
  }

  return (
    <>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "100px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Grid container spacing={{ md: 5, xs: 3 }}>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <SpecialCardDetail
              title="Power Play %"
              data={
                selectedTeam()?.power_play_pct
                  ? (Number(selectedTeam()?.power_play_pct) * 100).toFixed(2) +
                    "%"
                  : "N/A"
              }
              subtitle={
                getOrdinalSuffix(
                  specialTeamData
                    .sort((teamA: any, teamB: any) => {
                      return (
                        Number(teamB.power_play_pct) -
                        Number(teamA.power_play_pct)
                      );
                    })
                    .indexOf(selectedTeam()) + 1
                ) + " in League"
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <SpecialCardDetail
              title="Penalty Kill %"
              data={
                selectedTeam()?.penalty_kill_pct
                  ? (Number(selectedTeam()?.penalty_kill_pct) * 100).toFixed(
                      2
                    ) + "%"
                  : "N/A"
              }
              subtitle={
                getOrdinalSuffix(
                  specialTeamData
                    .sort((teamA: any, teamB: any) => {
                      return (
                        Number(teamB.penalty_kill_pct) -
                        Number(teamA.penalty_kill_pct)
                      );
                    })
                    .indexOf(selectedTeam()) + 1
                ) + " in League"
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <SpecialCardDetail
              title="Power Play Goals"
              data={selectedTeam()?.power_play_goals || "N/A"}
              subtitle={
                getOrdinalSuffix(
                  specialTeamData
                    .sort((teamA: any, teamB: any) => {
                      return (
                        Number(teamB.power_play_goals) -
                        Number(teamA.power_play_goals)
                      );
                    })
                    .indexOf(selectedTeam()) + 1
                ) + " in League"
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <SpecialCardDetail
              title="Short-Handed Goals"
              data={selectedTeam()?.penalty_kill_goals || "N/A"}
              subtitle={
                getOrdinalSuffix(
                  specialTeamData
                    .sort((teamA: any, teamB: any) => {
                      return (
                        Number(teamB.penalty_kill_goals) -
                        Number(teamA.penalty_kill_goals)
                      );
                    })
                    .indexOf(selectedTeam()) + 1
                ) + " in League"
              }
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SpecialTeams;
