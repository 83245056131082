import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";

const TableWithTotal = (props: any) => {
  const sumOfData = (key: string): number => {
    return props?.playerData?.teams?.reduce(
      (sum: number, item: any) => sum + Number(item[key]),
      0
    );
  };

  const avOdData = (key: string): number => {
    const data = props?.listData?.reduce(
      (sum: number, item: any) => sum + Number(item[key]),
      0
    );
    return data / props?.listData?.length;
  };

  const sumOfDataMinutes = (): any => {
    let totalHours = 0;
    let totalMinutes = 0;
    // Iterate through the array of time durations
    for (let i = 0; i < props?.playerData?.teams?.length; i++) {
      const [hours, minutes] = props?.playerData?.teams[i].toi
        .split(":")
        .map(Number);
      totalHours += hours;
      totalMinutes += minutes;
    }
    if (totalMinutes >= 60) {
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
    }
    const result = `${totalHours}:${totalMinutes.toString().padStart(2, "0")}`;
    return result;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.headers?.map((headItem: any) => (
                <TableCell
                  key={headItem.id}
                  className={`wtd-statview-header-color ${
                    headItem.id == "pts"
                      ? "wtd-statview-table-highlight-column-color"
                      : ""
                  }`}
                  align={headItem?.align ? headItem.align : "center"}
                  sx={{
                    ...CommonStyle.tableHeader,
                  }}
                >
                  {headItem.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listData?.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index + 1}
                  className={`wtd-statview-table-row-color`}
                >
                  {props.headers?.map((headItem: any) => {
                    const value = row[headItem.id];
                    return headItem.type !== "img" ? (
                      headItem.id == "record" ? (
                        <TableCell
                          sx={{
                            py: 1,
                          }}
                          key={headItem.id}
                          id={headItem.id}
                          align={headItem?.align ? headItem.align : "center"}
                        >
                          {`${row.wins}-${row.losses}-${row.otlosses}-${row.so_losses}-${row.ties}`}
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{
                            py: 1,
                          }}
                          key={headItem.id}
                          id={headItem.id}
                          className={`${
                            headItem.id == "pts"
                              ? "wtd-statview-table-highlight-column-color"
                              : "auto"
                          }`}
                          align={headItem?.align ? headItem.align : "center"}
                        >
                          {headItem.format && typeof value === "number"
                            ? headItem.format(value)
                            : value}
                        </TableCell>
                      )
                    ) : (
                      <TableCell
                        sx={{ py: 1, width: "32px" }}
                        key={headItem.id}
                        id={headItem.id}
                        align={headItem?.align ? headItem.align : "left"}
                      >
                        <Box
                          sx={{ display: "flex", gap: 1, alignItems: "center" }}
                        >
                          <Avatar
                            sx={{ width: "32px", height: "32px" }}
                            src={row.logo}
                          />
                          <Typography>{row?.short_name}</Typography>
                        </Box>
                      </TableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
            {props.listData?.length ? (
              <StyledTableRow hover role="checkbox" tabIndex={-1}>
                <TableCell sx={{ py: 1 }} align="center">
                  Total
                </TableCell>
                <TableCell sx={{ py: 1 }} align={"center"}></TableCell>
                {props.headers?.map((totalItem: any) => {
                  if (totalItem.total == true)
                    return (
                      <TableCell
                        sx={{ py: 1 }}
                        align={totalItem?.align ? totalItem.align : "center"}
                      >
                        {props?.playerData?.teams?.length
                          ? totalItem?.id == "toi"
                            ? sumOfDataMinutes()
                            : sumOfData(totalItem?.id)
                          : ""}
                      </TableCell>
                    );
                })}
              </StyledTableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableWithTotal;
