import React from "react";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import PublicRoute from "./Common/RouteAccessor/PublicRoute";
import TableComponent from "./Pages/TableComponent";
import { PageRoutes } from "./utils/constant";
import PlayerStat from "./Pages/Stats/PlayerStat";
import StandingStat from "./Pages/Stats/StandingStat";
import GameCenterBoard from "./Pages/GameCenter";
import GameCenter from "./Common/Component/GameCenter";
import PlayerProfile from "./Pages/PlayerProfile";
import Schedule from "./Pages/Schedule";
import DailySchedule from "./Pages/DailySchedule";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Attendance from "./Pages/Attendance";
import AttendaceMain from "./Pages/Attendance/attendance";
import Streaks from "./Pages/Streaks";
import Transactions from "./Pages/Transactions";
import PlayerSearch from "./Pages/PlayerSearch";
import TeamDetailPage from "./Pages/TeamDetail";
import DailyReportPage from "./Pages/DailyReportPage";
import HeadToHeadPage from "./Pages/HeadToHeadPage";

function App() {
  console.log(PageRoutes);//
  window.addEventListener("popstate", (event) => {
    if (event.state && event.state.param) {
      var currentURL = window.location.href;
      var index = currentURL.lastIndexOf("&");
      var newURL = currentURL.substring(
        0,
        index !== -1 ? index : currentURL.indexOf("?")
      );
      window.history.replaceState({}, "", newURL);
    } else {
      window.history.back();
    }
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Routes>
        <Route
          path={PageRoutes.RosterPage}
          element={
            <PublicRoute>
              <TableComponent />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.Attendace}
          element={
            <PublicRoute>
              <AttendaceMain />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.Streaks}
          element={
            <PublicRoute>
              <Streaks />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.Transaction}
          element={
            <PublicRoute>
              <Transactions />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.PlayerSearch}
          element={
            <PublicRoute>
              <PlayerSearch />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.GoalieStat}
          element={
            <PublicRoute>
              <PlayerStat />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.PlayerStat}
          element={
            <PublicRoute>
              <PlayerStat />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.StandingStat}
          element={
            <PublicRoute>
              <StandingStat isTeam={false} />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.TeamStats}
          element={
            <PublicRoute>
              <StandingStat isTeam={true} />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.GameCenter}
          element={
            <PublicRoute>
              <GameCenterBoard />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.TeamScore}
          element={
            <PublicRoute>
              <GameCenter />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.PlayerProfile}
          element={
            <PublicRoute>
              <PlayerProfile />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.DailyReport}
          element={
            <PublicRoute>
              <DailyReportPage />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.DailySchedule}
          element={
            <PublicRoute>
              <DailySchedule />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.Schedule}
          element={
            <PublicRoute>
              <Schedule />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.TeamDetail}
          element={
            <PublicRoute>
              <TeamDetailPage />
            </PublicRoute>
          }
        />
        <Route
          path={PageRoutes.HeadToHeadTeams}
          element={
            <PublicRoute>
              <HeadToHeadPage />
            </PublicRoute>
          }
        />
        <Route path={PageRoutes.Empty} element={<div></div>} />
      </Routes>
    </LocalizationProvider>
  );
}

export default App;
