/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable eqeqeq */
import { Box, MenuItem, Select, useMediaQuery } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import timerGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { CalendarOptions } from "@fullcalendar/core";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import { makeGetRequest } from "../../service";
import { useSearchParams } from "react-router-dom";
import { MonthsOption } from "../../utils/constant";
import CommonStyle from "../../Common/style/Style";
const league_id = window.customconfiguration?.league_id;
function DailyReportPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down("540"));
  const level_id = searchParams.get("level_id");
  const [seasonId, setSeasonId] = useState(searchParams.get("season_id"));
  const calendarRef = useRef<any>(null);
  const [selectedValue, setSelectedValue] = useState(
    searchParams.get("month")?.padStart(2, "0") || moment().format("MM")
  );
  const [league, setLeague] = useState([]);
  const handleMonthChange = (newMonth: any) => {
    if (calendarRef.current) {
      const currentDate = moment(`${moment().year()}-${newMonth}-01`);
      calendarRef.current.getApi().gotoDate(currentDate.toDate());
    }
  };
  useEffect(() => {
    // Set initial calendar view to the current year and month
    if (calendarRef.current) {
      const currentDate = moment(
        `${moment().year()}-${searchParams.get("month") || "01"}-01`
      );
      calendarRef.current.getApi().gotoDate(currentDate.toDate());
    }
  }, [searchParams.get("month")]);
  const fetachSeason = async () => {
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );
      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setLeague(response.leagues[0].seasons);
      let filterdSeasonIdForTeam;
      if (
        searchParams.get("team_id") &&
        searchParams.get("team_id") != "all-teams"
      ) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        season_id: filterSeasonId,
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        month: (moment().month() + 1).toString().padStart(2, "0"),
      };

      if (!searchParams.get("season_id")) {
        setMultiplaleParams(qurey);
      }
    } catch (error) {}
  };
  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };
  const dayCellContent = (renderProps: any) => {
    const cellStyle: any = {
      position: "relative",
      width: "100%",
      textAlign: "center",
    };

    const dateStyle: any = {
      fontSize: "14px",
      width: "20px",
      color: "#000000",
    };
    console.log(renderProps.date);

    return (
      <div style={cellStyle}>
        <div style={dateStyle}>{moment(renderProps.date).format("D")}</div>
        {moment(renderProps.date).isAfter(moment("2024-01-18")) &&
          moment(renderProps.date).isSameOrBefore(moment(), "day") && (
            <a
              href={`https://images-us-east.htptv.net/nahl/NA Today/${
                league_id == 5 ? "NA3HL " : "NAHL "
                // eslint-disable-next-line eqeqeq
              }Today/${league_id == 5 ? "na3hl" : "nahl"}${searchParams.get(
                "season_id"
              )}today_${moment(renderProps.date).format("YYYYMMDD")}.pdf`}
              target="_blank"
              rel="noreferrer"
              style={{
                textAlign: "center",
                color: "#0055a5",
                display: "felx",

                fontSize: isMobile ? "10px" : "14px",
              }}
            >
              {league_id == 5 ? "NA3HL TODAY REG" : "NAHL TODAY REG"}
            </a>
          )}{" "}
      </div>
    );
  };
  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      league_id: window?.customconfiguration?.league_id || 4,
    };
    setSeasonId(event.target.value);
    setMultiplaleParams(params);
  };
  useEffect(() => {
    fetachSeason();
  }, []);
  return (
    <div>
      <Box
        className="wtd-statview-filter"
        sx={{
          ...CommonStyle.tableControl,
          display: "flex",
          gap: 1,
          px: 1,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <SelectDropdownLeague league={league} handleChange={handleChange} />
        <Select
          displayEmpty
          id="demo-simple-select-autowidth"
          autoWidth
          value={selectedValue}
          onChange={(e) => {
            let params: any;
            params = {
              season_id: searchParams.get("season_id"),
              league_id: window?.customconfiguration?.league_id || 4,

              month: e.target.value?.toString(),
            };

            setSearchParams(params);
            handleMonthChange(e.target.value?.toString());
            setSelectedValue(e.target.value?.toString());
          }}
          defaultValue={searchParams.get("month") || moment().format("MM")}
          size="small"
          sx={{
            minWidth: "180px",
            ".MuiSelect-select": {
              py: "4px",
            },
            border: "none",
            backgroundColor: "#fff",
            borderRadius: "0",
          }}
        >
          {MonthsOption.map((el: any) => (
            <MenuItem value={el?.key}>{el?.label}</MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          p: 2,
          ".fc .fc-daygrid-day-number": {
            width: "100% !important",
          },
        }}
      >
        <FullCalendar
          ref={calendarRef}
          plugins={[
            dayGridPlugin,
            timerGridPlugin,
            interactionPlugin,
            timeGridPlugin,
          ]}
          dayHeaderFormat={{ weekday: "short" }}
          allDaySlot={false}
          eventColor="green"
          dayCellContent={dayCellContent}
          initialView="dayGridMonth"
          headerToolbar={{
            start: "prev",
            center: "title",
            end: "next",
          }}
          height={"90vh"}
        />
      </Box>
    </div>
  );
}

export default DailyReportPage;
