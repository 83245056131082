import { Box, Typography } from "@mui/material";
import React from "react";

const TableFooter = (props: any) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        py: 2,
        px: 1,
      }}
    >
      {props.content?.map((contentItem: any, index: number) => {
        return (
          <Box key={index + 1}>
            {contentItem?.position == "X" ? (
              <Typography variant="body2">
                {" "}
                Head Coach :{contentItem?.name}
              </Typography>
            ) : contentItem?.position == "Y" ? (
              <Typography variant="body2">
                Assistant Coach :{contentItem?.name}
              </Typography>
            ) : contentItem?.position == "Z" ? (
              <Typography variant="body2">
                Student Coach :{contentItem?.name}
              </Typography>
            ) : (
              ""
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default TableFooter;
