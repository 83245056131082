import {
    Avatar,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
import React from 'react'
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonStyle from "../../Common/style/Style";
import { CoachRole, PageRoutes } from "../../utils/constant";



interface Column {
    id:
      | "date"
      | "team"
      | "player"
      | "type"
      | "status"
    label: string;
    align?: "right";
  }
  
  const columns: readonly Column[] = [
   {
      id: "date",
      label: "",
    },
    {
      id: "team",
      label: "Team",
    },
    {
      id: "player",
      label: "Player",
    },
    {
      id: "type",
      label: "Type",
    },
  ];
  export const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#cecece",
    },
  }));


const TransactionsTable = () => {
    return (
        <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
          <TableContainer sx={{ maxHeight: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          Team
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          Player
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          Type
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >

                        </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      className="wtd-statview-table-row-color"
                    >
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        2018-01-27
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <Avatar src="" />
                          <Typography
                            variant="body2"
                            sx={{
                              ":hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontWeight: 600,
                              },
                            }}
                          >
                            Evansville Thunderbolts
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        Lee Christensen (D)
                      </TableCell>
    
                      <TableCell sx={{ py: 1 }} align={"left"}>
                       ADD
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        Added
                      </TableCell>
                    </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      );
}

export default TransactionsTable