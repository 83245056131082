import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Avatar,
  Link,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { PageRoutes } from "../../utils/constant";
import { StyledTableRow } from "../TableComponent/Table";

const TSTable = (props: any) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.headers?.map((headItem: any) => (
                <TableCell
                  key={headItem.id}
                  align={headItem?.align ? headItem.align : "center"}
                  sx={CommonStyle.tableHeader}
                  className="wtd-statview-header-color"
                  colSpan={2}
                >
                  {headItem.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listData?.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index + 1}
                  className="wtd-statview-table-row-color"
                >
                  <TableCell
                    sx={{ py: 1, width: "50px" }}
                    align={props?.align ? props.align : "left"}
                  >
                    <Avatar
                      src={row.player_image}
                      style={{
                        width: "50px",
                        height: "50px",
                        textAlign: "center",
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ float: "left", px: 0, width: "82%" }}>
                    <Link
                      sx={{ color: "black", textDecoration: "none" }}
                      href={`${PageRoutes.PlayerProfile}?player_id=${row?.player_id}`}
                    >
                      <Typography sx={{ cursor: "pointer" }} variant="body1">
                        #{row.jersey} {row.player_name?.toUpperCase()}
                      </Typography>
                    </Link>
                    <Typography variant="body1" sx={{ color: "grey" }}>
                      ({row.goals}-{row?.assists}-{row?.points})
                    </Typography>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TSTable;
