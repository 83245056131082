const HokeyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 95 118.75"
      enable-background="new 0 0 95 95"
      xmlSpace="preserve"
    >
      <symbol id="a" viewBox="-16.9 -10.2 33.9 20.4">
        <path
          fill="none"
          stroke="#FFFFFF"
          stroke-miterlimit="10"
          d="M16.4-6.3c0-1.9-1.5-3.4-3.4-3.4h-26   c-1.9,0-3.4,1.5-3.4,3.4V6.3c0,1.9,1.5,3.4,3.4,3.4h26c1.9,0,3.4-1.5,3.4-3.4V-6.3z"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.25"
          stroke-miterlimit="10"
          x1="-13"
          y1="9.7"
          x2="-13"
          y2="-9.7"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.25"
          stroke-miterlimit="10"
          x1="13"
          y1="9.7"
          x2="13"
          y2="-9.7"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.5"
          stroke-miterlimit="10"
          x1="-5.5"
          y1="9.7"
          x2="-5.5"
          y2="-9.7"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.5"
          stroke-miterlimit="10"
          x1="0"
          y1="9.7"
          x2="0"
          y2="-9.7"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.5"
          stroke-miterlimit="10"
          x1="5.5"
          y1="9.7"
          x2="5.5"
          y2="-9.7"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.2298"
          stroke-miterlimit="10"
          d="M-7.5,4.9c0-1.2-1-2.1-2.1-2.1   c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1C-8.5,7-7.5,6.1-7.5,4.9z"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.2298"
          stroke-miterlimit="10"
          d="M-7.5-4.5c0-1.2-1-2.1-2.1-2.1   c-1.2,0-2.1,1-2.1,2.1c0,1.2,1,2.1,2.1,2.1C-8.5-2.3-7.5-3.3-7.5-4.5z"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.2298"
          stroke-miterlimit="10"
          d="M7.5,4.9c0-1.2,1-2.1,2.1-2.1   c1.2,0,2.1,1,2.1,2.1c0,1.2-1,2.1-2.1,2.1C8.5,7,7.5,6.1,7.5,4.9z"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.2298"
          stroke-miterlimit="10"
          d="M7.5-4.5c0-1.2,1-2.1,2.1-2.1   c1.2,0,2.1,1,2.1,2.1c0,1.2-1,2.1-2.1,2.1C8.5-2.3,7.5-3.3,7.5-4.5z"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          stroke-width="0.2298"
          stroke-miterlimit="10"
          d="M2.4,0c0-1.3-1.1-2.4-2.4-2.4   c-1.3,0-2.4,1.1-2.4,2.4c0,1.3,1.1,2.4,2.4,2.4C1.3,2.4,2.4,1.3,2.4,0z"
        />
      </symbol>
      <use
        xlinkHref="#a"
        width="33.9"
        height="20.4"
        x="-16.9"
        y="-10.2"
        transform="matrix(2.6192 0 0 -2.6192 47.5573 48.5877)"
        overflow="visible"
      />
    </svg>
  );
};
export default HokeyIcon;
