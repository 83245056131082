import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Avatar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import { useState } from "react";
import CommonStyle from "../../../Common/style/Style";
import { StyledTableRow } from "../../TableComponent/Table";
import ScoringTableAccordian from "./ScoringTableAccordian";

const ScoringTable = ({ el, index, data }: any) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                className={"wtd-statview-header-color"}
                align={"left"}
                sx={{
                  ...CommonStyle.tableHeader,
                }}
              >
                {data.key == 1 ? "1st Period" : data.key == 2 ? "2nd Period" : data.key == 3 ? "3rd Period" : data.key}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{el?.length > 0 ? el?.map((element: any, index: any) => <ScoringTableAccordian element={element} index={index} />) : <Typography sx={{ textAlign: "center", py: 1 }}>No Scoring</Typography>}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default ScoringTable;
