import { FormControlLabel, Switch } from "@mui/material";
import React from "react";

const ToggleSwitch = (props: any) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={props.switch}
          onChange={() => props.setSwitch()}
          color="primary"
        />
      }
      label={props.label}
      defaultChecked={props.switch}
      labelPlacement="start"
    />
  );
};

export default ToggleSwitch;
