import { TableCell, Typography, Avatar, IconButton, Link } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageRoutes } from "../../utils/constant";
import { StyledTableRow } from "../TableComponent/Table";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
const GoalieStatRow = ({ goalieItem, index, sortKey, teamLogo }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [addRow, setAddRow] = useState(false);
  const handleAddRow = () => {
    setAddRow(!addRow);
  };
  const newPlayersData = goalieItem?.teams?.map((player: any) => ({
    ...player,
    points_per_game: (Number(player?.games_played) !== 0
      ? Number(player.points) / Number(player.games_played)
      : 0
    )?.toFixed(2),
    penalty_minutes_per_game: (Number(player?.games_played) !== 0
      ? Number(player.pims) / Number(player.games_played)
      : 0
    )?.toFixed(2),
    shooting_percentage: (Number(player?.sog) !== 0
      ? Number(player?.goals) / Number(player?.sog)
      : 0
    ).toFixed(1),
    shootout_percentage: (Number(player.shoa) !== 0
      ? Number(player.shog) / Number(player.shoa)
      : 0
    ).toFixed(1),
  }));

  return (
    <>
      {newPlayersData
        ?.filter((el: any, index: any) => index == 0)
        ?.map((el: any) => (
          <StyledTableRow
            hover
            key={index + 1}
            role="checkbox"
            tabIndex={-1}
            className="wtd-statview-table-row-color"
            sx={{ py: 1 }}
          >
            <TableCell
              id={"rank"}
              sx={{ paddingRight: 0, paddingLeft: 1 }}
              align={"left"}
            >
              {index + 1}
            </TableCell>
            <TableCell sx={{ paddingRight: 0, paddingLeft: 0 }} align={"left"}>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {goalieItem.display_flags == "R" ? "*" : ""}
                </Typography>

                <Typography variant="body2">
                  {goalieItem.active == 1 ? "" : "X"}
                </Typography>
              </Box>
            </TableCell>
            <TableCell
              sx={{
                paddingRight: 0,
                paddingLeft: 1,
                backgroundColor: sortKey === "jersey" ? "#cecece" : "",
              }}
              align={"left"}
            >
              {goalieItem.jersey}
            </TableCell>

            <TableCell
              id={"player_name"}
              sx={{
                py: 1,
                px: 0,
                maxWidth: "120px",

                backgroundColor: sortKey === "player_name" ? "#cecece" : "",
              }}
              align={"left"}
            >
              <Box
                sx={{
                  color: "black",
                  textDecoration: "none",
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                  width: "120px",
                }}
              >
                <Link
                  sx={{
                    color: "black",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                    width: "120px",
                  }}
                  href={`${PageRoutes.PlayerProfile}?player_id=${
                    goalieItem.player_id
                  }${
                    searchParams.get("stat_class")
                      ? `&stat_class=${searchParams.get("stat_class")}`
                      : ""
                  }&league_id=${
                    window?.customconfiguration?.league_id || 4 || 4
                  }`}
                >
                  <Avatar
                    src={goalieItem.player_image}
                    sx={{
                      ">img": {
                        width: "40px !important",
                        height: "40px !important",
                        objectFit: "cover",
                      },
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      textOverflow: "ellipsis",
                      ":hover": {
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: 600,
                      },
                    }}
                  >
                    {goalieItem.player_name}
                  </Typography>
                </Link>

                {goalieItem?.teams?.length >= 2 && (
                  <IconButton onClick={handleAddRow}>
                    {addRow ? (
                      <RemoveCircleOutlineIcon />
                    ) : (
                      <AddCircleOutlineIcon />
                    )}{" "}
                  </IconButton>
                )}
              </Box>
            </TableCell>

            <TableCell
              sx={{
                py: 1,
                paddingRight: 0,
                paddingLeft: 1,
                display: "flex",
                justifyContent: "center",
                backgroundColor:
                  sortKey === "team_ab" ? "#cecece" : "transparent",
              }}
              align={"left"}
            >
              <Avatar
                sx={{ bgcolor: "transparent" }}
                src={goalieItem?.smlogo}
              />
            </TableCell>
            <TableCell
              id={"games_played"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "games_played" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.games_played}
            </TableCell>
            <TableCell
              sx={{
                py: 1,
                backgroundColor: sortKey === "toi" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.toi}
            </TableCell>
            <TableCell
              sx={{
                py: 1,
                backgroundColor:
                  sortKey === "goals_against_ave" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.goals_against_ave}
            </TableCell>
            <TableCell
              id={"wins"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "wins" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.wins}
            </TableCell>
            <TableCell
              id={"losses"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "losses" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.losses}
            </TableCell>
            <TableCell
              id={"ot_losses"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "ot_losses" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.ot_losses}
            </TableCell>
            <TableCell
              id={"so_losses"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "so_losses" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.so_losses}
            </TableCell>
            <TableCell
              sx={{
                py: 1,
                backgroundColor: sortKey === "save_pct" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.save_pct}
            </TableCell>
            <TableCell
              sx={{
                py: 1,
                backgroundColor: sortKey === "sog" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.sog}
            </TableCell>
            <TableCell
              id={"shots_against"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "shots_against" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.shots_against}
            </TableCell>
            <TableCell
              id={"goals_against"}
              sx={{
                py: 1,
                backgroundColor: sortKey === "goals_against" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.goals_against}
            </TableCell>
            <TableCell
              sx={{
                py: 1,
                backgroundColor: sortKey === "shutouts" ? "#cecece" : "",
              }}
              align={"center"}
            >
              {goalieItem?.shutouts}
            </TableCell>
          </StyledTableRow>
        ))}

      {addRow &&
        newPlayersData?.map((el: any) => (
          <StyledTableRow
            hover
            key={index + 1}
            role="checkbox"
            tabIndex={-1}
            className="wtd-statview-table-row-color"
          >
            <TableCell id={"rank"} sx={{ py: 1 }} align={"left"}></TableCell>
            <TableCell sx={{ py: 1 }} align={"left"}>
              <Box
                sx={{
                  display: "flex",
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <Typography variant="body2">
                  {el.display_flags == "R" ? "*" : ""}
                </Typography>
                <Typography variant="body2">
                  {el.active == 1 ? "" : "X"}
                </Typography>
              </Box>
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"left"}>
              {el.jersey}
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"center"}>
              <Avatar
                sx={{ bgcolor: "transparent" }}
                src={teamLogo ? teamLogo : el?.smlogo}
              />
            </TableCell>
            <TableCell id={"games_played"} sx={{ py: 1 }} align={"center"}>
              {el?.games_played}
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"center"}>
              {el?.toi}
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"center"}>
              {el?.goals_against_ave}
            </TableCell>
            <TableCell id={"wins"} sx={{ py: 1 }} align={"center"}>
              {el?.wins}
            </TableCell>
            <TableCell id={"losses"} sx={{ py: 1 }} align={"center"}>
              {el?.losses}
            </TableCell>
            <TableCell id={"ot_losses"} sx={{ py: 1 }} align={"center"}>
              {el?.ot_losses}
            </TableCell>
            <TableCell id={"so_losses"} sx={{ py: 1 }} align={"center"}>
              {el?.so_losses}
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"center"}>
              {el?.save_pct}
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"center"}>
              {el?.sog}
            </TableCell>
            <TableCell id={"shots_against"} sx={{ py: 1 }} align={"center"}>
              {el?.shots_against}
            </TableCell>
            <TableCell id={"goals_against"} sx={{ py: 1 }} align={"center"}>
              {el?.goals_against}
            </TableCell>
            <TableCell sx={{ py: 1 }} align={"center"}>
              {el?.shutouts}
            </TableCell>
          </StyledTableRow>
        ))}
    </>
  );
};
export default GoalieStatRow;
