import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useSearchParams } from "react-router-dom";

export default function SelectDropdownLeagueForPlayer({
  league,

  handleChange,
}: any) {
  const [searachParams, setSearchParams] = useSearchParams();

  return (
    <Select
      value={searachParams.get("season_id") || " "}
      defaultValue={searachParams.get("season_id") || " "}
      id="demo-simple-select-autowidth"
      onChange={(e) => handleChange(e)}
      autoWidth
      size="small"
      sx={{
        minWidth: "180px",
        ".MuiSelect-select": {
          py: "4px",
        },
        border: "none",
        backgroundColor: "#fff",
        borderRadius: "0",
      }}
    >
      <MenuItem value={" "}>All Games</MenuItem>
      {league
        ?.sort((a: any, b: any) => (a.start_date < b.start_date ? 1 : -1))
        ?.map((el: any) => (
          <MenuItem value={el.season_id}>{el.season_name}</MenuItem>
        ))}
    </Select>
  );
}
