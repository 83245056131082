import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import registered from "../../assets/img/registered-img.png";
import copyright from "../../assets/img/copyright-img.png";
import addText from "../../assets/img/add-text.png";
import CommonStyle from "../../Common/style/Style";
import styled from "@emotion/styled";
import { CoachRole, PageRoutes } from "../../utils/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { ref } from "@firebase/database";
import { onValue } from "firebase/database";
import { db, makeGetRequest } from "../../service";
import TableMain from "../GameCenter/TableMain";
import DailyScheduleTableMain from "./DailyScheduleMainTable";
import { wrap } from "module";

interface Column {
  id:
    | "date"
    | "away_team"
    | "away_goals"
    | "away_goals"
    | "location"
    | "home_team"
    | "home_goals"
    | "attendance"
    | "heightft"
    | "time"
    | "town";
  label: string;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "date", label: "date" },
  { id: "away_team", label: "Visiting" },
  { id: "away_goals", label: "GF" },
  { id: "home_team", label: "Home" },
  {
    id: "home_goals",
    label: "GF",
  },
  {
    id: "location",
    label: "Venue",
  },
  {
    id: "attendance",
    label: "Att",
  },
  {
    id: "time",
    label: "Time",
  },
  {
    id: "town",
    label: "",
  },
];

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    backgroundColor: "#cecece",
  },
}));
let liveData = { clock: "", period: "" };

const DailyScheduleTable = ({ el }: any) => {
  const [gameData, setGameData] = useState({} as any);
  const location = useLocation();
  const [isSkelton, setIsSkeleton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const season_id = searchParams.get("season_id");
  const stat_class = searchParams.get("stat_class");
  const [liveData, setLiveData] = useState<any>({});
  const getGameDetailsLive = async () => {
    let qurey: any = {
      game_id: el?.game_id,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: el?.game_id,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${el?.game_id}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData(data);
    } catch (error) {}
  };

  const createObject = (
    shots: any,
    summary: any,
    away_ab: any,
    shots_sum: any,
    stat: any
  ) => {
    const obje = gameData?.period_list?.reduce((obj: any, value: any) => {
      obj[value] = gameData?.[summary]?.[shots]?.[value];
      return obj;
    }, {});
    obje.sog = away_ab;
    if (summary == "goal_summary") {
      obje.total = gameData?.[summary]?.[shots]?.total;
    }
    obje.shot_total = gameData?.["shot_summary"]?.[shots_sum]?.total;
    obje.pp = `${gameData?.misc_summary?.[`${stat}_ppg`]}/${
      gameData?.misc_summary?.[`${stat}_pp`]
    }`;

    return obje;
  };
  function startup_new(channels: any) {
    var i = 0;

    for (i = 0; i < channels.length; i++) {
      /* Firebase mode */
      if (channels[i].game_id > 0)
        var firebase_channel =
          "games/" + channels[i].channel + "/" + channels[i].game_id;
      else var firebase_channel = "rinks/" + channels[i].channel;

      var rink_ref = ref(db, firebase_channel);
      console.log("firebase Starting " + firebase_channel);
      (function (prefix, game) {
        var last_game_id = 0;
        var requested_game = game;
        if (requested_game) {
          // GetGameData(prefix + "_", requested_game);
          // GetRosterData(requested_game);
          last_game_id = requested_game;
        }

        onValue(rink_ref, (snapshot: any) => {
          const fb_data = snapshot.val();
          if (fb_data == null) return;
          const data = fb_data.clock_data;

          if (fb_data.clock_data == null) return;

          if (requested_game && data.game_id != requested_game) {
            return;
          }

          if (data.rosters == 1 || data.events == 1) {
            getGameDetailsLive();
          }

          setLiveData(data);
          // process_scoreboard_data(prefix + "_", data);
          if (last_game_id != data.game_id) {
            // GetGameData(prefix + "_", data.game_id);

            last_game_id = data.game_id;
          }
        });
      })(channels[i].prefix, channels[i].game_id);
    }
  }
  const fiilterArray = (object: any) => {
    let data = [];
    for (const key in object) {
      data.push(object[key]);
    }

    return data;
  };
  function getOrdinalNumber(number: any) {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = number % 100;
    const suffix =
      suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];
    return number + suffix;
  }
  const getGameDetails = async () => {
    setIsSkeleton(true);
    let qurey: any = {
      game_id: el?.game_id,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: el?.game_id,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${el?.game_id}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };
      setIsSkeleton(false);

      startup_new(array);
      setGameData(data);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    getGameDetails();
  }, [el]);

  if (isSkelton) {
    return (
      <Box
        sx={{
          position: "relative",
          py: 2,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <CircularProgress />
        </Box>
      </Box>
    );
  }
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0, mb: 2 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <Box
                sx={{ ...CommonStyle.tableHeader, px: 1, fontWeight: 600 }}
                className="wtd-statview-header-color"
              >
                {el?.location}
              </Box>
            </TableRow>
          </TableHead>
          <TableBody>
            <StyledTableRow
              hover
              role="checkbox"
              tabIndex={-1}
              className="wtd-statview-table-el-color"
            >
              <TableCell sx={{ py: 1 }} align={"left"}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 20,
                        width: { xs: 1, md: "auto" },
                      }}
                    >
                      <Box
                        sx={{
                          minWidth: "300px",
                          width: { xs: 1, md: "auto" },
                          py: 1,
                          display: "flex",

                          justifyContent: "space-between",
                          flexDirection: { xs: "row", md: "column" },
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            alignItems: { xs: "flex-end", md: "center" },
                            flexDirection: { md: "row", xs: "column" },
                          }}
                        >
                          <Box>
                            <Avatar
                              sx={{ width: "40px", height: "40px" }}
                              src={
                                el?.away_smlogo
                                  ? el?.away_smlogo
                                  : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: { xs: "flex-end", md: "flex-start" },
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 600, cursor: "pointer" }}
                            >
                              <Link
                                sx={{ color: "black", textDecoration: "none" }}
                                href={`${PageRoutes.TeamDetail}?team_id=${el.away_id}&season_id=${season_id}&stat_class=${stat_class}`}
                              >
                                {el?.away_team ? el?.away_team : "Not set"}
                                <span style={{ fontWeight: "600" }}>
                                  {liveData?.clock &&
                                    `(${gameData?.goal_summary?.away_goals["total"]})`}
                                </span>
                              </Link>
                            </Typography>
                            <Typography variant="caption">{`${gameData?.["away_match_up"]?.["full_season"]?.wins}-${gameData?.["away_match_up"]?.["full_season"]?.losses}-${gameData?.["away_match_up"]?.["full_season"]?.ot_losses}-${gameData?.["away_match_up"]?.["full_season"]?.so_losses}`}</Typography>
                          </Box>
                        </Box>{" "}
                        <Box
                          sx={{
                            display: { xs: "flex", md: "none" },
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            variant="body1"
                            sx={{
                              fontSize: "1rem",
                              fontWeight: "700",
                              lineHeight: "1.5",
                            }}
                          >
                            {liveData?.period != " " && liveData?.period ? (
                              liveData?.period
                            ) : el?.game_status == "NOT STARTED" ? (
                              <>
                                {" "}
                                {moment(el?.time, "hh:mm:ss A").format(
                                  "hh:mm A"
                                )}{" "}
                                {el?.timezn_ab}
                              </>
                            ) : el?.game_status == "CLOSED" ? (
                              el?.result_string
                            ) : (
                              el?.game_status
                            )}
                          </Typography>
                          {liveData?.clock && el?.game_status == "OPEN" && (
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontSize: "1.2rem",
                                fontWeight: "700",
                                lineHeight: "1.5",
                              }}
                            >
                              {liveData?.clock}
                            </Typography>
                          )}
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 2,
                            flexDirection: { md: "row", xs: "column" },
                          }}
                        >
                          <Box>
                            <Avatar
                              sx={{ width: "40px", height: "40px" }}
                              src={
                                el?.home_smlogo
                                  ? el?.home_smlogo
                                  : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                              }
                            />
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: 600, cursor: "pointer" }}
                            >
                              <Link
                                sx={{ color: "black", textDecoration: "none" }}
                                href={`${PageRoutes.TeamDetail}?team_id=${el.home_id}&season_id=${season_id}&stat_class=${stat_class}`}
                              >
                                {el?.home_team ? el?.home_team : "Not set"}
                                <span style={{ fontWeight: "600" }}>
                                  {liveData?.clock &&
                                    `(${gameData?.goal_summary?.home_goals["total"]})`}
                                </span>
                              </Link>
                            </Typography>
                            <Typography variant="caption">{`${gameData?.["home_match_up"]?.["full_season"]?.wins}-${gameData?.["home_match_up"]?.["full_season"]?.losses}-${gameData?.["home_match_up"]?.["full_season"]?.ot_losses}-${gameData?.["home_match_up"]?.["full_season"]?.so_losses}`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "block" } }}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "700",
                          lineHeight: "1.5",
                        }}
                      >
                        {liveData?.period != " " && liveData?.period ? (
                          liveData?.period
                        ) : el?.game_status == "NOT STARTED" ? (
                          <>
                            {" "}
                            {moment(el?.time, "hh:mm:ss A").format(
                              "hh:mm A"
                            )}{" "}
                            {el?.timezn_ab}
                          </>
                        ) : el?.game_status == "CLOSED" ? (
                          el?.result_string
                        ) : (
                          el?.game_status
                        )}
                      </Typography>
                      {liveData?.clock && el?.game_status == "OPEN" && (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontSize: "1.2rem",
                            fontWeight: "700",
                            lineHeight: "1.5",
                          }}
                        >
                          {liveData?.clock}
                        </Typography>
                      )}
                    </Box>
                    <Box sx={{ width: { xs: 1, md: "auto" } }}>
                      {" "}
                      {el?.game_status != "NOT STARTED" ? (
                        gameData?.period_list && (
                          <DailyScheduleTableMain
                            headers={[
                              { id: "sog", label: "Scoring", align: "left" },
                              ...gameData?.period_list?.map((element: any) => {
                                return {
                                  id: element.toString(),
                                  label: element.toString(),
                                  align: "left",
                                };
                              }),
                              {
                                id: "total",
                                label: "T",
                                align: "left",
                              },
                              { id: "pp", label: "PP", align: "right" },
                              { id: "shot_total", label: "S", align: "right" },
                            ]}
                            listData={[
                              createObject(
                                "away_goals",
                                "goal_summary",
                                gameData?.away_ab,
                                "home_shots",
                                "away"
                              ),
                              createObject(
                                "home_goals",
                                "goal_summary",
                                gameData?.home_ab,
                                "away_shots",
                                "home"
                              ),
                            ]}
                          />
                        )
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            flexGrow: 1,
                            height: 1,
                          }}
                        >
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Link
                              sx={{ color: "black", textDecoration: "none" }}
                              href={`${PageRoutes.PlayerStat}?team_id=${el?.away_id}?season_id=${el?.season_id}?league_id=${el?.league_id}`}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: 600,
                                  ":hover": {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {" "}
                                Stats
                              </Typography>
                            </Link>
                            |
                            <Link
                              sx={{ color: "black", textDecoration: "none" }}
                              href={`${PageRoutes.Schedule}?team_id=${el?.away_id}?season_id=${el?.season_id}?league_id=${el?.league_id}`}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: 600,
                                  ":hover": {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {" "}
                                Schedule
                              </Typography>
                            </Link>
                          </Box>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Link
                              sx={{ color: "black", textDecoration: "none" }}
                              href={`${PageRoutes.PlayerStat}?team_id=${el?.home_id}?season_id=${el?.season_id}?league_id=${el?.league_id}`}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: 600,
                                  ":hover": {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {" "}
                                Stats
                              </Typography>
                            </Link>
                            |
                            <Link
                              sx={{ color: "black", textDecoration: "none" }}
                              href={`${PageRoutes.Schedule}?team_id=${el?.home_id}?season_id=${el?.season_id}?league_id=${el?.league_id}`}
                            >
                              <Typography
                                variant="body1"
                                sx={{
                                  fontWeight: 600,
                                  ":hover": {
                                    textDecoration: "underline",
                                    cursor: "pointer",
                                  },
                                }}
                              >
                                {" "}
                                Schedule
                              </Typography>
                            </Link>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                  <Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <Typography
                        sx={{ mr: 2, fontWeight: 600 }}
                        variant="body2"
                      >
                        {el?.away_ab ? el?.away_ab : "Not Set"} {": "}
                      </Typography>

                      {[]
                        .concat(...fiilterArray(gameData?.events))
                        .filter((el: any) => el?.type == "goal" && el)
                        .filter((el: any) => el?.team_id == gameData?.away_id)
                        .map((el: any, index: any) => (
                          <Typography variant="body2">
                            {" "}
                            {el?.goal_player_name}({el?.time}
                            {getOrdinalNumber(el?.period)}){" "}
                            {index !=
                              []
                                .concat(...fiilterArray(gameData?.events))
                                .filter((el: any) => el?.type == "goal" && el)
                                .filter(
                                  (el: any) => el?.team_id == gameData?.away_id
                                ).length && ","}
                          </Typography>
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <Typography
                        sx={{ mr: 2, fontWeight: 600 }}
                        variant="body2"
                      >
                        {el?.home_ab ? el?.home_ab : "Not Set"}
                      </Typography>{" "}
                      {[]
                        .concat(...fiilterArray(gameData?.events))
                        .filter((el: any) => el?.type == "goal" && el)
                        .filter((el: any) => el?.team_id == gameData?.home_id)
                        .map((el: any, index: any) => (
                          <Typography variant="body2">
                            {" "}
                            {el?.goal_player_name}({el?.time}
                            {getOrdinalNumber(el?.period)}){" "}
                            {index !=
                              []
                                .concat(...fiilterArray(gameData?.events))
                                .filter((el: any) => el?.type == "goal" && el)
                                .filter(
                                  (el: any) => el?.team_id == gameData?.home_id
                                ).length -
                                1 && ","}
                          </Typography>
                        ))}
                    </Box>
                  </Box>
                </Box>
              </TableCell>
            </StyledTableRow>
            <TableRow>
              <Box
                sx={{
                  ...CommonStyle.tableHeader,
                  px: 1,

                  fontWeight: 600,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                className="wtd-statview-header-color"
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <Link
                    href={`https://images-us-east.s3.us-east-2.amazonaws.com/nahl/Broadcast+Packs/${el?.game_id}.pdf`}
                    type="download"
                    target="_blank"
                    sx={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                      color: "white",
                    }}
                    download
                  >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        bgcolor: "#252525",
                        borderRadius: "50%",
                        p: "1px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: "bolder",
                          fontSize: "12px",
                          color: "white",
                        }}
                      >
                        BP
                      </Typography>{" "}
                    </Box>
                  </Link>

                  <Link
                    sx={{
                      cursor: "pointer",
                      color: "black",
                      textDecoration: "none",
                    }}
                    href={`${PageRoutes.GameCenter}?game_id=${el?.game_id}`}
                  >
                    <Avatar
                      src={`${process.env.REACT_APP_S3URL}/assets/C_Schedule.jpeg`}
                      sx={{
                        borderRadius: "50%",
                        width: "22px",
                        height: "22px",
                      }}
                    />
                  </Link>
                  <a href={el?.scoresheet_link} target="_blank">
                    <Avatar
                      sx={{
                        borderRadius: "50%",
                        width: "22px",
                        height: "22px",
                      }}
                      src={`${process.env.REACT_APP_S3URL}/assets/R_ScheduleImage.jpeg`}
                    />
                  </a>

                  <a href="https://www.nahltv.com/login" target={"_blank"}>
                    <Avatar
                      sx={{
                        borderRadius: "50%",
                        height: "36px",
                        width: "60px",
                      }}
                      src={`https://images-us-east.s3.us-east-2.amazonaws.com/nahl/web-logo/NAHLTV.png`}
                    />
                  </a>
                </Box>
              </Box>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default DailyScheduleTable;
