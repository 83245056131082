/* eslint-disable eqeqeq */
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  Typography,
  Box,
  Link,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { PageRoutes } from "../../utils/constant";
import { StyledTableRow } from "../TableComponent/Table";

const TableMain = (props: any) => {
  const { isClickableRow = false } = props;
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {props.headers?.map((headItem: any) => (
                <TableCell
                  key={headItem.id}
                  className={`wtd-statview-header-color ${
                    headItem.id == "pts"
                      ? "wtd-statview-table-highlight-column-color"
                      : ""
                  }`}
                  align={headItem?.align ? headItem.align : "center"}
                  sx={{
                    ...CommonStyle.tableHeader,
                    px: "15px",
                  }}
                >
                  {headItem.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props?.listData?.length > 0 ? (
              props.listData?.map((row: any, index: number) => {
                return (
                  <Link
                    component={StyledTableRow}
                    href={
                      isClickableRow
                        ? `${PageRoutes.GameCenter}?game_id=${row?.game_id}`
                        : undefined
                    }
                    hover
                    sx={{ textTransform: "capitalize" }}
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                    className={`wtd-statview-table-row-color`}
                  >
                    {props.headers?.map((headItem: any) => {
                      const value = row[headItem.id];
                      return headItem.type !== "img" ? (
                        headItem.id == "record" ? (
                          <TableCell
                            sx={{
                              py: 1,
                            }}
                            key={headItem.id}
                            id={headItem.id}
                            align={headItem?.align ? headItem.align : "center"}
                          >
                            {`${row.total_wins}-${row.losses}-${row.otlosses}-${row.so_losses}`}
                          </TableCell>
                        ) : headItem.id == "penalty_name" ? (
                          <TableCell
                            sx={{
                              py: 1,
                              minWidth:
                                headItem.id == "penalty_player_name"
                                  ? { xs: "40px", md: "100px" }
                                  : "auto",
                              width:
                                headItem.id == "time_in"
                                  ? { xs: "40px", md: "80px" }
                                  : "auto",
                            }}
                            key={headItem.id}
                            id={headItem.id}
                            className={`${
                              headItem.id == "pts"
                                ? "wtd-statview-table-highlight-column-color"
                                : "auto"
                            }`}
                            align={headItem?.align ? headItem.align : "center"}
                          >
                            {value} ({row?.length}min)
                          </TableCell>
                        ) : (
                          <TableCell
                            sx={{
                              cursor: "pointer",
                              py: 1,
                              whiteSpace: { xs: "wrap", mg: "normal" },
                              width:
                                headItem.id == "time_in"
                                  ? { xs: "40px", md: "80px" }
                                  : headItem.id == "team_name_GOALTENDERS" ||
                                    headItem.id == "penalty_player_name"
                                  ? "250px"
                                  : "auto",
                            }}
                            key={headItem.id}
                            id={headItem.id}
                            className={`${
                              headItem.id == "pts"
                                ? "wtd-statview-table-highlight-column-color"
                                : "auto"
                            }`}
                            align={headItem?.align ? headItem.align : "center"}
                          >
                            <Link
                              sx={{ color: "black", textDecoration: "none" }}
                              href={
                                headItem.id == "name"
                                  ? `${PageRoutes.PlayerProfile}?player_id=${row?.player_id}`
                                  : headItem.id == "penalty_player_name"
                                  ? `${PageRoutes.PlayerProfile}?player_id=${row?.penalty_player_id}`
                                  : headItem.id == "match_name"
                                  ? ``
                                  : `${PageRoutes.GameCenter}?game_id=${row?.game_id}`
                              }
                            >
                              {headItem.format && typeof value === "number"
                                ? headItem.format(value)
                                : value}
                            </Link>
                          </TableCell>
                        )
                      ) : (
                        <TableCell
                          sx={{ py: 1, width: "32px" }}
                          key={headItem.id}
                          id={headItem.id}
                          align={headItem?.align ? headItem.align : "left"}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{ width: "32px", height: "32px" }}
                              src={
                                row[headItem.id]
                                  ? row[headItem.id]
                                  : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                              }
                            />
                            <Typography>{row?.short_name}</Typography>
                          </Box>
                        </TableCell>
                      );
                    })}
                  </Link>
                );
              })
            ) : (
              <StyledTableRow
                hover
                role="checkbox"
                tabIndex={-1}
                className={`wtd-statview-table-row-color`}
              >
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  {" "}
                  {props?.Title == "PENALTIES"
                    ? "No Penalties"
                    : props?.Title == "ROSTER"
                    ? "No Roster has been set."
                    : "No Data"}
                </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                <TableCell> </TableCell>
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
                {props?.Title == "ROSTER" && <TableCell> </TableCell>}
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableMain;
