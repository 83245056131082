import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  CircularProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { makeGetRequest } from "../../service";
import { useSearchParams } from "react-router-dom";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import SelectDropdownTeams from "../../Common/SelectDropdownTeam";
import SelectDropdownStat from "../../Common/SelectDropdownStat";
import { PageRoutes } from "../../utils/constant";

const headToHeadTeamDataColumn: any = [
  {
    title: "Overall Record",
    id: "overall_record",
  },
  {
    title: "Home Record",
    id: "home_record",
  },
  {
    title: "Away Record",
    id: "away_record",
  },
  {
    title: "Win %",
    id: "win_pct",
  },
  {
    title: "Points %",
    id: "pts_pct",
  },
  {
    title: "Goals For",
    id: "goals_for",
  },
  {
    title: "Goals Against",
    id: "goals_against",
  },
  {
    title: "Goal Differencial",
    id: "goal_differencial",
  },
  {
    title: "Penalty Minutes",
    id: "pims",
  },
  {
    title: "PP% Overall",
    id: "power_play_pct",
  },
  {
    title: "PP% Home",
    id: "home_power_play_pct",
  },
  {
    title: "PP% Away",
    id: "away_power_play_pct",
  },
  {
    title: "PK% Overall",
    id: "penalty_kill_pct",
  },
  {
    title: "PK% Home",
    id: "home_penalty_kill_pct",
  },
  {
    title: "PK% Away",
    id: "away_penalty_kill_pct",
  },
  {
    title: "Last 5 Overall",
    id: "last_5_overall",
  },
  {
    title: "Last 5 Home",
    id: "last_5_home",
  },
  {
    title: "Last 5 Away",
    id: "last_5_away",
  },
  {
    title: "Last 10 Overall",
    id: "last_10_overall",
  },
  {
    title: "Last 10 Home",
    id: "last_10_home",
  },
  {
    title: "Last 10 Away",
    id: "last_10_away",
  },
  {
    title: "Shootout Overall",
    id: "shootout_overall",
  },
  {
    title: "Shootout Home",
    id: "shootout_home",
  },
  {
    title: "Shootout Away",
    id: "shootout_away",
  },
  {
    title: "Head to Head",
    id: "head_to_head",
  },
  {
    title: "Points Leader",
    id: "points_leaders",
  },
  {
    title: "Goals Leader",
    id: "goals_leaders",
  },
  {
    title: "Assists Leader",
    id: "assists_leaders",
  },
  {
    title: "GAA Leader",
    id: "gaa_leaders",
  },
  {
    title: "SV% Leader",
    id: "save_pct_leaders",
  },
  {
    title: "Shutout Leader",
    id: "shutout_leaders",
  },
  {
    title: "PIM Leader",
    id: "pim_leaders",
  },
];

const HeadToHeadPage = () => {
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [teamData, setTeamData] = useState({} as any);
  const [league, setLeague] = useState([]);
  const [stats, setStats] = useState<any>([]);
  const [teams, setTeams] = useState([]);
  const [homeData, setHomeData] = useState<any>({});
  const [oppData, setOppData] = useState<any>({});

  const [searchParams, setSearchParams] = useSearchParams();
  const team_id =
    searchParams.get("team_id") || window?.customconfiguration?.team_id;
  const level_id = searchParams.get("level_id");
  const season_id = searchParams.get("season_id");
  const stat_class = searchParams.get("stat_class");
  const opp_id = searchParams.get("opp_id");

  const get_team_stats = async (query: any) => {
    setIsSkeleton(true);
    try {
      const resTeamData = await makeGetRequest(
        "get_team_stats",
        new URLSearchParams(query).toString()
      );
      setIsSkeleton(false);
      return resTeamData;
    } catch (error) {
      setIsSkeleton(false);
      return {};
    }
  };

  const fetchSeason = async () => {
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );

      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);
      let filterdSeasonIdForTeam;
      if (team_id != "all-teams" && team_id) {
        const res = await makeGetRequest("get_team_info", `team_id=${team_id}`);

        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        season_id ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const query: any = {
        team_id,
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!season_id) {
        setSearchParams(query);
      }
    } catch (error) {}
  };

  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };

  const fetchTeamData = async () => {
    let query: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id,
      stat_class: stat_class,
    };
    if (level_id) {
      query.level_id = level_id;
    }
    try {
      const respone = await makeGetRequest(
        "get_teams",
        new URLSearchParams(query).toString()
      );
      const findCurrntHomeTeam = respone.teams?.filter(
        (el: any) => el.team_id == team_id
      );

      let filterTeamId: any;
      if (findCurrntHomeTeam.length >= 1) {
        filterTeamId = findCurrntHomeTeam[0]?.team_id;
      } else {
        filterTeamId = respone.teams[0].team_id;
      }
      query = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        team_id: filterTeamId,
        stat_class: stat_class || "",
      };
      if (season_id) {
        query.season_id = season_id;
      }
      if (opp_id) {
        query.opp_id = opp_id;
      }
      const filterData = respone.teams?.filter(
        (el: any) => el.team_id == filterTeamId
      );

      setSearchParams(query);
      setTeams(respone.teams);
    } catch (error) {}
  };

  const fetchTeamStats = async () => {
    const homeTeamQuery: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,

      team_id,
      stat_class: stat_class || "",
    };

    const oppTeamQuery: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,

      opp_id: team_id,
      stat_class: stat_class || "",
    };
    if (season_id) {
      homeTeamQuery.season_id = season_id;
      oppTeamQuery.season_id = season_id;
    }
    if (opp_id) {
      homeTeamQuery.opp_id = opp_id;
      oppTeamQuery.team_id = opp_id;
    }
    let homeTeamData: any;
    let oppTeamData: any;
    if (team_id && team_id != "null") {
      homeTeamData = await get_team_stats(homeTeamQuery);
    }
    if (opp_id && opp_id != "null") {
      oppTeamData = await get_team_stats(oppTeamQuery);
    }

    setHomeData(homeTeamData?.team_stats);
    setOppData(oppTeamData?.team_stats);
  };

  const handleChangeTeam = async (e: any) => {
    const query: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id || "",
      team_id: e.target.value,
      stat_class: stat_class || "",
    };
    if (opp_id) {
      query.opp_id = opp_id;
    }
    setSearchParams(query);
    const data = await get_team_stats(query);
    const away_data = await get_team_stats({
      ...query,
      team_id: opp_id,
      opp_id: e.target.value,
    });
    setHomeData(data.team_stats);
    setOppData(away_data.team_stats);
  };

  const handleChangeOppTeam = async (e: any) => {
    const query: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id || "",
      team_id,
      opp_id: e.target.value,
      stat_class: stat_class || "",
    };
    setSearchParams(query);
    const away_data = await get_team_stats({
      ...query,
      team_id: e.target.value,
      opp_id: team_id,
    });
    const home_data = await get_team_stats({
      ...query,
      team_id,
      opp_id: e.target.value,
    });

    setOppData(away_data.team_stats);
    setHomeData(home_data.team_stats);
  };

  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      team_id,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: stat_class || "",
      opp_id: opp_id || "",
    };
    setMultiplaleParams(params);
  };

  const handleStatChange = (event: any) => {
    const params: any = {
      season_id: season_id || "",
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: event.target.value,
    };
    setMultiplaleParams(params);
  };

  const getValueById = (data: any, id: string) => {
    switch (id) {
      case "overall_record":
        return `${data?.["total_wins"] || 0}-${data?.["losses"] || 0}-${
          data?.["ot_losses"] || 0
        }-${data?.["so_losses"] || 0}`;
      case "home_record":
        return `${data?.["home_total_wins"] || 0}-${
          data?.["home_losses"] || 0
        }-${data?.["home_ot_losses"] || 0}-${data?.["home_so_losses"] || 0}`;
      case "away_record":
        return `${data?.["away_total_wins"] || 0}-${
          data?.["away_losses"] || 0
        }-${data?.["away_ot_losses"] || 0}-${data?.["away_so_losses"] || 0}`;
      case "win_pct":
        return (data?.["win_pct"] * 100 || 0).toFixed(2) + "%";
      case "pts_pct":
        return (data?.["pts_pct"] * 100 || 0).toFixed(2) + "%";
      case "power_play_pct":
        return (data?.["power_play_pct"] * 100 || 0).toFixed(2) + "%";
      case "home_power_play_pct":
        return (data?.["home_power_play_pct"] * 100 || 0).toFixed(2) + "%";
      case "away_power_play_pct":
        return (data?.["away_power_play_pct"] * 100 || 0).toFixed(2) + "%";
      case "penalty_kill_pct":
        return (data?.["penalty_kill_pct"] * 100 || 0).toFixed(2) + "%";
      case "home_penalty_kill_pct":
        return (data?.["home_penalty_kill_pct"] * 100 || 0).toFixed(2) + "%";
      case "away_penalty_kill_pct":
        return (data?.["away_penalty_kill_pct"] * 100 || 0).toFixed(2) + "%";
      case "last_5_overall":
        return `${data?.["last_5_wins"] || 0}-${data?.["last_5_losses"] || 0}-${
          data?.["last_5_ot_losses"] || 0
        }-${data?.["last_5_so_losses"] || 0}`;
      case "last_5_home":
        return `${data?.["home_last_5_wins"] || 0}-${
          data?.["home_last_5_losses"] || 0
        }-${data?.["home_last_5_ot_losses"] || 0}-${
          data?.["home_last_5_so_losses"] || 0
        }`;
      case "last_5_away":
        return `${data?.["away_last_5_wins"] || 0}-${
          data?.["away_last_5_losses"] || 0
        }-${data?.["away_last_5_ot_losses"] || 0}-${
          data?.["away_last_5_so_losses"] || 0
        }`;
      case "last_10_overall":
        return `${data?.["last_10_wins"] || 0}-${
          data?.["last_10_losses"] || 0
        }-${data?.["last_10_ot_losses"] || 0}-${
          data?.["last_10_so_losses"] || 0
        }`;
      case "last_10_home":
        return `${data?.["home_last_10_wins"] || 0}-${
          data?.["home_last_10_losses"] || 0
        }-${data?.["home_last_10_ot_losses"] || 0}-${
          data?.["home_last_10_so_losses"] || 0
        }`;
      case "last_10_away":
        return `${data?.["away_last_10_wins"] || 0}-${
          data?.["away_last_10_losses"] || 0
        }-${data?.["away_last_10_ot_losses"] || 0}-${
          data?.["away_last_10_so_losses"] || 0
        }`;
      case "shootout_overall":
        return `${data?.["so_wins"] || 0}-${data?.["so_losses"] || 0}`;
      case "shootout_home":
        return `${data?.["home_so_wins"] || 0}-${
          data?.["home_so_losses"] || 0
        }`;
      case "shootout_away":
        return `${data?.["away_so_wins"] || 0}-${
          data?.["away_so_losses"] || 0
        }`;
      case "head_to_head":
        return `${data?.["head_to_head_wins"] || 0}-${
          data?.["head_to_head_losses"] || 0
        }-${data?.["head_to_head_ot_losses"] || 0}-${
          data?.["head_to_head_so_losses"] || 0
        }`;
      case "points_leaders":
        return data?.["point_leaders"]?.[0] ? (
          <>
            <Link
              sx={{ "&:hover": { fontWeight: 600 } }}
              href={`${PageRoutes.PlayerProfile}?player_id=${
                data?.["point_leaders"]?.[0]?.["player_id"]
              }${stat_class ? `&stat_class=${stat_class}` : ""}&league_id=${
                window?.customconfiguration?.league_id || 4 || 4
              }`}
            >
              {data?.["point_leaders"]?.[0]?.["player_name"]}
            </Link>{" "}
            ({data?.["point_leaders"]?.[0]?.["points"]})
          </>
        ) : (
          "N/A"
        );
      case "goals_leaders":
        return data?.["goal_leaders"]?.[0] ? (
          <>
            <Link
              sx={{ "&:hover": { fontWeight: 600 } }}
              href={`${PageRoutes.PlayerProfile}?player_id=${
                data?.["goal_leaders"]?.[0]?.["player_id"]
              }${stat_class ? `&stat_class=${stat_class}` : ""}&league_id=${
                window?.customconfiguration?.league_id || 4 || 4
              }`}
            >
              {data?.["goal_leaders"]?.[0]?.["player_name"]}
            </Link>{" "}
            ({data?.["goal_leaders"]?.[0]?.["goals"]})
          </>
        ) : (
          "N/A"
        );
      case "assists_leaders":
        return data?.["assist_leaders"]?.[0] ? (
          <>
            <Link
              sx={{ "&:hover": { fontWeight: 600 } }}
              href={`${PageRoutes.PlayerProfile}?player_id=${
                data?.["assist_leaders"]?.[0]?.["player_id"]
              }${stat_class ? `&stat_class=${stat_class}` : ""}&league_id=${
                window?.customconfiguration?.league_id || 4 || 4
              }`}
            >
              {data?.["assist_leaders"]?.[0]?.["player_name"]}
            </Link>{" "}
            ({data?.["assist_leaders"]?.[0]?.["assists"]})
          </>
        ) : (
          "N/A"
        );
      case "gaa_leaders":
        return data?.["gaa_leaders"]?.[0] ? (
          <>
            <Link
              sx={{
                "&:hover": { fontWeight: 600 },
              }}
              href={`${PageRoutes.PlayerProfile}?player_id=${
                data?.["gaa_leaders"]?.[0]?.["player_id"]
              }${stat_class ? `&stat_class=${stat_class}` : ""}&league_id=${
                window?.customconfiguration?.league_id || 4 || 4
              }`}
            >
              {data?.["gaa_leaders"]?.[0]?.["player_name"]}
            </Link>{" "}
            ({data?.["gaa_leaders"]?.[0]?.["goals_against_ave"]})
          </>
        ) : (
          "N/A"
        );
      case "save_pct_leaders":
        return data?.["save_pct_leaders"]?.[0] ? (
          <>
            <Link
              sx={{ "&:hover": { fontWeight: 600 } }}
              href={`${PageRoutes.PlayerProfile}?player_id=${
                data?.["save_pct_leaders"]?.[0]?.["player_id"]
              }${stat_class ? `&stat_class=${stat_class}` : ""}&league_id=${
                window?.customconfiguration?.league_id || 4 || 4
              }`}
            >
              {data?.["save_pct_leaders"]?.[0]?.["player_name"]}
            </Link>{" "}
            ({data?.["save_pct_leaders"]?.[0]?.["save_pct"]})
          </>
        ) : (
          "N/A"
        );
      case "shutout_leaders":
        return data?.["shutout_leaders"]?.[0] ? (
          <>
            <Link
              sx={{ "&:hover": { fontWeight: 600 } }}
              href={`${PageRoutes.PlayerProfile}?player_id=${
                data?.["shutout_leaders"]?.[0]?.["player_id"]
              }${stat_class ? `&stat_class=${stat_class}` : ""}&league_id=${
                window?.customconfiguration?.league_id || 4 || 4
              }`}
            >
              {data?.["shutout_leaders"]?.[0]?.["player_name"]}
            </Link>{" "}
            ({data?.["shutout_leaders"]?.[0]?.["shutouts"]})
          </>
        ) : (
          "N/A"
        );
      case "pim_leaders":
        return data?.["pim_leaders"]?.[0] ? (
          <>
            <Link
              sx={{ "&:hover": { fontWeight: 600 } }}
              href={`${PageRoutes.PlayerProfile}?player_id=${
                data?.["pim_leaders"]?.[0]?.["player_id"]
              }${stat_class ? `&stat_class=${stat_class}` : ""}&league_id=${
                window?.customconfiguration?.league_id || 4 || 4
              }`}
            >
              {data?.["pim_leaders"]?.[0]?.["player_name"]}
            </Link>{" "}
            ({data?.["pim_leaders"]?.[0]?.["pims"]})
          </>
        ) : (
          "N/A"
        );
      default:
        return data?.[id];
    }
  };

  useEffect(() => {
    fetchSeason();
  }, []);

  useEffect(() => {
    fetchTeamData();
    fetchTeamStats();
  }, [season_id, stat_class]);

  return (
    <>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "100px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box sx={{ px: 1 }}>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 800, fontSize: 18, py: 3 }}
            >
              Team Comparison
            </Typography>
            <SelectDropdownLeague league={league} handleChange={handleChange} />
            <SelectDropdownStat stats={stats} handleChange={handleStatChange} />
          </Box>
          <TableContainer
            style={{
              maxWidth: "100%",
              overflowX: "auto",
            }}
          >
            <Table
              style={{
                border: "none",
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                  >
                    {" "}
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 3,
                      }}
                    >
                      {/* season dropdown */}

                      {/* team-1 dropdown */}
                      <SelectDropdownTeams
                        teams={teams}
                        handleChangeTeam={handleChangeTeam}
                        allTeamNeeded={false}
                        value={team_id}
                      />
                      {/* team-1 logo */}
                      <Box
                        component={Link}
                        href={`${PageRoutes.TeamDetail}?team_id=${team_id}&season_id=${season_id}&stat_class=${stat_class}`}
                      >
                        <Avatar
                          src={
                            homeData?.smlogo ||
                            "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                          }
                          alt={homeData?.team_name}
                          sx={{
                            width: { md: "100px", xs: "70px" },
                            height: { md: "100px", xs: "70px" },
                            ".MuiAvatar-img": {
                              objectFit: "contain !important",
                            },
                            borderRadius: "0px",
                          }}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell
                    style={{
                      borderBottom: "none",
                      textAlign: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: 3,
                      }}
                    >
                      {/* stat_class dropdown */}

                      {/* team-2 dropdown */}
                      <SelectDropdownTeams
                        teams={teams}
                        handleChangeTeam={handleChangeOppTeam}
                        allTeamNeeded={false}
                        value={opp_id || ""}
                        key="opp_id"
                      />
                      {/* team-2 logo */}
                      <Box
                        component={Link}
                        href={`${PageRoutes.TeamDetail}?team_id=${opp_id}&season_id=${season_id}&stat_class=${stat_class}`}
                      >
                        <Avatar
                          src={
                            oppData?.smlogo ||
                            "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                          }
                          sx={{
                            width: { md: "100px", xs: "70px" },
                            height: { md: "100px", xs: "70px" },
                            ".MuiAvatar-img": {
                              objectFit: "contain !important",
                            },
                            borderRadius: "0px",
                          }}
                          alt={oppData?.team_name}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                {headToHeadTeamDataColumn?.map((ele: any, index: number) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          textAlign: "center",
                          paddingBottom: 0,
                        }}
                      >
                        {ele?.title}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          textAlign: "center",
                          paddingBottom: 0,
                        }}
                      >
                        {getValueById(homeData, ele.id) || "N/A"}
                      </TableCell>
                      <TableCell
                        style={{
                          borderBottom: "none",
                          textAlign: "center",
                          paddingBottom: 0,
                        }}
                      >
                        {getValueById(oppData, ele.id) || "N/A"}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default HeadToHeadPage;
