import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CommonStyle from "../../Common/style/Style";
import { makeGetRequest } from "../../service";
import DailyScheduleTable from "./DailyScoreTable";

const DailySchedule = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const level_id = searchParams.get("level_id");
  const [value, setValue] = useState<any>(
    dayjs(moment(searchParams.get("date") || moment()).format("YYYY-MM-DD"))
  );

  const fetchData = async (value: any) => {
    if (!searchParams.get("date")) {
      searchParams.set("date", moment().format("YYYY-MM-DD"));
      setSearchParams(searchParams);
    }
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4,
      date: value.format("YYYY-MM-DD"),
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    setIsSkeleton(true);
    try {
      const response = await makeGetRequest(
        "get_schedule",
        new URLSearchParams(qurey).toString()
      );

      setSchedule(response?.games);
      response.games.map((el: any) => console.log(el.date));
      searchParams.set("date", value.format("YYYY-MM-DD"));
      searchParams.set(
        "league_id",
        window?.customconfiguration?.league_id || 4
      );
      setSearchParams(searchParams);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  useEffect(() => {
    fetchData(value);
  }, [value]);

  return (
    <Box>
      <Box sx={{ px: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 1 }}>
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            Daily Schedule and Scores
          </Typography>
        </Box>
        <Box
          className="wtd-statview-filter"
          sx={{
            ...CommonStyle.tableControl,
            display: "flex",
            gap: 1,
            px: 1,
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <DatePicker
            sx={{
              borderRadius: "4px",
              bgcolor: "white",

              ".MuiOutlinedInput-root": {
                flexDirection: "row-reverse",
              },
              ".MuiOutlinedInput-input": { py: "10px" },
            }}
            value={value}
            format={"dddd, MMM DD, YYYY"}
            onChange={(newValue) => {
              setValue(newValue);
            }}
          />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 3 }}>
              <Typography
                variant="h2"
                onClick={() => {
                  setValue(
                    dayjs(
                      moment(value.format("YYYY-MM-DD"))
                        .add(-1, "days")
                        .format("YYYY-MM-DD")
                    )
                  );
                }}
                sx={{
                  fontSize: { xs: "16px", md: "24px" },
                  ":hover": { textDecoration: "underline", cursor: "pointer" },
                }}
              >
                {"<"}{" "}
                {moment(value.format("YYYY-MM-DD"))
                  .add(-1, "days")
                  .format("dddd, MMM DD")}
              </Typography>
              <Typography
                variant="h2"
                onClick={() => {
                  setValue(
                    dayjs(
                      moment(value.format("YYYY-MM-DD"))
                        .add(1, "days")
                        .format("YYYY-MM-DD")
                    )
                  );
                }}
                sx={{
                  fontSize: { xs: "16px", md: "24px" },
                  ":hover": { textDecoration: "underline", cursor: "pointer" },
                }}
              >
                {value && (
                  <>
                    {moment(value.format("YYYY-MM-DD"))
                      .add(1, "days")
                      .format("dddd, MMM DD")}{" "}
                    {">"}
                  </>
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        {isSkeleton ? (
          <Box sx={{ position: "relative", height: "80vh" }}>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
              }}
            >
              <CircularProgress />
            </Box>
          </Box>
        ) : (
          <Box>
            {schedule?.length > 0 ? (
              <Box>
                {schedule
                  ?.sort((a: any, b: any) => (a.gmt_time < b.gmt_time ? -1 : 1))
                  ?.map((el: any) => (
                    <DailyScheduleTable el={el} />
                  ))}
              </Box>
            ) : (
              <Box>
                <Typography>There are no games on this date</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default DailySchedule;
