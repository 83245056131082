import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/system";
import theme from "./Common/style/core/config/theme.js";
import GameCenter from "./Common/Component/GameCenter";
import StandingTable from "./Common/Component/StandingTable";
import TableComponent from "./Pages/TableComponent";
import PlayerStat from "./Pages/Stats/PlayerStat";
import StandingStat from "./Pages/Stats/StandingStat";
import GameCenterBoard from "./Pages/GameCenter";
import PlayerProfile from "./Pages/PlayerProfile";
import Schedule from "./Pages/Schedule";
import DailySchedule from "./Pages/DailySchedule";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AttendaceMain from "./Pages/Attendance/attendance";
import TeamDetailPage from "./Pages/TeamDetail";
import DailyReportPage from "./Pages/DailyReportPage";
import HeadToHeadPage from "./Pages/HeadToHeadPage";
const container = document.getElementById("root-react") as HTMLElement;
const container2: any = document.getElementById("root-game-center");
const container3: any = document.getElementById("root-standing");
const roster: any = document.getElementById("root-roster");
const goalieStats: any = document.getElementById("root-goalie-stats");
const skaterStats: any = document.getElementById("root-skater-stats");
const teamStanding: any = document.getElementById("root-team-standings");
const teamstats: any = document.getElementById("root-team-stats");
const gameCenter: any = document.getElementById("root-game-center-board");
const playerProfile: any = document.getElementById("root-player-profile");
const schedule: any = document.getElementById("root-schedule");
const dailyCchedule: any = document.getElementById("root-daily-schedule");
const attendance: any = document.getElementById("root-attendance");
const teamdetails: any = document.getElementById("root-team-details");
const dailyreports: any = document.getElementById("root-league-today");
const headeToHeadTeam: any = document.getElementById("root-head-to-head-team");
if (container) {
  const root = ReactDOM.createRoot(container); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (headeToHeadTeam) {
  const root = ReactDOM.createRoot(headeToHeadTeam); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <HeadToHeadPage />
      </BrowserRouter>
    </ThemeProvider>
  );
}

if (container2) {
  const root = ReactDOM.createRoot(container2); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GameCenter />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (container3) {
  const root = ReactDOM.createRoot(container3); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <StandingTable />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (teamdetails) {
  const root = ReactDOM.createRoot(teamdetails); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <TeamDetailPage />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (goalieStats) {
  const root = ReactDOM.createRoot(goalieStats); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PlayerStat isGoalie={true} />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (skaterStats) {
  const root = ReactDOM.createRoot(skaterStats); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PlayerStat isGoalie={false} />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (attendance) {
  const root = ReactDOM.createRoot(attendance); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AttendaceMain />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (dailyreports) {
  const root = ReactDOM.createRoot(dailyreports); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <DailyReportPage />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (gameCenter) {
  const root = ReactDOM.createRoot(gameCenter); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <GameCenterBoard />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (teamStanding) {
  const root = ReactDOM.createRoot(teamStanding); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <StandingStat isTeam={false} />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (schedule) {
  const root = ReactDOM.createRoot(schedule); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Schedule />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (teamstats) {
  const root = ReactDOM.createRoot(teamstats); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <StandingStat isTeam={true} />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (playerProfile) {
  const root = ReactDOM.createRoot(playerProfile); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <PlayerProfile />
      </BrowserRouter>
    </ThemeProvider>
  );
}
if (dailyCchedule) {
  const root = ReactDOM.createRoot(dailyCchedule); // createRoot(container!) if you use TypeScript

  root.render(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <DailySchedule />
        </BrowserRouter>
      </ThemeProvider>
    </LocalizationProvider>
  );
}

if (roster) {
  const root = ReactDOM.createRoot(roster); // createRoot(container!) if you use TypeScript

  root.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <TableComponent />
      </BrowserRouter>
    </ThemeProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
