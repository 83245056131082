import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { makeGetRequest } from "../../service";
import { useSearchParams } from "react-router-dom";
import RostersPage from "./Tabs/Rosters";
import SchedulesPage from "./Tabs/Schedules";
import TeamLeadersTab from "./Tabs/TeamLeaders";
import TeamStatsPage from "./Tabs/TeamStats";
import SpecialTeams from "./Tabs/SpecialTeams";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import SelectDropdownTeams from "../../Common/SelectDropdownTeam";
import SelectDropdownStat from "../../Common/SelectDropdownStat";

const tabsData = [
  { value: 1, label: "Team Leaders" },
  { value: 2, label: "Team Stats" },
  { value: 3, label: "Special Teams" },
  { value: 4, label: "Schedule" },
  { value: 5, label: "Roster" },
];

const TeamDetailPage = () => {
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [teamData, setTeamData] = useState({} as any);
  const [league, setLeague] = useState([]);
  const [stats, setStats] = useState<any>([]);
  const [teams, setTeams] = useState([]);

  const [selectedTab, setSelectedTab] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const team_id = searchParams.get("team_id");
  const level_id = searchParams.get("level_id");

  const get_team_data = async () => {
    setIsSkeleton(true);
    try {
      const resTeamData = await makeGetRequest(
        "get_team_info",
        `team_id=${team_id}`
      );
      setTeamData(resTeamData);
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const fetchSeason = async () => {
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );

      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);
      let filterdSeasonIdForTeam;
      if (
        searchParams.get("team_id") != "all-teams" &&
        searchParams.get("team_id")
      ) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        team_id,
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!searchParams.get("season_id")) {
        setSearchParams(qurey);
      }
    } catch (error) {}
  };

  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };

  const fetchTeamData = async () => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: searchParams.get("season_id"),
      stat_class: searchParams.get("stat_class"),
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    try {
      const respone = await makeGetRequest(
        "get_teams",
        new URLSearchParams(qurey).toString()
      );
      const findCurrntHomeTeam = respone.teams?.filter(
        (el: any) => el.team_id == team_id
      );

      let filterTeamId: any;
      if (findCurrntHomeTeam.length >= 1) {
        filterTeamId = findCurrntHomeTeam[0]?.team_id;
      } else {
        filterTeamId = respone.teams[0].team_id;
      }
      qurey = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: searchParams.get("season_id") || "",
        team_id: filterTeamId,
        stat_class: searchParams.get("stat_class") || "",
      };

      const filterData = respone.teams?.filter(
        (el: any) => el.team_id == filterTeamId
      );

      setSearchParams(qurey);
      setTeams(respone.teams);
    } catch (error) {}
  };

  const handleChangeTeam = async (e: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: searchParams.get("season_id") || "",
      team_id: e.target.value,
      stat_class: searchParams.get("stat_class") || "",
    };
    setSearchParams(qurey);
  };

  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      team_id,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: searchParams.get("stat_class") || "",
    };
    setMultiplaleParams(params);
  };

  const handleStatChange = (event: any) => {
    const params: any = {
      season_id: searchParams.get("season_id") || "",
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: event.target.value,
    };
    setMultiplaleParams(params);
  };

  useEffect(() => {
    fetchSeason();
  }, []);

  useEffect(() => {
    fetchTeamData();
  }, [searchParams.get("season_id"), searchParams.get("stat_class")]);

  useEffect(() => {
    get_team_data();
  }, [team_id]);

  return (
    <>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "100px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box sx={{ px: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: { md: 1, xs: 0 },
              py: 1,
              px: { md: 2, xs: 1 },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { md: 2, xs: 0 },
              }}
            >
              <Box sx={{ width: { md: "100px", xs: "70px" } }}>
                <img src={teamData?.team_logo} width="100%" />
              </Box>
              <Box>
                <Typography
                  variant="subtitle2"
                  sx={{ fontWeight: 800, fontSize: 18 }}
                >
                  {teamData?.team_name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: "flex", gap: 5 }}>
              <SelectDropdownLeague
                league={league}
                handleChange={handleChange}
              />
              <SelectDropdownStat
                stats={stats}
                handleChange={handleStatChange}
              />
              <SelectDropdownTeams
                teams={teams}
                handleChangeTeam={handleChangeTeam}
                allTeamNeeded={false}
              />
            </Box>
          </Box>
          <Tabs
            sx={{
              px: 2,
              ".MuiTabs-scrollButtons.Mui-disabled": {
                opacity: 0.3,
              },
            }}
            value={selectedTab}
            onChange={(event, value) => setSelectedTab(value)}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="scrollable auto tabs example"
          >
            {tabsData?.map((el) => {
              return <Tab value={el?.value} label={el?.label} />;
            })}
          </Tabs>
          <Box sx={{ p: 2 }}>
            {selectedTab === 1 && <TeamLeadersTab />}
            {selectedTab === 2 && (
              <TeamStatsPage teamLogo={teamData?.team_logo} />
            )}
            {selectedTab === 3 && <SpecialTeams />}
            {selectedTab === 4 && <SchedulesPage />}
            {selectedTab === 5 && <RostersPage />}
          </Box>
        </Box>
      )}
    </>
  );
};

export default TeamDetailPage;
