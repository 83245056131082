import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import StreakTable from "./StreakTable";
import CommonStyle from "../../Common/style/Style";
import { useSearchParams } from "react-router-dom";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import { makeGetRequest } from "../../service";
import SelectDropdownStat from "../../Common/SelectDropdownStat";

const Streaks = () => {
  const [isTeam, setTeam] = useState(true);
  const [isGoal, setGoal] = useState(true);
  const [isAssists, setAssists] = useState(false);
  const [isPoints, setPoints] = useState(false);

  const [isDivision, setDivision] = useState(true);
  const [isConference, setConforence] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [league, setLeague] = useState([]);
  const [stats, setStats] = useState([]);
  const [currentLeague, setCurrentLeague] = useState<any>({});
  const [currentTeam, setCurrentTeam] = useState<any>({});
  const [teams, setTeams] = useState([]);

  const level_id = searchParams.get("level_id");
  const fetachSeason = async () => {
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );
      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);
      let filterdSeasonIdForTeam;
      if (searchParams.get("team_id")) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        const findSeason = response.leagues[0].seasons.filter(
          (el: any) => el.season_id == res?.levels[0]?.season_id
        );
        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!searchParams.get("season_id")) {
        setSearchParams(qurey);
      }

      const filterData = response.leagues[0].seasons?.filter(
        (el: any) => el.season_id == filterSeasonId
      );
      //   await fetchTeamData(filterSeasonId, searchParams.get("team_id"));
      setCurrentLeague(filterData[0]);
    } catch (error) {}
  };
  //   const fetchTeamData = async (season_id: any, team_id: any) => {
  //     let qurey: any = {
  //       league_id: window?.customconfiguration?.league_id || 4 || 4,
  //       season_id: season_id,
  //     };

  //     try {
  //       const respone = await makeGetRequest(
  //         "get_teams",
  //         new URLSearchParams(qurey).toString()
  //       );
  //       const findCurrntHomeTeam = respone.teams?.filter(
  //         (el: any) => el.team_id == team_id
  //       );

  //       let filterTeamId: any;
  //       if (findCurrntHomeTeam.length >= 1) {
  //         filterTeamId = findCurrntHomeTeam[0]?.team_id;
  //       } else {
  //         filterTeamId = respone.teams[0].team_id;
  //       }
  //       qurey = {
  //         league_id: window?.customconfiguration?.league_id || 4 || 4,
  //         season_id: season_id,
  //         team_id: filterTeamId,
  //       };

  //       const filterData = respone.teams?.filter(
  //         (el: any) => el.team_id == filterTeamId
  //       );

  //       setCurrentTeam(filterData[0]);
  //       setSearchParams(qurey);

  //       setTeams(respone.teams);

  //     } catch (error) {}
  //   };

  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
    };
    const filterData = league?.filter(
      (el: any) => el.season_id == event.target.value
    );

    setCurrentLeague(filterData[0]);
    setMultiplaleParams(params);
  };

  const handleStatChange = (event: any) => {
    const params: any = {
      season_id: searchParams.get("season_id"),
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: event.target.value,
    };
    setMultiplaleParams(params);
  };

  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    fetachSeason();
  }, []);

  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 800, fontSize: 18 }}
        ml={2}
      >
        Streaks
      </Typography>
      <Box
        className="wtd-statview-filter"
        sx={{
          ...CommonStyle.tableControl,
          display: "flex",
          gap: 1,
          px: 1,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <ButtonGroup
          sx={{
            boxShadow: "none",
            borderColor: "transparent",
            border: "1px solid transparent",
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "transparent",
            },
          }}
          variant="contained"
        >
          <Button
            className={`wtd-statview-button-color ${isTeam ? "active" : ""}`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: isTeam ? "#ffb931" : "#000000",
              fontWeight: isTeam ? 600 : 200,
            }}
            onClick={() => {
              searchParams.set("streaktype", "team");
              setSearchParams(searchParams);
              setTeam(false);
              setTeam(true);
            }}
          >
            Team
          </Button>
          <Button
            className={`wtd-statview-button-color ${!isTeam ? "active" : ""}`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: !isTeam ? "#ffb931" : "#000000",
              fontWeight: !isTeam ? 600 : 200,
              ml: 0.5,
            }}
            onClick={() => {
              searchParams.set("streaktype", "player");
              setSearchParams(searchParams);
              setTeam(false);
            }}
          >
            Player
          </Button>
          <Box ml={3}>
            {!isTeam ? (
              <>
                <Button
                  className={`wtd-statview-button-color ${
                    isGoal ? "active" : ""
                  }`}
                  sx={{
                    ...CommonStyle.btnSwitch,
                    backgroundColor: isGoal ? "#ffb931" : "#000000",
                    fontWeight: isGoal ? 600 : 200,
                    mr: 0.5,
                  }}
                  onClick={() => {
                    searchParams.set("type", "goals");
                    setSearchParams(searchParams);
                    setPoints(false);
                    setAssists(false);
                    setGoal(true);
                  }}
                >
                  Goals
                </Button>
                <Button
                  className={`wtd-statview-button-color ${
                    isAssists ? "active" : ""
                  }`}
                  sx={{
                    ...CommonStyle.btnSwitch,
                    backgroundColor: isAssists ? "#ffb931" : "#000000",
                    fontWeight: isAssists ? 600 : 200,
                    mr: 0.5,
                  }}
                  onClick={() => {
                    searchParams.set("type", "assists");
                    setSearchParams(searchParams);
                    setGoal(false);
                    setPoints(false);
                    setAssists(true);
                  }}
                >
                  Assists
                </Button>
                <Button
                  className={`wtd-statview-button-color ${
                    isPoints ? "active" : ""
                  }`}
                  sx={{
                    ...CommonStyle.btnSwitch,
                    backgroundColor: isPoints ? "#ffb931" : "#000000",
                    fontWeight: isPoints ? 600 : 200,
                    mr: 0.5,
                  }}
                  onClick={() => {
                    searchParams.set("type", "points");
                    setSearchParams(searchParams);
                    setGoal(false);
                    setAssists(false);
                    setPoints(true);
                  }}
                >
                  Points
                </Button>
              </>
            ) : (
              <>
                <Button
                  className={`wtd-statview-button-color ${
                    isDivision ? "active" : ""
                  }`}
                  sx={{
                    ...CommonStyle.btnSwitch,
                    backgroundColor: isDivision ? "#ffb931" : "#000000",
                    fontWeight: isDivision ? 600 : 200,
                    mr: 0.5,
                  }}
                  onClick={() => {
                    searchParams.set("type", "division");
                    setSearchParams(searchParams);
                    setConforence(false);
                    setDivision(true);
                  }}
                >
                  Division
                </Button>
                <Button
                  className={`wtd-statview-button-color ${
                    isConference ? "active" : ""
                  }`}
                  sx={{
                    ...CommonStyle.btnSwitch,
                    backgroundColor: isConference ? "#ffb931" : "#000000",
                    fontWeight: isConference ? 600 : 200,
                    mr: 0.5,
                  }}
                  onClick={() => {
                    searchParams.set("type", "conference");
                    setSearchParams(searchParams);
                    setDivision(false);
                    setConforence(true);
                  }}
                >
                  Conference
                </Button>
              </>
            )}
          </Box>
        </ButtonGroup>
        <SelectDropdownLeague league={league} handleChange={handleChange} />
        <SelectDropdownStat stats={stats} handleChange={handleStatChange} />
      </Box>

      <StreakTable />
    </Box>
  );
};

export default Streaks;
