import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { makeGetRequest } from "../../service";
import CommonStyle from "../../Common/style/Style";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import SelectDropdownTeams from "../../Common/SelectDropdownTeam";
import TransactionsTable from "./Table";
import SelectDropdownStat from "../../Common/SelectDropdownStat";

const Transactions = () => {
  const [league, setLeague] = useState([]);
  const [teams, setTeams] = useState([]);
  const [stats, setStats] = useState([]);
  const [currentLeague, setCurrentLeague] = useState<any>({});
  const [currentTeam, setCurrentTeam] = useState<any>({});
  const [searchParams, setSearchParams] = useSearchParams();

  const fetchTeamData = async (season_id: any, team_id: any, stat_id: any) => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id,
      stat_class: stat_id,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    try {
      const respone = await makeGetRequest(
        "get_teams",
        new URLSearchParams(qurey).toString()
      );
      const findCurrntHomeTeam = respone.teams?.filter(
        (el: any) => el.team_id == team_id
      );

      let filterTeamId: any;
      if (findCurrntHomeTeam.length >= 1) {
        filterTeamId = findCurrntHomeTeam[0]?.team_id;
      } else {
        filterTeamId = respone.teams[0].team_id;
      }
      qurey = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: season_id,
        team_id: filterTeamId,
        stat_class: stat_id,
      };

      const filterData = respone.teams?.filter(
        (el: any) => el.team_id == filterTeamId
      );

      setCurrentTeam(filterData[0]);
      setSearchParams(qurey);

      setTeams(respone.teams);
    } catch (error) {}
  };
  const level_id = searchParams.get("level_id");
  const fetachSeason = async () => {
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );
      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);
      let filterdSeasonIdForTeam;
      if (
        searchParams.get("team_id") != "all-teams" &&
        searchParams.get("team_id")
      ) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        const findSeason = response.leagues[0].seasons.filter(
          (el: any) => el.season_id == res?.levels[0]?.season_id
        );
        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!searchParams.get("season_id")) {
        setSearchParams(qurey);
      }

      const filterData = response.leagues[0].seasons?.filter(
        (el: any) => el.season_id == filterSeasonId
      );

      setCurrentLeague(filterData[0]);
      await fetchTeamData(
        filterSeasonId,
        searchParams.get("team_id"),
        searchParams.get("stat_class") || defaultStat[0].stat_class_id
      );
    } catch (error) {}
  };
  useEffect(() => {
    fetachSeason();
  }, []);

  const handleChangeTeam = async (e: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: searchParams.get("season_id"),
      team_id: e.target.value,
    };

    setSearchParams(qurey);
    const filterData = teams?.filter((el: any) => el.team_id == e.target.value);
    setCurrentTeam(filterData[0]);
  };

  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
    };
    const filterData = league?.filter(
      (el: any) => el.season_id == event.target.value
    );

    setCurrentLeague(filterData[0]);
    fetchTeamData(
      event.target.value,
      searchParams.get("team_id") || "",
      searchParams.get("stat_class") || ""
    );
    setMultiplaleParams(params);
  };

  const handleStatChange = (event: any) => {
    const params: any = {
      season_id: searchParams.get("season_id"),
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: event.target.value,
    };
    fetchTeamData(
      searchParams.get("season_id"),
      searchParams.get("team_id"),
      event.target.value
    );
    setMultiplaleParams(params);
  };

  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };
  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{ fontWeight: 800, fontSize: 18 }}
        ml={2}
      >
        Transactions
      </Typography>
      <Box
        className="wtd-statview-filter"
        sx={{
          ...CommonStyle.tableControl,
          display: "flex",
          gap: 1,
          px: 1,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <SelectDropdownLeague league={league} handleChange={handleChange} />
        <SelectDropdownTeams
          teams={teams}
          handleChangeTeam={handleChangeTeam}
        />
        <SelectDropdownStat stats={stats} handleChange={handleStatChange} />
      </Box>
      <TransactionsTable />
    </Box>
  );
};

export default Transactions;
