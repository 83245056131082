import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Avatar,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CommonStyle from "../../Common/style/Style";
import styled from "@emotion/styled";
import { useLocation, useSearchParams } from "react-router-dom";
import "./index.css";
import { PageRoutes } from "../../utils/constant";
import handleSort from "../../hooks/useSort";
import GoalieStatRow from "./GoalieStatRow";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface Column {
  id:
    | "rank"
    | "jersey"
    | "rookie"
    | "active"
    | "player_name"
    | "team_ab"
    | "games_played"
    | "toi"
    | "goals_against_ave"
    | "wins"
    | "losses"
    | "ot_losses"
    | "so_losses"
    | "save_pct"
    | "sog"
    | "shots_against"
    | "goals_against"
    | "shutouts";
  label: string;
  align?: "center" | "left" | "right";
  sort?: "asc" | "desc";
}

const columns: readonly Column[] = [
  { id: "rank", label: "" },
  { id: "rookie", label: "" },
  { id: "jersey", label: "#", align: "center", sort: "asc" },

  { id: "player_name", label: "Name", sort: "asc" },
  { id: "team_ab", label: "Team", align: "center", sort: "asc" },
  { id: "games_played", label: "GP", align: "center", sort: "desc" },
  {
    id: "toi",
    label: "MIN",
    align: "center",
    sort: "desc",
  },
  { id: "goals_against_ave", label: "GAA", align: "center", sort: "asc" },
  {
    id: "wins",
    label: "W",
    align: "center",
    sort: "desc",
  },
  {
    id: "losses",
    label: "L",
    align: "center",
    sort: "asc",
  },
  {
    id: "ot_losses",
    label: "OTL",
    align: "center",
    sort: "asc",
  },
  {
    id: "so_losses",
    label: "SOL",
    align: "center",
    sort: "asc",
  },
  { id: "save_pct", label: "SV%", align: "center", sort: "desc" },
  {
    id: "sog",
    label: "SH",
    align: "center",
    sort: "desc",
  },
  {
    id: "shots_against",
    label: "SA",
    align: "center",
    sort: "desc",
  },
  { id: "goals_against", label: "GA", align: "center", sort: "asc" },
  { id: "shutouts", label: "SO", align: "center", sort: "desc" },
];

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    backgroundColor: "#cecece",
  },
}));

const GoalieStat = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();
  const isGoalies =
    searchParams.get("player_type") == "goalie" ||
    (!searchParams.get("player_type") && props?.isGoalie) ||
    (!searchParams.get("player_type") &&
      location.pathname.includes("/goalie-stats"))
      ? true
      : false;

  const sortTable = (key: string, flag: any, sort: string) => {
    searchParams.set("sort", key);
    if (flag == "filter") {
      if (key) {
        {
          columns.forEach((column) => {
            if (column.id == key) {
            } else {
            }
          });
        }

        searchParams.set("direction", sort);
        setSearchParams(searchParams);

        props.setSortConfig({ key, direction: sort });
      } else {
        searchParams.delete("sort");
        searchParams.delete("direction");
        props.fetachSeason();
      }
    } else {
      {
        columns.forEach((column) => {
          if (column.id == key) {
          } else {
          }
        });
      }

      const currentSortDirection = searchParams.get("direction") || "asc";
      if (props.sortConfig.key === key) {
        props.setSortConfig({
          key,
          direction: currentSortDirection === "asc" ? "desc" : "asc",
        });
        searchParams.set(
          "direction",
          currentSortDirection === "asc" ? "desc" : "asc"
        );
      } else {
        searchParams.set("direction", sort);
        props.setSortConfig({ key, direction: sort });
      }
      setSearchParams(searchParams);
    }
  };

  const sumOfData = (key: string): number => {
    return props.playersData
      ?.filter((el: any) => {
        if (isGoalies) {
          return el?.plays === "G";
        } else {
          return true;
        }
      })
      ?.filter((el: any) => {
        if (searchParams.get("rookie")) {
          return el?.display_flags === "R";
        } else {
          return true;
        }
      })
      .reduce((sum: number, item: any) => sum + Number(item[key]), 0);
  };
  const sumOfDataMinutes = (key: string): any => {
    let totalHours = 0;
    let totalMinutes = 0;

    // Iterate through the array of time durations
    for (let i = 0; i < props.playersData.length; i++) {
      const [hours, minutes] = props.playersData[i].toi.split(":").map(Number);
      totalHours += hours;
      totalMinutes += minutes;
    }

    // If the total minutes exceed 60, adjust the hours and minutes
    if (totalMinutes >= 60) {
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
    }

    // Format the result as a time duration string
    const result = `${totalHours}:${totalMinutes.toString().padStart(2, "0")}`;

    return result;
  };
  const sumOfDataMinutesForSum = (key: string): any => {
    let totalHours = 0;
    let totalMinutes = 0;

    // Iterate through the array of time durations
    for (let i = 0; i < props.playersData.length; i++) {
      const [hours, minutes] = props.playersData[i].toi.split(":").map(Number);
      totalHours += hours;
      totalMinutes += minutes;
    }

    // If the total minutes exceed 60, adjust the hours and minutes
    if (totalMinutes >= 60) {
      totalHours += Math.floor(totalMinutes / 60);
      totalMinutes %= 60;
    }

    // Format the result as a time duration string
    const result = `${totalHours}.${totalMinutes.toString().padStart(2, "0")}`;

    return result;
  };

  useEffect(() => {
    props.setSortConfig({ key: "goals_against_ave", direction: "asc" });
  }, []);

  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
        <TableContainer sx={{ maxHeight: "auto" }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    id={column.id}
                    key={column.id}
                    align={column.align}
                    sx={{
                      ...CommonStyle.tableHeader,
                      padding: "0px 5px 0px 5px",
                      backgroundColor:
                        props.sortConfig.key === column.id
                          ? "#cecece"
                          : "#ffb931",
                    }}
                    className="wtd-statview-header-color"
                  >
                    {["rank", "rookie", "active"].includes(column.id) ? (
                      column.label
                    ) : (
                      <a
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: column.align,
                        }}
                        onClick={() =>
                          sortTable(
                            column.id,
                            "table",
                            column.sort ? column.sort : "desc"
                          )
                        }
                      >
                        {column.label}
                        {props.sortConfig.key === column.id && (
                          <span style={{ display: "flex" }}>
                            {props.sortConfig.direction === "asc" ? (
                              <ArrowDropUpIcon />
                            ) : (
                              <ArrowDropDownIcon />
                            )}
                          </span>
                        )}
                      </a>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.playersData
                ?.sort((a: any, b: any) =>
                  handleSort(a, b, {
                    ...props.sortConfig,
                    key:
                      props.sortConfig.key == "player_name"
                        ? "lname"
                        : props.sortConfig.key,
                  })
                )
                ?.filter((el: any) => {
                  if (searchParams.get("rookie")) {
                    return el?.display_flags == "R";
                  } else {
                    return el;
                  }
                })
                .map((goalieItem: any, index: number) => {
                  return (
                    <GoalieStatRow
                      teamLogo={props?.teamLogo}
                      goalieItem={goalieItem}
                      index={index}
                      sortKey={props.sortConfig.key}
                    />
                  );
                })}
              <StyledTableRow hover role="checkbox" tabIndex={-1}>
                <TableCell sx={{ py: 1 }} align={"center"}></TableCell>
                <TableCell sx={{ py: 1 }} align={"center"}></TableCell>
                <TableCell sx={{ py: 1 }} align={"center"}></TableCell>
                <TableCell id={"player_name"} sx={{ py: 1 }} align={"left"}>
                  Totals
                </TableCell>
                <TableCell sx={{ py: 1 }} align={"center"}></TableCell>
                <TableCell id={"games_played"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("games_played"))
                    ? sumOfData("games_played")
                    : 0}
                </TableCell>
                <TableCell id={"toi"} sx={{ py: 1 }} align={"center"}>
                  {sumOfDataMinutes("toi")}
                </TableCell>
                <TableCell
                  id={"goals_against_ave"}
                  sx={{ py: 1 }}
                  align={"center"}
                >
                  {!isNaN(
                    (sumOfData("goals_against") * 60) /
                      sumOfDataMinutesForSum("toi")
                  )
                    ? (
                        (sumOfData("goals_against") * 60) /
                        sumOfDataMinutesForSum("toi")
                      ).toFixed(2)
                    : 0}
                </TableCell>
                <TableCell id={"wins"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("wins")) ? sumOfData("wins") : 0}
                </TableCell>
                <TableCell id={"losses"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("losses")) ? sumOfData("losses") : 0}
                </TableCell>
                <TableCell id={"ot_losses"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("ot_losses")) ? sumOfData("ot_losses") : 0}
                </TableCell>
                <TableCell id={"so_losses"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("so_losses")) ? sumOfData("so_losses") : 0}
                </TableCell>
                <TableCell id={"save_pct"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(
                    (sumOfData("shots_against") - sumOfData("goals_against")) /
                      sumOfData("shots_against")
                  )
                    ? (
                        (sumOfData("shots_against") -
                          sumOfData("goals_against")) /
                        sumOfData("shots_against")
                      ).toFixed(4)
                    : 0}
                </TableCell>
                <TableCell id={"sog"} sx={{ py: 1 }} align={"center"}>
                  {sumOfData("sog")}
                </TableCell>
                <TableCell id={"shots_against"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("shots_against"))
                    ? sumOfData("shots_against")
                    : 0}
                </TableCell>
                <TableCell id={"goals_against"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("goals_against"))
                    ? sumOfData("goals_against")
                    : 0}
                </TableCell>
                <TableCell id={"shutouts"} sx={{ py: 1 }} align={"center"}>
                  {!isNaN(sumOfData("shutouts")) ? sumOfData("shutouts") : 0}
                </TableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};

export default GoalieStat;
