import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";

import CommonStyle from "../../../Common/style/Style";
import PlayByPlayTableAccordian from "./PlayByPlayTableAccordian";

const PlayByPlayTable = ({ el, index, switches }: any) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                className={"wtd-statview-header-color"}
                align={"left"}
                sx={{
                  ...CommonStyle.tableHeader,
                }}
              >
                {el.key == 1 ? "1st Period" : el.key == 2 ? "2nd Period" : el.key == 3 ? "3rd Period" : el.key}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {el?.array?.length > 0 ? (
              el?.array
                ?.filter((el: any) => {
                  if (!switches?.goals) {
                    return el.type.toUpperCase() != "GOAL";
                  }
                  return el;
                })
                ?.filter((el: any) => {
                  if (!switches?.panelties) {
                    return el.type?.toUpperCase() != "PENALTY";
                  }
                  return el;
                })
                ?.filter((el: any) => {
                  if (!switches?.Shots) {
                    return el.type?.toUpperCase() != "SHOT";
                  }
                  return el;
                })
                ?.filter((el: any) => {
                  if (!switches?.Save) {
                    return el.type?.toUpperCase() != "SAVE";
                  }
                  return el;
                })
                .map((element: any, index: any) => {
                  return element?.type?.toUpperCase() == "SAVE" && !element?.shooter_player_name ? <></> : <PlayByPlayTableAccordian switches={switches} element={element} index={index} />;
                })
            ) : (
              <Typography sx={{ textAlign: "center", py: 1 }}>No Scoring</Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default PlayByPlayTable;
