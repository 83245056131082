import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import CardDetail from "../Card";
import { makeGetRequest } from "../../../service";
import { useSearchParams } from "react-router-dom";
import handleSort from "../../../hooks/useSort";

const TeamLeadersTab = () => {
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const team_id = searchParams.get("team_id");
  const season_id = searchParams.get("season_id");
  const [filteredData, setFilteredData] = useState({
    goals: [],
    points: [],
    assists: [],
    gwg: [],
    wins: [],
    "sv%": [],
    shutouts: [],
    gaa: [],
  });
  const fetchData = async (
    type: string,
    keys: string[],

    length: number = 3
  ) => {
    const endpoint = type === "skaters" ? "get_skaters" : "get_goalies";
    let currentSeason;
    if (season_id == null) {
      const resTeamData = await makeGetRequest(
        "get_team_info",
        `team_id=${team_id}`
      );
      currentSeason = resTeamData?.leagues[0]?.current_season;
    }
    const query: any = {
      team_id,
      league_id: window.customconfiguration?.league_id || 4,
      season_id: season_id !== null ? season_id : currentSeason,
    };
    if (searchParams.get("stat_class")) {
      query.stat_class = searchParams.get("stat_class");
    }
    const response = await makeGetRequest(
      endpoint,
      new URLSearchParams(query).toString()
    );

    return keys.map((key) =>
      response[type]
        .sort((teamA: any, teamB: any) =>
          handleSort(teamB, teamA, { key, direction: "asc" })
        )
        .slice(0, length)
    );
  };

  useEffect(() => {
    (async () => {
      setIsSkeleton(true);

      const skatersKeys = ["goals", "points", "assists", "gwg"];
      const goaliesKeys = ["wins", "save_pct", "shutouts", "gaa"];

      const [res_1, res_2, res_3, res_4] = await fetchData(
        "skaters",
        skatersKeys
      );
      const [res_5, res_6, res_7, res_8] = await fetchData(
        "goalies",
        goaliesKeys,
        3
      );
      setFilteredData({
        goals: res_1,
        points: res_2,
        assists: res_3,
        gwg: res_4,
        wins: res_5,
        "sv%": res_6,
        shutouts: res_7,
        gaa: res_8,
      });

      setIsSkeleton(false);
    })();
  }, [season_id, searchParams.get("stat_class")]);

  return (
    <>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "100px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              pt: 3,
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Grid container spacing={{ md: 5, xs: 3 }}>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Goals" data={filteredData.goals} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Points" data={filteredData.points} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Assists" data={filteredData.assists} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Game Winning Goals" data={filteredData.gwg} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Wins" data={filteredData.wins} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Save Percentage" data={filteredData["sv%"]} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Shutouts" data={filteredData.shutouts} />
          </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12}>
            <CardDetail title="Goals Against Average" data={filteredData.gaa} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TeamLeadersTab;
