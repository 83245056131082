import React, { useEffect, useRef, useState } from "react";

import { makeGetRequest } from "../../service";
import CommonStyle from "../../Common/style/Style";

import { MonthsOption, PlayerPosition } from "../../utils/constant";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import PlayerTable from "./ScheduleTable";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { useParams, useSearchParams } from "react-router-dom";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import SelectDropdownTeams from "../../Common/SelectDropdownTeam";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TableDetails from "../../Common/Component/TableDetails";
import ScheduleTable from "./ScheduleTable";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { CloseFullscreen } from "@mui/icons-material";
import SelectDropdownStat from "../../Common/SelectDropdownStat";

const ics = require("ics");
export default function Schedule({
  isTeamVisible = true,
  isSeasonsVisible = true,
  isStatsVisible = true,
}: any) {
  const userTimeZone = momentTimezone.tz.guess();

  const [playersData, setPlayersData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [league, setLeague] = useState([]);
  const [teams, setTeams] = useState([]);
  const [stats, setStats] = useState([]);
  const [selectedValue, setSelectedValue] = useState(
    searchParams.get("month") || moment().format("MM")
  );

  const [currentLeague, setCurrentLeague] = useState<any>({});
  const [currentTeam, setCurrentTeam] = useState<any>({});
  const { id } = useParams();
  const team_id = searchParams.get("team_id");
  const standingMode = searchParams.get("mode") || "home&away";
  const level_id = searchParams.get("level_id");
  const filteredData = (data: any, plays: any) =>
    data.filter((resItem: any) => plays.includes(resItem.plays));
  const fetchData = async (season_id: any, team_id: any, stat_id: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4,
      season_id: season_id,
      team_id: team_id == "all-teams" ? "" : team_id,
      stat_class: stat_id,
    };
    setIsSkeleton(true);
    if (level_id) {
      qurey.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_schedule",
        new URLSearchParams(qurey).toString()
      );
      console.log(response?.games);
      setPlayersData(response?.games);

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  const fetachSeason = async () => {
    setIsSkeleton(true);
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );
      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);

      let filterdSeasonIdForTeam;

      if (
        searchParams.get("team_id") != "all-teams" &&
        searchParams.get("team_id")
      ) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        const findSeason = response.leagues[0].seasons.filter(
          (el: any) => el.season_id == res?.levels[0]?.season_id
        );
        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }

      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!searchParams.get("season_id")) {
        setMultiplaleParams(qurey);
      }

      const filterData = response.leagues[0].seasons?.filter(
        (el: any) => el.season_id == filterSeasonId
      );

      setCurrentLeague(filterData[0]);
      isTeamVisible &&
        (await fetchTeamData(
          filterSeasonId,
          searchParams.get("team_id"),
          searchParams.get("stat_class") || defaultStat[0].stat_class_id
        ));

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const handleChangeMode = (mode: string) => {
    if (searchParams.get("mode") != mode) {
      // fetachSeason();
      fetchData(
        searchParams.get("season_id"),
        searchParams.get("team_id"),
        searchParams.get("stat_class")
      );
    }

    searchParams.set("mode", mode);

    setSearchParams(searchParams);
    fetchData(
      searchParams.get("season_id"),
      searchParams.get("team_id"),
      searchParams.get("stat_class")
    );
  };
  const handleChangeTeam = async (e: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: searchParams.get("season_id"),
      team_id: e.target.value,
      mode: e.target.value && standingMode,
    };

    setMultiplaleParams(qurey);
    const filterData = teams?.filter((el: any) => el.team_id == e.target.value);
    setCurrentTeam(filterData[0]);
    if (e.target.value == "all-teams") {
      searchParams.delete("mode");
      setSearchParams(searchParams);
      await fetchData(
        searchParams.get("season_id"),
        "",
        searchParams.get("stat_class")
      );
    } else {
      await fetchData(
        searchParams.get("season_id"),
        e.target.value,
        searchParams.get("stat_class")
      );
    }
  };
  const fetchTeamData = async (
    season_id: any,
    team_id: any,
    stat_class: any
  ) => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id,
      stat_class: stat_class,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    try {
      const respone = await makeGetRequest(
        "get_teams",
        new URLSearchParams(qurey).toString()
      );
      const findCurrntHomeTeam = respone.teams?.filter(
        (el: any) => el.team_id == team_id
      );

      let filterTeamId: any;
      if (findCurrntHomeTeam.length >= 1) {
        filterTeamId = findCurrntHomeTeam[0]?.team_id;
      } else {
        filterTeamId = "all-teams";
      }

      qurey = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: season_id,
        team_id: team_id == "all-teams" ? "all-teams" : filterTeamId,
        stat_class: stat_class,
      };

      const filterData = respone.teams?.filter(
        (el: any) => el.team_id == filterTeamId
      );

      setCurrentTeam(filterData[0]);
      setMultiplaleParams(qurey);

      setTeams(respone.teams);

      await fetchData(
        season_id,
        team_id == "all-teams" ? "" : filterTeamId,
        searchParams.get("stat_class")
      );
    } catch (error) {}
  };
  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      mode: standingMode,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: searchParams.get("stat_class"),
    };
    const filterData = league?.filter(
      (el: any) => el.season_id == event.target.value
    );

    setCurrentLeague(filterData[0]);
    isTeamVisible &&
      fetchTeamData(
        event.target.value,
        searchParams.get("team_id") || "",
        searchParams.get("stat_class") || ""
      );

    setMultiplaleParams(params);
  };
  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      searchParams.set("month", selectedValue);
      setSearchParams(searchParams);
    }
  };

  const handleStatChange = async (event: any) => {
    const params: any = {
      season_id: searchParams.get("season_id"),
      mode: standingMode,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: event.target.value,
    };

    fetchTeamData(
      searchParams.get("season_id"),
      searchParams.get("team_id"),
      event.target.value
    );
    setMultiplaleParams(params);
  };

  const downloadAllICS = () => {
    const icsContent: any = generateICS();

    const blob = new Blob([icsContent], {
      type: "text/calendar;charset=utf-8",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "all_game_events.ics"; // Set the file name for the combined .ics file
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const converDate = (time: any) => {
    return momentTimezone
      .tz(
        `${time.date} ${time.formatted_time}`,
        "YYYY-MM-DD HH:mm:ss.SSSSSS A Z",
        time.timezn
      )
      .tz(userTimeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  };

  const icsdata = () => {
    const data = playersData
      .filter((el: any) => {
        if (selectedValue != "all-months") {
          return moment(el.date).format("MM") == selectedValue;
        } else {
          return el;
        }
      })
      .filter((el: any) => {
        if (team_id && standingMode) {
          if (standingMode == "home") {
            return el?.home_id == team_id;
          } else if (standingMode == "away") {
            return el?.away_id == team_id;
          } else {
            return el;
          }
        } else {
          return el;
        }
      })
      .map((eventDetails: any) => {
        const { game_id, alias, time, home_team, away_team, location }: any =
          eventDetails;
        const event: any = {
          start: [
            parseInt(moment(converDate(eventDetails)).format("YYYY")),
            parseInt(moment(converDate(eventDetails)).format("MM")),
            parseInt(moment(converDate(eventDetails)).format("DD")),
            parseInt(moment(converDate(eventDetails)).format("HH")),
            parseInt(moment(converDate(eventDetails)).format("mm")),
          ],
          duration: { hours: 4, minutes: 30 },
          title: `${away_team} @ ${home_team}`,
          description: `${away_team} @ ${home_team}`,
          location: location,
        };

        return event;
      });
    return data;
  };
  const generateICS = () => {
    const { error, value } = ics.createEvents(icsdata());

    return value;
  };
  useEffect(() => {
    isSeasonsVisible && fetachSeason();
  }, []);

  useEffect(() => {
    !isTeamVisible &&
      fetchData(
        searchParams.get("season_id"),
        team_id,
        searchParams.get("stat_class")
      );
  }, [searchParams.get("season_id"), team_id, searchParams.get("stat_class")]);

  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 1 }}>
        <Typography variant="h6" sx={{ fontWeight: 600 }}>
          Schedule
        </Typography>
      </Box>
      <Box
        className="wtd-statview-filter"
        sx={{
          ...CommonStyle.tableControl,
          display: "flex",
          gap: 1,
          px: 1,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {isSeasonsVisible && (
          <SelectDropdownLeague league={league} handleChange={handleChange} />
        )}
        {isTeamVisible && (
          <SelectDropdownTeams
            teams={teams}
            handleChangeTeam={handleChangeTeam}
          />
        )}
        {isStatsVisible && (
          <SelectDropdownStat stats={stats} handleChange={handleStatChange} />
        )}
        <Select
          displayEmpty
          id="demo-simple-select-autowidth"
          autoWidth
          value={selectedValue}
          onChange={(e) => {
            let params: any;
            params = {
              season_id: searchParams.get("season_id"),
              league_id: window?.customconfiguration?.league_id || 4 || 4,
              stat_class: searchParams.get("stat_class"),
              team_id: searchParams.get("team_id"),
              month: e.target.value?.toString() || "all-months",
            };
            if (searchParams.get("mode")) {
              params.mode = searchParams.get("mode");
            }
            setSearchParams(params);
            setSelectedValue(e.target.value?.toString() || "all-months");
          }}
          defaultValue={searchParams.get("months") || moment().format("MM")}
          size="small"
          sx={{
            minWidth: "180px",
            ".MuiSelect-select": {
              py: "4px",
            },
            border: "none",
            backgroundColor: "#fff",
            borderRadius: "0",
          }}
        >
          <MenuItem value={"all-months"}>All Months</MenuItem>
          {MonthsOption.map((el: any) => (
            <MenuItem value={el?.key}>{el?.label}</MenuItem>
          ))}
        </Select>
        {team_id != "all-teams" && (
          <ButtonGroup
            sx={{
              boxShadow: "none",
              borderColor: "transparent",
              border: "1px solid transparent",
              ".MuiButtonGroup-grouped:not(:last-of-type)": {
                borderColor: "transparent",
              },
            }}
            variant="contained"
          >
            <Button
              className={`wtd-statview-button-color ${
                standingMode == "home&away" ? "active" : ""
              }`}
              sx={{
                ...CommonStyle.btnSwitch,
                backgroundColor:
                  standingMode == "home&away" ? "#ffb931" : "#000000",
                fontWeight: standingMode == "home&away" ? 600 : 200,
                color: standingMode == "home&away" ? "#000000" : "",
              }}
              onClick={() => {
                handleChangeMode("home&away");
              }}
            >
              Home & Away
            </Button>
            <Button
              className={`wtd-statview-button-color ${
                standingMode == "home" ? "active" : ""
              }`}
              sx={{
                ...CommonStyle.btnSwitch,
                backgroundColor: standingMode == "home" ? "#ffb931" : "#000000",
                fontWeight: standingMode == "home" ? 600 : 200,
                color: standingMode == "home" ? "#000000" : "",
              }}
              onClick={() => {
                handleChangeMode("home");
              }}
            >
              Home
            </Button>
            <Button
              className={`wtd-statview-button-color ${
                standingMode == "away" ? "active" : ""
              }`}
              sx={{
                ...CommonStyle.btnSwitch,
                backgroundColor: standingMode == "away" ? "#ffb931" : "#000000",
                fontWeight: standingMode == "away" ? 600 : 200,
                color: standingMode == "away" ? "#000000" : "",
              }}
              onClick={() => {
                handleChangeMode("away");
              }}
            >
              Away
            </Button>
          </ButtonGroup>
        )}{" "}
        <IconButton
          sx={{
            bgcolor: "white",
            ":hover": {
              bgcolor: "white",
            },
          }}
          onClick={downloadAllICS}
        >
          <CalendarMonthIcon />
        </IconButton>
      </Box>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "80vh" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            {(
              isTeamVisible
                ? playersData.filter((el: any) => {
                    if (selectedValue != "all-months") {
                      return moment(el.date).format("MM") == selectedValue;
                    } else {
                      return el;
                    }
                  })?.length > 0
                : playersData?.filter((el: any) => {
                    if (selectedValue != "all-months") {
                      return moment(el.date).format("MM") == selectedValue;
                    } else {
                      return el;
                    }
                  })?.length > 0
            ) ? (
              <ScheduleTable
                playersData={
                  isTeamVisible
                    ? playersData
                        .filter((el: any) => {
                          if (selectedValue != "all-months") {
                            return (
                              moment(el.date).format("MM") == selectedValue
                            );
                          } else {
                            return el;
                          }
                        })
                        .filter((el: any) => {
                          if (team_id && standingMode) {
                            if (standingMode == "home") {
                              return el?.home_id == team_id;
                            } else if (standingMode == "away") {
                              return el?.away_id == team_id;
                            } else {
                              return el;
                            }
                          } else {
                            return el;
                          }
                        })
                    : playersData.filter((el: any) => {
                        if (selectedValue != "all-months") {
                          return moment(el.date).format("MM") == selectedValue;
                        } else {
                          return el;
                        }
                      })
                }
              />
            ) : (
              <Box sx={{ textAlign: "center", py: 2 }}>
                NO GAMES MATCH THE SELECTED CRITERIA
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}
