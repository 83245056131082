import React, { useEffect, useRef, useState } from "react";

import { makeGetRequest } from "../../service";
import CommonStyle from "../../Common/style/Style";

import { PlayerPosition } from "../../utils/constant";
import { Box, CircularProgress, Typography } from "@mui/material";
import PlayerTable from "./Table";

import { useParams, useSearchParams } from "react-router-dom";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import SelectDropdownTeams from "../../Common/SelectDropdownTeam";
import TableDetails from "../../Common/Component/TableDetails";
import SelectDropdownStat from "../../Common/SelectDropdownStat";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function TableComponent() {
  const [playersData, setPlayersData] = useState({
    forward: [],
    defense: [],
    goalie: [],
    coaches: [],
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [league, setLeague] = useState([]);
  const [teams, setTeams] = useState([]);
  const [stats, setStats] = useState([]);
  const [currentLeague, setCurrentLeague] = useState<any>({});
  const [currentTeam, setCurrentTeam] = useState<any>({});
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: searchParams.get("sort") || "",
    direction: searchParams.get("direction") || "",
  });
  const { id } = useParams();
  const level_id = searchParams.get("level_id");
  const filteredData = (data: any, plays: any) =>
    data.filter(
      (resItem: any) => plays.includes(resItem.plays) && resItem?.coach != "1"
    );
  const filterDataCoches = (data: any) => {
    return data?.filter((resItem: any) => resItem.coach == "1");
  };
  const fetchData = async (season_id: any, team_id: any, stat_id: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id,
      team_id: team_id,
      stat_class: stat_id || 1,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    setIsSkeleton(true);
    try {
      const response = await makeGetRequest(
        "get_roster",
        new URLSearchParams(qurey).toString()
      );

      setPlayersData({
        ...playersData,
        forward: filteredData(response.players, PlayerPosition.forward),
        defense: filteredData(response.players, PlayerPosition.defense),
        goalie: filteredData(response.players, PlayerPosition.goalie),
        coaches: filterDataCoches(response.players),
      });

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  const fetachSeason = async () => {
    setIsSkeleton(true);
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id || 4,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );
      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);
      let filterdSeasonIdForTeam;
      if (
        searchParams.get("team_id") != "all-teams" &&
        searchParams.get("team_id")
      ) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        const findSeason = response.leagues[0].seasons.filter(
          (el: any) => el.season_id == res?.levels[0]?.season_id
        );
        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!searchParams.get("season_id")) {
        setSearchParams(qurey);
      }

      const filterData = response.leagues[0].seasons?.filter(
        (el: any) => el.season_id == filterSeasonId
      );

      setCurrentLeague(filterData[0]);
      await fetchTeamData(
        filterSeasonId,
        searchParams.get("team_id"),
        searchParams.get("stat_class") || defaultStat[0].stat_class_id
      );

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };

  const handleChangeTeam = async (e: any) => {
    const qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: searchParams.get("season_id"),
      team_id: e.target.value,
      stat_class: searchParams.get("stat_class") || "",
    };

    setSearchParams(qurey);
    const filterData = teams?.filter((el: any) => el.team_id == e.target.value);
    setCurrentTeam(filterData[0]);
    await fetchData(
      searchParams.get("season_id"),
      e.target.value,
      searchParams.get("stat_class") || ""
    );
  };
  const fetchTeamData = async (
    season_id: any,
    team_id: any,
    stat_class: any
  ) => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      season_id: season_id,
      stat_class,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    try {
      const respone = await makeGetRequest(
        "get_teams",
        new URLSearchParams(qurey).toString()
      );

      const findCurrntHomeTeam = respone.teams?.filter(
        (el: any) => el.team_id == team_id
      );

      let filterTeamId: any;
      if (findCurrntHomeTeam.length >= 1) {
        filterTeamId = findCurrntHomeTeam[0]?.team_id;
      } else {
        filterTeamId = respone.teams[0].team_id;
      }
      qurey = {
        league_id: window?.customconfiguration?.league_id || 4 || 4,
        season_id: season_id,
        team_id: filterTeamId,
        stat_class: stat_class,
      };

      const filterData = respone.teams?.filter(
        (el: any) => el.team_id == filterTeamId
      );

      setCurrentTeam(filterData[0]);
      setMultiplaleParams(qurey);

      setTeams(respone.teams);

      await fetchData(season_id, filterTeamId, stat_class);
    } catch (error) {}
  };

  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      league_id: window?.customconfiguration?.league_id || 4 || 4,
    };
    const filterData = league?.filter(
      (el: any) => el.season_id == event.target.value
    );

    setCurrentLeague(filterData[0]);
    fetchTeamData(
      event.target.value,
      searchParams.get("team_id") || "",
      searchParams.get("stat_class") || ""
    );
    setMultiplaleParams(params);
  };

  const handleStatChange = (event: any) => {
    const params: any = {
      season_id: searchParams.get("season_id"),
      league_id: window?.customconfiguration?.league_id || 4 || 4,
      stat_class: event.target.value,
    };
    const filterData = stats?.filter(
      (el: any) => el.stat_class_id == event.target.value
    );

    fetchTeamData(
      searchParams.get("season_id") || "",
      searchParams.get("team_id") || "",
      event.target.value
    );
    setMultiplaleParams(params);
  };

  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };

  const sortTable = (key: string, sort: string) => {
    searchParams.set("sort", key);
    const currentSortDirection = searchParams.get("direction");
    if (sortConfig.key === key) {
      setSortConfig({
        key,
        direction: currentSortDirection === "asc" ? "desc" : "asc",
      });
      searchParams.set(
        "direction",
        currentSortDirection === "asc" ? "desc" : "asc"
      );
    } else {
      searchParams.set("direction", sort);
      setSortConfig({ key, direction: sort });
    }
    setSearchParams(searchParams);
  };

  const getColumnLabel = (column: {
    id: string;
    label: string;
    sort: string;
    align: string;
  }) => {
    return !["jersey"].includes(column.id) ? (
      column.label
    ) : (
      <a
        style={{
          cursor: "pointer",
          textDecoration: "underline",
          display: "flex",
          alignItems: "center",
          justifyContent: column.align,
        }}
        onClick={() => sortTable(column.id, column.sort ? column.sort : "desc")}
      >
        {column.label}
        {sortConfig.key === column.id && (
          <span style={{ display: "flex" }}>
            {sortConfig.direction === "asc" ? (
              <ArrowDropUpIcon />
            ) : (
              <ArrowDropDownIcon />
            )}
          </span>
        )}
      </a>
    );
  };

  useEffect(() => {
    fetachSeason();
  }, []);

  return (
    <Box sx={{ px: 1 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1, py: 1 }}>
        <Box sx={{ width: "50px" }}>
          <img src={currentTeam?.smlogo} width="100%" />
        </Box>
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 800, fontSize: 18 }}
          >
            {currentTeam?.team_name}
          </Typography>
          <Typography variant="body1">{currentLeague?.season_name}</Typography>
        </Box>
      </Box>
      <Box
        className="wtd-statview-filter"
        sx={{
          ...CommonStyle.tableControl,
          display: "flex",
          gap: 1,
          px: 1,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <SelectDropdownLeague league={league} handleChange={handleChange} />
        <SelectDropdownTeams
          teams={teams}
          handleChangeTeam={handleChangeTeam}
        />
        <SelectDropdownStat stats={stats} handleChange={handleStatChange} />
      </Box>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "80vh" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        <Box>
          <Box>
            {playersData?.forward.length > 0 && (
              <PlayerTable
                playersData={playersData?.forward}
                playerPosition={"Forwards"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
            {playersData?.defense.length > 0 && (
              <PlayerTable
                playersData={playersData?.defense}
                playerPosition={"Defensemen"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
            {playersData?.goalie.length > 0 && (
              <PlayerTable
                playersData={playersData?.goalie}
                playerPosition={"Goalies"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
            {playersData?.coaches.length > 0 && (
              <PlayerTable
                playersData={playersData?.coaches}
                playerPosition={"Coaches"}
                sortConfig={sortConfig}
                getColumnLabel={getColumnLabel}
              />
            )}
          </Box>
          <TableDetails />
        </Box>
      )}
    </Box>
  );
}
