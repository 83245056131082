import {
  Avatar,
  Box,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import registered from "../../assets/img/registered-img.png";
import copyright from "../../assets/img/copyright-img.png";
import addText from "../../assets/img/add-text.png";
import CommonStyle from "../../Common/style/Style";
import styled from "@emotion/styled";
import { CoachRole, PageRoutes } from "../../utils/constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

interface Column {
  id:
    | "date"
    | "away_team"
    | "away_goals"
    | "away_goals"
    | "location"
    | "home_team"
    | "home_goals"
    | "attendance"
    | "heightft"
    | "time"
    | "town";
  label: string;
  align?: "right";
}

const columns: readonly Column[] = [
  { id: "date", label: "Date" },
  { id: "away_team", label: "Visiting" },
  { id: "away_goals", label: "GF" },
  { id: "home_team", label: "Home" },
  {
    id: "home_goals",
    label: "GF",
  },
  {
    id: "location",
    label: "Venue",
  },
  {
    id: "attendance",
    label: "Att",
  },
  {
    id: "time",
    label: "Time",
  },
  {
    id: "town",
    label: "",
  },
];

export const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    backgroundColor: "#cecece",
  },
}));

const PlayerTable = ({ playersData }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const season_id = searchParams.get("season_id");
  const stat_class = searchParams.get("stat_class");

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={CommonStyle.tableHeader}
                  className="wtd-statview-header-color"
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {playersData
              ?.sort((a: any, b: any) => (a.gmt_time < b.gmt_time ? -1 : 1))
              ?.map((row: any, index: number) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + 1}
                    className="wtd-statview-table-row-color"
                  >
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {moment(row.date).format("MMM. DD")}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Link
                        href={`${PageRoutes.TeamDetail}?team_id=${row.away_id}&season_id=${season_id}&stat_class=${stat_class}`}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          cursor: "pointer",
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        <Avatar src={row.away_smlogo} />
                        <Typography
                          variant="body2"
                          sx={{
                            ":hover": {
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontWeight: 600,
                            },
                          }}
                        >
                          {row.away_team}
                        </Typography>
                      </Link>
                    </TableCell>

                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.away_goals}
                    </TableCell>

                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Link
                        href={`${PageRoutes.TeamDetail}?team_id=${row.home_id}&season_id=${season_id}&stat_class=${stat_class}`}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 1,
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        <Avatar src={row.home_smlogo} />
                        <Typography
                          variant="body2"
                          sx={{
                            ":hover": {
                              textDecoration: "underline",
                              cursor: "pointer",
                              fontWeight: 600,
                            },
                          }}
                        >
                          {row.home_team}
                        </Typography>
                      </Link>
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.home_goals}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.location}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {row.attendance}
                    </TableCell>
                    <TableCell sx={{ py: 1 }} align={"left"}>
                      {!row?.away_goals && !row?.home_goals ? (
                        <>
                          {moment(row?.time, "hh:mm a").format("hh:mm A")}{" "}
                          {row?.timezn_ab}
                        </>
                      ) : row?.game_status == "CLOSED" ? (
                        row?.result_string
                      ) : row?.game_status == "OPEN" ? (
                        "FINAL/SO"
                      ) : (
                        ""
                      )}
                    </TableCell>

                    <TableCell sx={{ py: 1 }} align={"left"}>
                      <Box
                        sx={{ display: "flex", gap: 1, alignItems: "center" }}
                      >
                        <Link
                          href={`https://images-us-east.s3.us-east-2.amazonaws.com/nahl/Broadcast+Packs/${row?.game_id}.pdf`}
                          type="download"
                          target="_blank"
                          sx={{
                            cursor: "pointer",
                            whiteSpace: "nowrap",
                            color: "white",
                          }}
                          download
                        >
                          <Box
                            sx={{
                              width: "22px",
                              height: "22px",
                              bgcolor: "#252525",
                              borderRadius: "50%",
                              p: "1px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: "bolder",
                                fontSize: "12px",
                                color: "white",
                              }}
                            >
                              BP
                            </Typography>{" "}
                          </Box>
                        </Link>

                        <Link
                          sx={{
                            cursor: "pointer",
                            color: "black",
                            textDecoration: "none",
                          }}
                          href={`${PageRoutes.GameCenter}?game_id=${row?.game_id}`}
                        >
                          <Avatar
                            src={`${process.env.REACT_APP_S3URL}/assets/C_Schedule.jpeg`}
                            sx={{
                              borderRadius: "50%",
                              width: "22px",
                              height: "22px",
                            }}
                          />
                        </Link>
                        <a href={row?.scoresheet_link} target="_blank">
                          <Avatar
                            sx={{
                              borderRadius: "50%",
                              width: "22px",
                              height: "22px",
                            }}
                            src={`${process.env.REACT_APP_S3URL}/assets/R_ScheduleImage.jpeg`}
                          />
                        </a>

                        <a
                          href="https://www.nahltv.com/login"
                          target={"_blank"}
                        >
                          <Avatar
                            sx={{
                              borderRadius: "50%",
                              height: "36px",
                              width: "60px",
                            }}
                            src={`https://images-us-east.s3.us-east-2.amazonaws.com/nahl/web-logo/NAHLTV.png`}
                          />
                        </a>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PlayerTable;
