import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Avatar,
  Box,
} from "@mui/material";
import React from "react";
import CommonStyle from "../../Common/style/Style";
import { StyledTableRow } from "../TableComponent/Table";

const HBHTableOne = (props: any) => {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                align="left"
                sx={CommonStyle.tableHeader}
                className="wtd-statview-header-color"
                colSpan={4}
              >
                {props.header}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.listData?.map((row: any, index: number) => {
              return (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index + 1}
                  className="wtd-statview-table-row-color"
                >
                  <TableCell sx={{ py: 1, minWidth: "230px" }} align={"left"}>
                    <Box
                      sx={{
                        display: "flex",

                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Avatar
                          src={
                            row.team1_src
                              ? row.team1_src
                              : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                          }
                          sx={{
                            borderRadius: "0px",
                            width: "50px",
                            height: "50px",
                            textAlign: "center",
                          }}
                        />
                        <Typography variant="body1">
                          {row.team1_scores}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.team1}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell sx={{ py: 1, minWidth: "230px" }} align={"left"}>
                    <Box
                      sx={{
                        display: "flex",

                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                      >
                        <Avatar
                          src={
                            row.team2_src
                              ? row.team2_src
                              : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                          }
                          sx={{
                            borderRadius: "0px",
                            width: "50px",
                            height: "50px",
                            textAlign: "center",
                          }}
                        />
                        <Typography variant="body1">
                          {row.team2_scores}
                        </Typography>
                      </Box>
                      <Typography
                        variant="body1"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {row.team2}
                      </Typography>
                    </Box>
                  </TableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default HBHTableOne;
