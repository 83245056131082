import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  CircularProgress,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableMain from "./TableMain";
import TableTitle from "./TableTitle";
import TableFooter from "./TableFooter";
import team1 from "../../Common/images/team1.jpg";
import teamLogo from "../../Common/images/team-logo.png";
import team2 from "../../Common/images/team2.jpg";
import CommonStyle from "../../Common/style/Style";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ToggleSwitch from "./ToggleSwitch";
import PBPTable from "./PBPTable";
import HBHTableOne from "./HBHTableOne";
import HBHTableTwo from "./HBHTableTwo";
import TSTable from "./TSTable";
import LastGamesTable from "./LastGamesTable";
import { useLocation, useSearchParams } from "react-router-dom";
import { db, makeGetRequest } from "../../service";
import { StyledTableRow } from "../TableComponent/Table";
import ScoringTable from "./ScoringTable/ScroingTable";
import PlayByPlayTable from "./PlayByPlay/PlayByPlayTable";
import { PageRoutes } from "../../utils/constant";
import { onValue, ref } from "firebase/database";
import moment from "moment";

const GameCenterBoard = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const game_id = searchParams.get("game_id") || "";
  const [isSkeleton, setIsSKeleton] = useState(true);
  const [gameData, setGameData] = useState({} as any);
  const location = useLocation();
  const [liveData, setLiveData] = useState<any>({});
  const isMobile = useMediaQuery((theme: any) =>
    theme.breakpoints.down("1030")
  );
  const [hideData, setHideData] = useState({
    fisrt: false,
    second: false,
    third: false,
    ot: false,
    goals: false,
    penalties: false,
  });
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const [switches, setSwitches] = useState({
    first: true,
    second: true,
    third: true,
    ot: true,
    goals: true,
    panelties: true,
    Save: true,
    Shots: true,
  });
  const covertIntoArry = (object: any) => {
    let data = [];
    for (const key in object) {
      data.push(object[key]);
    }
    return data;
  };
  const offiicialArray = () => {
    const array = [];

    gameData?.scorekeeper &&
      array.push({
        officials: gameData?.scorekeeper,
        role: "Scorekeeper",
      });
    gameData?.officials[1] &&
      array.push({
        officials: gameData?.officials[1],
        role: "Referee 1",
      });
    gameData?.officials[2] &&
      array.push({
        officials: gameData?.officials[2],
        role: "Linesman 1",
      });
    gameData?.officials[3] &&
      array.push({
        officials: gameData?.officials[3],
        role: "Linesman 2",
      });
    gameData?.officials[4] &&
      array.push({
        officials: gameData?.officials[4],
        role: "Referee 2",
      });
    return array;
  };
  const fiilterArray = (object: any) => {
    let data = [];
    for (const key in object) {
      data.push({ key: key, array: object[key] });
    }

    return data;
  };
  const createObject = (shots: any, summary: any, away_ab: any) => {
    const obje = gameData?.period_list?.reduce((obj: any, value: any) => {
      obj[value] = gameData?.[summary]?.[shots]?.[value];
      return obj;
    }, {});
    obje.sog = away_ab;

    obje.total = gameData?.[summary]?.[shots]?.total;

    return obje;
  };
  const getGameDetails = async () => {
    let qurey: any = {
      game_id: game_id,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: game_id,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${game_id}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      startup_new(array);
      setGameData(data);
      setIsSKeleton(false);
    } catch (error) {
      setIsSKeleton(false);
    }
  };
  const getGameDetailsLive = async () => {
    let qurey: any = {
      game_id: game_id,
    };

    try {
      const res = await makeGetRequest(
        "get_game_center",
        new URLSearchParams(qurey).toString()
      );

      const channel = {
        game_id: game_id,
        channel_type: 2,
        prefix: `${res?.game_center?.game_info?.game_channel}_${game_id}`,
        channel: res?.game_center?.game_info?.game_channel,
      };
      const array = [channel];
      const data = {
        ...res?.game_center?.pregame,
        ...res?.game_center?.live,
        ...res?.game_center?.postgame,
        ...res?.game_center?.game_info,
      };

      setGameData(data);
    } catch (error) {}
  };
  function GetRosterData(game_id: any) {
    var xmlHttp: any;

    xmlHttp = new XMLHttpRequest();

    if (xmlHttp == null) {
      alert("Browser does not support Ajax");
    } else {
      xmlHttp.open(
        "GET",
        "http://live-new.sharksice.timetoscore.com" +
          "/get_game_rosters?game_id=" +
          game_id,
        true
      );
      xmlHttp.onreadystatechange = function ClientSideUpdate() {
        var result;

        if (xmlHttp.readyState == 4) {
          result = JSON.parse(xmlHttp.responseText);
          if (result) {
            console.log(result);
          }
        }
      };

      xmlHttp.send(null);
    }
  }
  function GetGameData(prefix: any, game_id: any) {
    var xmlHttp: any;

    xmlHttp = new XMLHttpRequest();
    if (xmlHttp == null) {
      alert("Browser does not support Ajax");
    } else {
      xmlHttp.open(
        "GET",
        "http://live-new.sharksice.timetoscore.com" +
          "/get_team_info?game_id=" +
          game_id,
        true
      );
      xmlHttp.onreadystatechange = function ClientSideUpdate() {
        var result;
        var oCell;

        if (xmlHttp.readyState == 4) {
          result = JSON.parse(xmlHttp.responseText);
          console.log(result);
        }
      };

      xmlHttp.send(null);
    }
  }
  function startup_new(channels: any) {
    var i = 0;

    for (i = 0; i < channels.length; i++) {
      /* Firebase mode */
      if (channels[i].game_id > 0)
        var firebase_channel =
          "games/" + channels[i].channel + "/" + channels[i].game_id;
      else var firebase_channel = "rinks/" + channels[i].channel;

      var rink_ref = ref(db, firebase_channel);
      console.log("firebase Starting " + firebase_channel);
      (function (prefix, game) {
        var last_game_id = 0;
        var requested_game = game;
        if (requested_game) {
          // GetGameData(prefix + "_", requested_game);
          // GetRosterData(requested_game);
          last_game_id = requested_game;
        }

        onValue(rink_ref, (snapshot: any) => {
          const fb_data = snapshot.val();
          if (fb_data == null) return;
          const data = fb_data.clock_data;

          if (fb_data.clock_data == null) return;

          if (requested_game && data.game_id != requested_game) {
            return;
          }
          if (data.rosters == 1 || data.events == 1) {
            getGameDetailsLive();
          }

          setLiveData(data);
          // process_scoreboard_data(prefix + "_", data);
          if (last_game_id != data.game_id) {
            GetGameData(prefix + "_", data.game_id);
            GetRosterData(data.game_id);
            last_game_id = data.game_id;
          }
        });
      })(channels[i].prefix, channels[i].game_id);
    }
  }
  useEffect(() => {
    getGameDetails();
  }, []);
  if (isSkeleton) {
    return (
      <Box sx={{ position: "relative", height: "80vh" }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
          }}
        >
          <CircularProgress />
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={{ px: 2 }}>
      {/* Header */}
      <Grid container spacing={1}>
        <Grid item xs={3.5} sm={4} md={4} lg={4} xl={4}>
          {!isMobile ? (
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "150px",
                alignItems: "center",
              }}
            >
              <Grid item xs={6} sm={6} md={6} sx={{ textAlign: "end" }} lg={6}>
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0 10px",
                    fontSize: "1.2rem",
                    fontWeight: "700",
                    lineHeight: "1.5",
                  }}
                >
                  {gameData?.away_name}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} sx={{ textAlign: "end" }} lg={4}>
                <img
                  src={
                    gameData?.away_logo
                      ? gameData?.away_logo
                      : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                  }
                  alt="Image"
                  style={{
                    height: "auto",
                    maxWidth: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Typography
                  variant="body1"
                  sx={{
                    color: "#000",
                    fontSize: { xs: "24px", md: "44px" },
                    fontWeight: "600",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {gameData?.goal_summary?.away_goals?.total}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "150px",
                alignItems: "center",
                verticalAlign: "top",
              }}
            >
              <Box>
                <img
                  src={
                    gameData?.away_logo
                      ? gameData?.away_logo
                      : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                  }
                  alt="Image"
                  style={{
                    margin: "10px auto",
                    height: "auto",
                    maxWidth: "80px",
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0 10px",
                    fontSize: "1.2rem",
                    fontWeight: "700",
                    lineHeight: "1.5",
                  }}
                >
                  {gameData?.away_ab}
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        <Grid
          item
          xs={5}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          sx={{
            display: "flex",
            my: 3,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {isMobile ? (
            <Typography
              variant="body1"
              sx={{
                fontSize: "1.4rem",
                fontWeight: "700",
                lineHeight: "1.5",
              }}
            >
              {gameData?.goal_summary?.away_goals["total"]}-{" "}
              {gameData?.goal_summary?.home_goals["total"]}
            </Typography>
          ) : null}
          <Typography
            variant="body1"
            sx={{
              fontSize: "1.2rem",
              fontWeight: "700",
              lineHeight: "1.5",
            }}
          >
            {liveData?.period != " " && liveData?.period
              ? liveData?.period
              : gameData?.status == "NOT STARTED"
              ? gameData?.date
              : gameData?.status}
          </Typography>
          {liveData?.clock && liveData?.status == "OPEN" && (
            <Typography variant="h1">{liveData?.clock}</Typography>
          )}
          <Typography
            variant="body1"
            sx={{ fontSize: { xs: "14px", md: "16px" } }}
          >
            Game #:{gameData?.alias}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              py: 1,
              textAlign: "center",
              fontSize: { xs: "14px", md: "16px" },
            }}
          >
            {gameData?.formatted_date}
          </Typography>
          {!isMobile ? (
            <Typography variant="body1" sx={{ py: 1.5 }}>
              <a href={gameData?.scoresheet_url} style={{ color: "#000000" }}>
                Game Report
              </a>{" "}
              |{" "}
              <a href={gameData?.boxscore_url} style={{ color: "#000000" }}>
                Text Box Score
              </a>
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={3.5} sm={4} md={4} lg={4} xl={4}>
          {!isMobile ? (
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                height: "150px",
                alignItems: "center",
              }}
            >
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0 10px",
                    fontSize: "1.2rem",
                    fontWeight: "700",
                    lineHeight: "1.5",
                  }}
                >
                  {gameData?.home_name}
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4}>
                <img
                  src={
                    gameData?.home_logo
                      ? gameData?.home_logo
                      : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                  }
                  alt="Image"
                  style={{
                    height: "auto",
                    maxWidth: "100px",
                  }}
                />
              </Grid>
              <Grid item xs={2} sm={2} md={2} lg={2}>
                <Typography
                  variant="body1"
                  sx={{
                    minWidth: "50px",
                    color: "#000",
                    fontSize: { xs: "24px", md: "44px" },
                    fontWeight: "600",
                    textAlign: "center",
                    verticalAlign: "middle",
                  }}
                >
                  {gameData?.goal_summary?.home_goals["total"]}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "150px",
                alignItems: "center",
                verticalAlign: "top",
              }}
            >
              <Box>
                <img
                  src={
                    gameData?.home_logo
                      ? gameData?.home_logo
                      : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                  }
                  alt="Image"
                  style={{
                    margin: "10px auto",
                    height: "auto",
                    maxWidth: "80px",
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    padding: "0 10px",
                    fontSize: "1.2rem",
                    fontWeight: "700",
                    lineHeight: "1.5",
                  }}
                >
                  {gameData?.home_ab}
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      {/* First Tables Grid */}
      <Grid container spacing={2.5} sx={{ py: 1.5 }}>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <TableMain
            headers={[
              { id: "sog", label: "Scoring", align: "left" },
              ...gameData?.period_list?.map((el: any) => {
                return {
                  id: el.toString(),
                  label:
                    el == 1 ? "1st" : el == 2 ? "2nd" : el == 3 ? "3rd" : el,
                  align: "left",
                };
              }),
              {
                id: "total",
                label: liveData.clock ? "TOTAL" : "Final",
                align: "left",
              },
            ]}
            listData={[
              createObject("away_goals", "goal_summary", gameData?.away_ab),
              createObject("home_goals", "goal_summary", gameData?.home_ab),
            ]}
          />
        </Grid>
        {/* { id: "1", label: "1" },
              { id: "2", label: "2" },
              { id: "3", label: "3" },
              { id: "ot", label: "OT" },
              { id: "total", label: "T" }, */}
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <TableMain
            headers={[
              { id: "sog", label: "SOG", align: "left" },
              ...gameData?.period_list?.map((el: any) => {
                return {
                  id: el.toString(),
                  label:
                    el == 1 ? "1st" : el == 2 ? "2nd" : el == 3 ? "3rd" : el,
                  align: "left",
                };
              }),
              {
                id: "total",
                label: liveData.clock ? "TOTAL" : "Final",
                align: "left",
              },
            ]}
            listData={[
              createObject("home_shots", "shot_summary", gameData?.away_ab),
              createObject("away_shots", "shot_summary", gameData?.home_ab),
            ]}
            // listData={[
            //   {
            //     sog: gameData?.away_ab,
            //     "1": gameData?.away_saves?.total?.p1_shots,
            //     "2": gameData?.away_saves?.total?.p2_shots,
            //     "3": gameData?.away_saves?.total?.p3_shots,
            //     ot: gameData?.away_saves?.total?.p4_shots,
            //     total: gameData?.away_saves?.total?.total_shots,
            //   },
            //   {
            //     sog: gameData?.home_ab,
            //     "1": gameData?.home_saves?.total?.p1_shots,
            //     "2": gameData?.home_saves?.total?.p2_shots,
            //     "3": gameData?.home_saves?.total?.p3_shots,
            //     ot: gameData?.home_saves?.total?.p4_shots,
            //     total: gameData?.home_saves?.total?.total_shots,
            //   },
            // ]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4}>
          <TableMain
            headers={[
              { id: "rank", label: "", align: "left" },
              { id: "pp", label: "PP", align: "right" },
              { id: "pim", label: "PIM", align: "right" },
            ]}
            listData={[
              {
                rank: gameData?.away_ab,
                pp: `${gameData?.misc_summary?.away_ppg}/${gameData?.misc_summary?.away_pp}`,

                pim: `${gameData?.misc_summary?.away_pim} min/ ${gameData?.misc_summary?.away_penalties} in`,
              },
              {
                rank: gameData?.home_ab,
                pp: `${gameData?.misc_summary?.home_ppg}/${gameData?.misc_summary?.home_pp}`,
                pim: `${gameData?.misc_summary?.home_pim} min/ ${gameData?.misc_summary?.home_penalties} in`,
              },
            ]}
          />
        </Grid>
      </Grid>

      {/* Second Tables Grid */}
      <Grid container spacing={2.5} sx={{ py: 1.5 }}>
        <Grid item xs={12} lg={6}>
          <TableTitle title={"SCORING"} />
          {fiilterArray(gameData?.events)
            .filter((el) => el.key != "SO")
            ?.map((el: any, index: any) => (
              <ScoringTable
                data={el}
                el={el.array.filter((el: any) => el.type == "goal")}
                index={index}
              />
            ))}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TableTitle title={"PENALTIES"} />

          {fiilterArray(gameData?.events)
            ?.filter((el) => el.key != "SO")
            ?.map((el: any, index: any) => (
              <TableMain
                Title="PENALTIES"
                headers={[
                  {
                    id: "time_in",
                    label: `${
                      el.key == 1
                        ? "1st Period"
                        : el.key == 2
                        ? "2nd Period"
                        : el.key == 3
                        ? "3rd Period"
                        : el.key
                    }`,
                    align: "left",
                  },
                  { id: "team_logo", label: "", type: "img", align: "left" },
                  {
                    id: "penalty_player_name",
                    label: "",

                    align: "left",
                  },

                  { id: "penalty_name", label: "", align: "left" },
                ]}
                listData={
                  el.key == "SO"
                    ? []
                    : el.array.filter(
                        (element: any) => element.type == "penalty"
                      )
                }
              />
            ))}
        </Grid>
      </Grid>
      {/* Fourth Tables Grid */}
      {gameData?.events?.SO && (
        <Grid container spacing={2.5} sx={{ py: 4 }}>
          <Grid item xs={12} lg={6}>
            <TableTitle
              title={`${gameData?.away_name} Shootout`}
              onClick={(e: any) => {
                alert(e.target.innerHTML);
              }}
            />
            <TableMain
              headers={[
                { id: "shooter_jersey", label: "#" },

                { id: "shooter_name", label: "Skater", align: "left" },

                { id: "succeeded", label: "Goal" },
              ]}
              listData={[
                ...covertIntoArry(
                  gameData?.events?.SO.filter(
                    (el: any) => el?.team_id == gameData?.away_id
                  )
                ),
                {
                  shooter_name: "Total",
                  succeeded: covertIntoArry(
                    gameData?.events?.SO.filter(
                      (el: any) => el?.team_id == gameData?.away_id
                    )
                  ).filter((el: any) => {
                    return el.succeeded.toUpperCase() == "YES";
                  })?.length,
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TableTitle
              title={`${gameData?.home_name} Shootout`}
              onClick={(e: any) => {
                alert(e.target.innerHTML);
              }}
            />
            <TableMain
              headers={[
                { id: "shooter_jersey", label: "#" },

                { id: "shooter_name", label: "Skater", align: "left" },

                { id: "succeeded", label: "Goal" },
              ]}
              listData={[
                ...covertIntoArry(
                  gameData?.events?.SO.filter(
                    (el: any) => el?.team_id == gameData?.home_id
                  )
                ),
                {
                  shooter_name: "Total",
                  succeeded: covertIntoArry(
                    gameData?.events?.SO.filter(
                      (el: any) => el?.team_id == gameData?.home_id
                    )
                  ).filter((el: any) => {
                    return el.succeeded.toUpperCase() == "YES";
                  })?.length,
                },
              ]}
            />
          </Grid>
        </Grid>
      )}

      <TableTitle
        title={`Stars`}
        onClick={(e: any) => {
          alert(e.target.innerHTML);
        }}
      />
      <Grid
        container
        spacing={2.5}
        sx={{ py: 4, justifyContent: "space-between" }}
      >
        {covertIntoArry(gameData?.mvps)?.map(
          (el: any, index: any) =>
            index <= 2 && (
              <Grid item xs={12} md={6} lg={3}>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <Avatar
                    sx={{ width: "80px", height: "80px" }}
                    src={el?.player_image}
                  />
                  <Box>
                    <Typography variant="body2">{index + 1}</Typography>
                    <Link
                      sx={{ color: "black", textDecoration: "none" }}
                      href={`${PageRoutes.PlayerProfile}?player_id=${el?.player_id}`}
                    >
                      <Typography sx={{ cursor: "pointer" }} variant="body2">
                        {el?.name} #({el?.jersey})
                      </Typography>
                    </Link>
                    <Typography variant="body2">{el?.team_name}</Typography>
                    {el?.goalie == 1 ? (
                      <Typography variant="body2">
                        SA:{el?.shots_against}|SV:{el?.saves}|TOI:{el?.toi}
                      </Typography>
                    ) : (
                      <Typography variant="body2">
                        G:{el?.goals}|A:{el?.assists}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Grid>
            )
        )}
      </Grid>
      {/* Third Table */}
      <Grid container spacing={2.5} sx={{ py: 4 }}>
        <Grid item xs={12} lg={12}>
          <TableTitle title={"GOALTENDERS"} />
          <TableMain
            headers={[
              {
                id: "team_name_GOALTENDERS",
                label: gameData?.away_name,
                align: "left",
              },
              { id: "toi", label: "TOI", align: "left" },
              { id: "sv/sh", label: "SV/SH", align: "left" },
              { id: "on", label: "ON", align: "left" },
              { id: "off", label: "OFF", align: "left" },
            ]}
            listData={gameData?.away_goalie_changes?.map(
              (el: any, index: any) => {
                return {
                  team_name_GOALTENDERS: el?.goalie_name,
                  toi: el?.toi,
                  "sv/sh": `${el?.saves}/${el?.shots_against}`,
                  on: el?.on,
                  off: el?.off,
                };
              }
            )}
          />
          <TableMain
            headers={[
              {
                id: "team_name_GOALTENDERS",
                label: gameData?.home_name,
                align: "left",
              },
              { id: "toi", label: "TOI", align: "left" },
              { id: "sv/sh", label: "SV/SH", align: "left" },
              { id: "on", label: "ON", align: "left" },
              { id: "off", label: "OFF", align: "left" },
            ]}
            listData={gameData?.home_goalie_changes?.map(
              (el: any, index: any) => {
                return {
                  team_name_GOALTENDERS: el?.goalie_name,
                  toi: el?.toi,
                  "sv/sh": `${el?.saves}/${el?.shots_against}`,
                  on: el?.on,
                  off: el?.off,
                };
              }
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2.5} sx={{ py: 4 }}>
        <Grid item xs={12} lg={6}>
          <TableTitle
            title={`${gameData?.away_name}`}
            onClick={(e: any) => {
              alert(e.target.innerHTML);
            }}
          />
          <TableMain
            Title="ROSTER"
            headers={[
              { id: "jersey", label: "#", align: "left" },
              { id: "name", label: "Skater", align: "left" },
              { id: "playing", label: "Pos" },
              { id: "goals", label: "G" },
              { id: "assists", label: "A" },
              { id: "pims", label: "PIM" },
              { id: "shots", label: "Shots" },
              { id: "plusminus", label: "+/-" },
            ]}
            listData={covertIntoArry(gameData?.away_skaters)}
          />
          <TableMain
            Title="ROSTER"
            headers={[
              { id: "jersey", label: "#", align: "left" },

              { id: "name", label: "Goalie", align: "left" },
              { id: "goals_against", label: "GA" },
              { id: "toi", label: "Mins" },
              { id: "shots_against", label: "SA" },
              { id: "saves", label: "SVS" },

              { id: "goals", label: "G" },
              { id: "assists", label: "A" },
              { id: "pims", label: "PIM" },
            ]}
            listData={gameData?.away_goalies}
          />
          <TableFooter content={covertIntoArry(gameData?.away_coaches)} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TableTitle
            title={`${gameData?.home_name}`}
            onClick={(e: any) => {
              alert(e.target.innerHTML);
            }}
          />
          <TableMain
            Title="ROSTER"
            headers={[
              { id: "jersey", label: "#", align: "left" },
              { id: "name", label: "Skater", align: "left" },
              { id: "playing", label: "Pos" },
              { id: "goals", label: "G" },
              { id: "assists", label: "A" },
              { id: "pims", label: "PIM" },
              { id: "shots", label: "Shots" },
              { id: "plusminus", label: "+/-" },
            ]}
            listData={covertIntoArry(gameData?.home_skaters)}
          />
          <TableMain
            Title="ROSTER"
            headers={[
              { id: "jersey", label: "#", align: "left" },

              { id: "name", label: "Goalie", align: "left" },
              { id: "goals_against", label: "GA" },
              { id: "toi", label: "Mins" },
              { id: "shots_against", label: "SA" },
              { id: "saves", label: "SVS" },

              { id: "goals", label: "G" },
              { id: "assists", label: "A" },
              { id: "pims", label: "PIM" },
            ]}
            listData={gameData?.home_goalies}
          />
          <TableFooter content={covertIntoArry(gameData?.home_coaches)} />
        </Grid>
      </Grid>

      {/* Note */}
      <Typography
        variant="body1"
        sx={{ textAlign: "center", fontSize: "14px" }}
      >
        NOTE: Players listed in Italics are the starting lineup.
      </Typography>
      {/* Fifth Tables Grid */}
      <Grid container spacing={2.5} sx={{ py: 4 }}>
        <Grid item lg={12} xs={12}>
          <TableTitle title={"ARENA"} />
          <TableMain
            headers={[
              { id: "arena", label: "Arena", align: "left" },
              { id: "attendance", label: "Attendance", align: "left" },
              { id: "start", label: "Start", align: "left" },
              { id: "end", label: "End", align: "left" },
              { id: "length", label: "Length" },
            ]}
            listData={[
              {
                arena: gameData?.location,
                attendance: gameData?.attendance,
                start: gameData?.actual_start_time,
                end: gameData?.actual_end_time,
                length: gameData?.actual_length,
              },
            ]}
          />
        </Grid>
      </Grid>

      {/* Sixth Tables Grid */}
      <Grid container spacing={2.5} sx={{ py: 4 }}>
        <Grid item lg={12} xs={12}>
          <TableTitle title={"OFFICIALS"} />
          <TableMain
            headers={[
              { id: "role", label: "Role", align: "left" },
              { id: "officials", label: "Name", align: "left" },
            ]}
            listData={offiicialArray()}
          />
        </Grid>
      </Grid>

      {/* Hide Show Tables */}
      <Grid container spacing={2.5} sx={{ py: 3 }}>
        <Grid item lg={12} xs={12}>
          <Accordion
            sx={{
              width: "100%",
              overflow: "hidden",
              borderRadius: 0,
            }}
            expanded={isExpanded}
          >
            <AccordionSummary
              className={`wtd-statview-header-color`}
              sx={{
                ...CommonStyle.tableHeader,
                maxHeight: "auto",
                flexDirection: "row-reverse",
                ".MuiAccordionSummary-content.Mui-expanded": {
                  m: 0,
                  p: 0,
                },
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <Typography
                align={"left"}
                sx={{
                  ...CommonStyle.tableHeader,
                  fontWeight: 700,
                  bgcolor: "transparent",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                &nbsp; PLAY BY PLAY
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box>
                  <Box>
                    <ToggleSwitch
                      label={"1st"}
                      switch={switches.first}
                      setSwitch={() =>
                        setSwitches({
                          ...switches,
                          first: !switches.first,
                        })
                      }
                    />
                    <ToggleSwitch
                      label={"2nd"}
                      switch={switches.second}
                      setSwitch={() =>
                        setSwitches({
                          ...switches,
                          second: !switches.second,
                        })
                      }
                    />
                    <ToggleSwitch
                      label={"3rd"}
                      switch={switches.third}
                      setSwitch={() =>
                        setSwitches({
                          ...switches,
                          third: !switches.third,
                        })
                      }
                    />
                    <ToggleSwitch
                      label={"OT"}
                      switch={switches.ot}
                      setSwitch={() =>
                        setSwitches({
                          ...switches,
                          ot: !switches.ot,
                        })
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      py: 2,
                    }}
                  >
                    <ToggleSwitch
                      label={"Goals"}
                      switch={switches.goals}
                      setSwitch={() =>
                        setSwitches({
                          ...switches,
                          goals: !switches.goals,
                        })
                      }
                    />
                    <ToggleSwitch
                      label={"Penalties"}
                      switch={switches.panelties}
                      setSwitch={() =>
                        setSwitches({
                          ...switches,
                          panelties: !switches.panelties,
                        })
                      }
                    />
                    <ToggleSwitch
                      label={"Shots"}
                      switch={switches.Shots}
                      setSwitch={() =>
                        setSwitches({
                          ...switches,
                          Shots: !switches.Shots,
                          Save: !switches.Save,
                        })
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <Grid item xs={12} lg={12}>
                {fiilterArray(gameData?.events)
                  .filter((el: any) => {
                    if (!switches?.third) {
                      return el?.key != 3;
                    }
                    return el;
                  })
                  .filter((el: any) => {
                    if (!switches?.first) {
                      return el?.key != 1;
                    }
                    return el;
                  })
                  .filter((el: any) => {
                    if (!switches?.second) {
                      return el?.key != 2;
                    }
                    return el;
                  })
                  .filter((el: any) => {
                    if (!switches?.ot) {
                      return el?.key != "OT1";
                    }
                    return el;
                  })
                  .map((el: any, index: any) => (
                    <PlayByPlayTable
                      el={el}
                      switches={switches}
                      index={index}
                    />
                  ))}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      {/* Seventh Tables Grid */}
      <Grid container spacing={1} sx={{ py: 2 }}>
        <Grid item xs={12}>
          <TableTitle title={"HEAD TO HEAD"} />
        </Grid>
        <Grid container spacing={2.5} sx={{ py: 2 }}>
          <Grid item lg={6} xs={12}>
            <HBHTableOne
              header={"Current Season"}
              listData={[
                {
                  team1_src: gameData?.away_logo,
                  team1_scores: `${gameData?.away_current_season_record?.wins}-${gameData?.away_current_season_record?.losses}-${gameData?.away_current_season_record?.ot_losses}-${gameData?.away_current_season_record?.so_losses}`,
                  team2_src: gameData?.home_logo,
                  team1: gameData?.away_ab,
                  team2: gameData?.home_ab,
                  team2_scores: `${gameData?.home_current_season_record?.wins}-${gameData?.home_current_season_record?.losses}-${gameData?.home_current_season_record?.ot_losses}-${gameData?.home_current_season_record?.so_losses}`,
                },
              ]}
            />
            <HBHTableOne
              header={"Previous Season"}
              listData={[
                {
                  team1_src: gameData?.away_logo,
                  team1: gameData?.away_ab,
                  team2: gameData?.home_ab,
                  team1_scores: `${gameData?.away_previous_season_record?.wins}-${gameData?.away_previous_season_record?.losses}-${gameData?.away_previous_season_record?.ot_losses}-${gameData?.away_previous_season_record?.so_losses}`,
                  team2_src: gameData?.home_logo,
                  team2_scores: `${gameData?.home_previous_season_record?.wins}-${gameData?.home_previous_season_record?.losses}-${gameData?.home_previous_season_record?.ot_losses}-${gameData?.home_previous_season_record?.so_losses}`,
                },
              ]}
            />
            <HBHTableOne
              header={"Last 5 Seasons"}
              listData={[
                {
                  team1_src: gameData?.away_logo,
                  team1: gameData?.away_ab,
                  team2: gameData?.home_ab,
                  team1_scores: `${gameData?.away_last_5_seasons_record?.wins}-${gameData?.away_last_5_seasons_record?.losses}-${gameData?.away_last_5_seasons_record?.ot_losses}-${gameData?.away_last_5_seasons_record?.so_losses}`,
                  team2_src: gameData?.home_logo,
                  team2_scores: `${gameData?.home_last_5_seasons_record?.wins}-${gameData?.home_last_5_seasons_record?.losses}-${gameData?.home_last_5_seasons_record?.ot_losses}-${gameData?.home_last_5_seasons_record?.so_losses}`,
                },
              ]}
            />
            <HBHTableOne
              header={"All Seasons"}
              listData={[
                {
                  team1_src: gameData?.away_logo,
                  team1: gameData?.away_ab,
                  team2: gameData?.home_ab,
                  team1_scores: `${gameData?.away_all_seasons_record?.wins}-${gameData?.away_all_seasons_record?.losses}-${gameData?.away_all_seasons_record?.ot_losses}-${gameData?.away_all_seasons_record?.so_losses}`,
                  team2_src: gameData?.home_logo,
                  team2_scores: `${gameData?.home_all_seasons_record?.wins}-${gameData?.home_all_seasons_record?.losses}-${gameData?.home_all_seasons_record?.ot_losses}-${gameData?.home_all_seasons_record?.so_losses}`,
                },
              ]}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <HBHTableTwo
              header={"Previous meeting this season"}
              listData={gameData?.current_season_past_meetings?.map(
                (el: any, index: any) => {
                  return {
                    team1_src: el?.away_smlogo,
                    score_board: `${el?.away_goals}:${el?.home_goals}`,
                    team2_src: el?.home_smlogo,
                    date: el?.date,
                    team1: el?.away_ab,
                    team2: el?.home_ab,
                    mode: el?.status,
                  };
                }
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Eighth Tables Grid */}
      <Grid container sx={{ py: 4 }}>
        <Grid container sx={{ pr: 1 }} xs={12} md={6}>
          <Grid item xs={12} lg={6}>
            <TableTitle title={"TOP SCORERS"} />
            <TSTable
              headers={[
                { id: "team1", label: gameData?.away_name, align: "left" },
              ]}
              listData={gameData?.away_top_scorers?.filter(
                (el: any, index: any) => index <= 4 && el
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box sx={{ visibility: "hidden" }}>
              <TableTitle title={"TOP SCORERS"} />
            </Box>
            <TSTable
              headers={[
                { id: "team1", label: gameData?.home_name, align: "left" },
              ]}
              listData={gameData?.home_top_scorers?.filter(
                (el: any, index: any) => index <= 4 && el
              )}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ pr: 1 }} xs={12} md={6}>
          <Grid item xs={12} lg={6}>
            <TableTitle title={"LAST 5 GAMES"} />
            <LastGamesTable
              headers={[
                { id: "team1", label: gameData?.away_name, align: "left" },
              ]}
              listData={gameData?.away_past_5?.filter(
                (el: any, index: any) => index <= 4 && el
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box sx={{ visibility: "hidden" }}>
              <TableTitle title={"TOP SCORERS"} />
            </Box>
            <LastGamesTable
              headers={[
                { id: "team1", label: gameData?.home_name, align: "left" },
              ]}
              listData={gameData?.home_past_5?.filter(
                (el: any, index: any) => index <= 4 && el
              )}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* Last Tables Grid */}
      <Grid container spacing={1} sx={{ py: 4 }}>
        <Grid item lg={12} xs={12}>
          <TableTitle title={"MATCH UP"} />
          <TableMain
            headers={[
              { id: "type", label: "", align: "left" },
              { id: "team1", label: gameData?.away_name, align: "left" },
              { id: "team2", label: gameData?.home_name, align: "left" },
            ]}
            listData={[
              {
                type: "Season Record",
                team1: `${gameData?.away_match_up?.full_season?.wins}-${gameData?.away_match_up?.full_season?.losses}-${gameData?.away_match_up?.full_season?.ot_losses}-${gameData?.away_match_up?.full_season?.so_losses}`,
                team2: `${gameData?.home_match_up?.full_season?.wins}-${gameData?.home_match_up?.full_season?.losses}-${gameData?.home_match_up?.full_season?.ot_losses}-${gameData?.home_match_up?.full_season?.so_losses}`,
              },
              {
                type: "Last 10 Games",
                team1: `${gameData?.away_match_up?.last_10?.wins}-${gameData?.away_match_up?.last_10?.losses}-${gameData?.away_match_up?.last_10?.ot_losses}-${gameData?.away_match_up?.last_10?.so_losses}`,
                team2: `${gameData?.home_match_up?.last_10?.wins}-${gameData?.home_match_up?.last_10?.losses}-${gameData?.home_match_up?.last_10?.ot_losses}-${gameData?.home_match_up?.last_10?.so_losses}`,
              },
              {
                type: "Streak",
                team1: `${gameData?.away_match_up?.streak?.wins}-${gameData?.away_match_up?.streak?.losses}-${gameData?.away_match_up?.streak?.ot_losses}-${gameData?.away_match_up?.streak?.so_losses}`,
                team2: `${gameData?.home_match_up?.streak?.wins}-${gameData?.home_match_up?.streak?.losses}-${gameData?.home_match_up?.streak?.ot_losses}-${gameData?.home_match_up?.streak?.so_losses}`,
              },
              {
                type: "Last Game",
                team1: `${gameData?.away_match_up?.last_game?.away_goals}-${gameData?.away_match_up?.last_game?.home_goals} ${gameData?.away_match_up?.last_game?.result} ${gameData?.away_match_up?.last_game?.opp_seperator} ${gameData?.away_match_up?.last_game?.opp_name} (${gameData?.away_match_up?.last_game?.date})`,
                team2: `${gameData?.home_match_up?.last_game?.away_goals}-${gameData?.home_match_up?.last_game?.home_goals} ${gameData?.home_match_up?.last_game?.result} ${gameData?.home_match_up?.last_game?.opp_seperator} ${gameData?.home_match_up?.last_game?.opp_name} (${gameData?.home_match_up?.last_game?.date})`,
              },
              {
                type: "Home Record",
                team1: `${gameData?.away_match_up?.home_record?.wins}-${gameData?.away_match_up?.home_record?.losses}-${gameData?.away_match_up?.home_record?.ot_losses}-${gameData?.away_match_up?.home_record?.so_losses}`,
                team2: `${gameData?.home_match_up?.home_record?.wins}-${gameData?.home_match_up?.home_record?.losses}-${gameData?.home_match_up?.home_record?.ot_losses}-${gameData?.home_match_up?.home_record?.so_losses}`,
              },
              {
                type: "Away Record",
                team1: `${gameData?.away_match_up?.away_record?.wins}-${gameData?.away_match_up?.away_record?.losses}-${gameData?.away_match_up?.away_record?.ot_losses}-${gameData?.away_match_up?.away_record?.so_losses}`,
                team2: `${gameData?.home_match_up?.away_record?.wins}-${gameData?.home_match_up?.away_record?.losses}-${gameData?.home_match_up?.away_record?.ot_losses}-${gameData?.home_match_up?.away_record?.so_losses}`,
              },

              {
                type: "Goals For",
                team1: `${gameData?.away_match_up?.goals_for}`,
                team2: `${gameData?.home_match_up?.goals_for}`,
              },
              {
                type: "Goals Against",
                team1: `${gameData?.away_match_up?.goals_against}`,
                team2: `${gameData?.home_match_up?.goals_against}`,
              },
              {
                type: "Power Plays",
                team1: `${gameData?.away_match_up?.pp_pk?.power_play_goals} /${gameData?.away_match_up?.pp_pk?.power_play_opps}(${gameData?.away_match_up?.pp_pk?.pp_pct}%)`,
                team2: `${gameData?.home_match_up?.pp_pk?.power_play_goals} /${gameData?.home_match_up?.pp_pk?.power_play_opps}(${gameData?.home_match_up?.pp_pk?.pp_pct}%)`,
              },
              {
                type: "Power Plays (Home)",
                team1: `${gameData?.away_match_up?.home_pp_pk?.power_play_goals} /${gameData?.away_match_up?.home_pp_pk?.power_play_opps}(${gameData?.away_match_up?.home_pp_pk?.pp_pct}%)`,
                team2: `${gameData?.home_match_up?.home_pp_pk?.power_play_goals} /${gameData?.home_match_up?.home_pp_pk?.power_play_opps}(${gameData?.home_match_up?.home_pp_pk?.pp_pct}%)`,
              },
              {
                type: "Power Plays (Away)",
                team1: `${gameData?.away_match_up?.away_pp_pk?.power_play_goals} /${gameData?.away_match_up?.away_pp_pk?.power_play_opps}(${gameData?.away_match_up?.away_pp_pk?.pp_pct}%)`,
                team2: `${gameData?.home_match_up?.away_pp_pk?.power_play_goals} /${gameData?.home_match_up?.away_pp_pk?.power_play_opps}(${gameData?.home_match_up?.away_pp_pk?.pp_pct}%)`,
              },
              {
                type: "Penalty Killing",
                team1: `${gameData?.away_match_up?.pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.pp_pk?.penalty_kills}(${gameData?.away_match_up?.pp_pk?.pk_pct}%)`,
                team2: `${gameData?.home_match_up?.pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.pp_pk?.penalty_kills}(${gameData?.home_match_up?.pp_pk?.pk_pct}%)`,
              },
              {
                type: "Penalty Killing (Home)",
                team1: `${gameData?.away_match_up?.home_pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.home_pp_pk?.penalty_kills}(${gameData?.away_match_up?.home_pp_pk?.pk_pct}%)`,
                team2: `${gameData?.home_match_up?.home_pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.home_pp_pk?.penalty_kills}(${gameData?.home_match_up?.home_pp_pk?.pk_pct}%)`,
              },
              {
                type: "Penalty Killing (Away)",
                team1: `${gameData?.away_match_up?.away_pp_pk?.penalty_kill_goals_against} /${gameData?.away_match_up?.away_pp_pk?.penalty_kills}(${gameData?.away_match_up?.away_pp_pk?.pk_pct}%)`,
                team2: `${gameData?.home_match_up?.away_pp_pk?.penalty_kill_goals_against} /${gameData?.home_match_up?.away_pp_pk?.penalty_kills}(${gameData?.home_match_up?.away_pp_pk?.pk_pct}%)`,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GameCenterBoard;
