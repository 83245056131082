import {
    Avatar,
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
import React from 'react'
import styled from "@emotion/styled";
import { useNavigate, useSearchParams } from "react-router-dom";
import CommonStyle from "../../Common/style/Style";
import { CoachRole, PageRoutes } from "../../utils/constant";
import { type } from "os";

interface Column {
    id:
      | "player_name"
      | "play"
      | "team"
      | "from"
      | "to"
      | "gp"
      | "g"
      | "a"
      | "pts"
      | "team_name"
      | "win"
      | "start"
      | "end"
      | "undefeated"

    label: string;
    align?: "right";
  }
  
  const columns: readonly Column[] = [
    { id: "player_name", label: "Name" },
    // { id: "play", label: "" },
    { id: "team", label: "Team" },
    {
      id: "from",
      label: "From",
    },
    {
      id: "to",
      label: "To",
    },
    {
      id: "gp",
      label: "GP",
    },
    {
      id: "g",
      label: "g",
    },
    {
      id: "a",
      label: "A",
    },
    {
      id: "pts",
      label: "PTS",
    },
  ];
  
  const columnTeam : readonly Column[] = [
    {
      id: "team_name",
      label: "Name",
    },
    {
      id: "win",
      label: "Wins",
    },
    {
      id: "start",
      label: "Start",
    },
    {
      id: "end",
      label: "End",
    },
    {
      id: "undefeated",
      label: "Undefeated",
    },
  ]
  
  export const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#f9f9f9",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
    "&.MuiTableRow-root:hover": {
      backgroundColor: "#cecece",
    },
  }));
  
const StreakTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
    const option = searchParams.get('type');
    const streaktype = searchParams.get("streaktype")
    return (
        <Paper sx={{ width: "100%", overflow: "hidden", borderRadius: 0 }}>
          <TableContainer sx={{ maxHeight: "auto" }}>
            <Table stickyHeader aria-label="sticky table">
              {streaktype=='player'?
                <>
                <TableHead>
                  <TableRow>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          Name
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          Team
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          From
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          To
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          GP
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          G
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          A
                        </TableCell>
                        <TableCell
                          // key={column.id}
                          align="left"
                          sx={CommonStyle.tableHeader}
                          className="wtd-statview-header-color"
                        >
                          PTS
                        </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      className="wtd-statview-table-row-color"
                    >
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <Avatar src="" />
                          <Typography
                            variant="body2"
                            sx={{
                              ":hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontWeight: 600,
                              },
                            }}
                          >
                            Player1
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        TIM
                      </TableCell>
    
                      <TableCell sx={{ py: 1 }} align={"left"}>
                       Jan 13,2024
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        Present
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        21
                      </TableCell>
                      <TableCell sx={{ py: 1}} align={"left"}>
                        14
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        35
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"} >
                        49
                      </TableCell>
                    </StyledTableRow>
                </TableBody>
                </>
                :<>
                <TableHead>
                  <TableRow>
                    <TableCell
                      // key={column.id}
                      align="left"
                      sx={CommonStyle.tableHeader}
                      className="wtd-statview-header-color"
                    >
                      Name
                    </TableCell>
                    <TableCell
                      // key={column.id}
                      align="left"
                      sx={CommonStyle.tableHeader}
                      className="wtd-statview-header-color"
                    >
                      Wins
                    </TableCell>
                    <TableCell
                      // key={column.id}
                      align="left"
                      sx={CommonStyle.tableHeader}
                      className="wtd-statview-header-color"
                    >
                      Start
                    </TableCell>
                    <TableCell
                      // key={column.id}
                      align="left"
                      sx={CommonStyle.tableHeader}
                      className="wtd-statview-header-color"
                    >
                      End
                    </TableCell>
                    <TableCell
                      // key={column.id}
                      align="left"
                      sx={CommonStyle.tableHeader}
                      className="wtd-statview-header-color"
                    >
                      Underfeated
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <StyledTableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      className="wtd-statview-table-row-color"
                    >
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                          <Avatar src="" />
                          <Typography
                            variant="body2"
                            sx={{
                              ":hover": {
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontWeight: 600,
                              },
                            }}
                          >
                            Team1
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        12
                      </TableCell>
    
                      <TableCell sx={{ py: 1 }} align={"left"}>
                       Jan 22/23
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        Feb 25/23
                      </TableCell>
                      <TableCell sx={{ py: 1 }} align={"left"}>
                        12
                      </TableCell>
                    </StyledTableRow>
                </TableBody>
                </>}
            </Table>
          </TableContainer>
        </Paper>
      );
}

export default StreakTable