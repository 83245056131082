import React from "react";
import { Box, Typography } from "@mui/material";
import PlayerStat from "../../Stats/PlayerStat";
import CommonStyle from "../../../Common/style/Style";

const TeamStatsPage = ({ teamLogo }: any) => {
  return (
    <>
      <Box>
        <Typography
          sx={CommonStyle.tableTitle}
          className="wtd-statview-group-header-color"
        >
          Skater Stats
        </Typography>
        <PlayerStat
          teamLogo={teamLogo}
          isGoalie={false}
          isHeaderVisible={false}
          isAllTeam={false}
        />
      </Box>
      <br />
      <br />
      <Box>
        <Typography
          sx={CommonStyle.tableTitle}
          className="wtd-statview-group-header-color"
        >
          Goalie Stats
        </Typography>
        <PlayerStat
          teamLogo={teamLogo}
          isGoalie={true}
          isHeaderVisible={false}
          isAllTeam={false}
        />
      </Box>
    </>
  );
};

export default TeamStatsPage;
