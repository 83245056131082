import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Avatar,
  Select,
  MenuItem,
  ButtonGroup,
  Button,
  CircularProgress,
  Link,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonStyle from "../../Common/style/Style";
import styled from "@emotion/styled";
import SelectDropdownLeague from "../../Common/SelectDropdownLeague";
import { makeGetRequest } from "../../service";
import { useSearchParams } from "react-router-dom";
import { optionForStanding, PageRoutes } from "../../utils/constant";
import handleSort from "../../hooks/useSort";
import SelectDropdownStat from "../../Common/SelectDropdownStat";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

interface Column {
  id:
    | ""
    | "rank"
    | "name"
    | "games_played"
    | "total_wins"
    | "losses"
    | "otlosses"
    | "so_losses"
    | "pts"
    | "otwins"
    | "goals_for"
    | "goals_against"
    | "plusminus"
    | "pts_pct"
    | "pims"
    | "wins"
    | "rw"
    | "row"
    | "streak"
    | "past_10";

  label: string;
  align?: "center" | "left" | "right";
  sort?: "asc" | "desc";
}

interface SpecialColumn {
  id:
    | ""
    | "rank"
    | "name"
    | "games_played"
    | "power_play_opps"
    | "power_play_goals"
    | "total_wins"
    | "power_play_pct"
    | "penalty_kill_ga"
    | "power_play_gaa"
    | "penalty_kills"
    | "power_play_ga"
    | "penalty_kill_goals"
    | "penalty_kill_pct";
  label: string;
  align?: "center" | "left" | "right";
  sort?: "asc" | "desc";
}

const columns: readonly Column[] = [
  { id: "rank", label: "" },
  { id: "", label: "" },
  { id: "name", label: "Division" },
  { id: "games_played", label: "GP", align: "center", sort: "desc" },
  {
    id: "total_wins",
    label: "W",
    align: "center",
    sort: "desc",
  },
  {
    id: "losses",
    label: "L",
    align: "center",
    sort: "asc",
  },
  {
    id: "otlosses",
    label: "OTL",
    align: "center",
    sort: "asc",
  },
  {
    id: "so_losses",
    label: "SOL",
    align: "center",
    sort: "asc",
  },
  {
    id: "pts",
    label: "PTS",
    align: "center",
    sort: "desc",
  },
  { id: "otwins", label: "OTW", align: "center", sort: "desc" },
  { id: "goals_for", label: "GF", align: "center", sort: "desc" },
  { id: "goals_against", label: "GA", align: "center", sort: "asc" },
  { id: "plusminus", label: "DIFF", align: "center", sort: "desc" },
  {
    id: "pts_pct",
    label: "PCT",
    align: "center",
    sort: "desc",
  },
  {
    id: "pims",
    label: "PIM",
    align: "center",
    sort: "desc",
  },
  {
    id: "rw",
    label: "RW",
    align: "center",
    sort: "desc",
  },
  {
    id: "row",
    label: "ROW",
    align: "center",
    sort: "desc",
  },
  {
    id: "streak",
    label: "STK",
    align: "center",
  },
  {
    id: "past_10",
    label: "P10",
    align: "center",
  },
];

const specialColumns: readonly SpecialColumn[] = [
  { id: "rank", label: "" },
  { id: "", label: "" },
  { id: "name", label: "Division" },
  { id: "games_played", label: "GP", align: "center", sort: "desc" },
  {
    id: "power_play_opps",
    label: "PPOP",
    align: "center",
    sort: "desc",
  },
  {
    id: "power_play_goals",
    label: "PPGF",
    align: "center",
    sort: "desc",
  },
  {
    id: "power_play_ga",
    label: "SHGA",
    align: "center",
    sort: "asc",
  },
  {
    id: "power_play_pct",
    label: "PP%",
    align: "center",
    sort: "desc",
  },
  {
    id: "penalty_kills",
    label: "TSH",
    align: "center",
    sort: "desc",
  },
  { id: "penalty_kill_ga", label: "PPGA", align: "center", sort: "asc" },
  { id: "penalty_kill_goals", label: "SHGF", align: "center", sort: "desc" },
  { id: "penalty_kill_pct", label: "PK%", align: "center", sort: "desc" },
];

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#f9f9f9",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.MuiTableRow-root:hover": {
    backgroundColor: "#cecece",
  },
}));

const StandingStat = ({ isTeam }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSkeleton, setIsSkeleton] = useState(false);
  const [league, setLeague] = useState([]);
  const [stats, setStats] = useState([]);
  const [currentLeague, setCurrentLeague] = useState<any>({});
  const [standingData, setStandingData] = useState([] as any);
  const [leagueData, setLeagueData] = useState([]);
  const [leagueName, setLeagueName] = useState("");
  const [leagueDataForSpeacilTeams, setLeagueDataForSpecialTeam] = useState([]);
  const [specialTeamData, setSpecialTeamData] = useState([] as any);
  const level_id = searchParams.get("level_id");
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: string;
  }>({
    key: searchParams.get("sort") || "",
    direction: searchParams.get("direction") || "desc",
  });
  const [standingType, setStandingType] = useState(
    searchParams.get("type") || "division"
  );
  const season_id = searchParams.get("season_id");
  const stat_class = searchParams.get("stat_class");
  const standingMode = searchParams.get("mode") || "overall";
  const isLegue = searchParams.get("type") == "league" ? true : false;
  const isSpeacial =
    searchParams.get("special_team") == "yes" ||
    (!searchParams.get("special_team") && isTeam)
      ? true
      : false;
  const handleChangeButton = (type: any) => {
    if (searchParams.get("type") != type) {
      fetachSeason();
    }
    searchParams.set("type", type);

    setSearchParams(searchParams);

    setStandingType(type);
  };

  const handleChangeMode = (mode: string) => {
    if (searchParams.get("mode") != mode) {
      fetachSeason();
    }

    searchParams.set("mode", mode);

    setSearchParams(searchParams);
  };
  const sortTable = (key: string, flag: any, sort: string) => {
    searchParams.set("sort", key);
    if (flag == "filter") {
      if (key) {
        // eslint-disable-next-line no-lone-blocks
        {
          (isSpeacial ? specialColumns : columns).forEach((column) => {
            if (column.id == key) {
              const elementsList: any = document.querySelectorAll(
                `#${column.id}`
              );
              const elementsArray = [...elementsList];
              elementsArray.forEach((element) => {
                element?.classList.add(
                  "wtd-statview-table-highlight-column-color"
                );
              });
            } else {
              const elementsList: any = document.querySelectorAll(
                `#${column.id}`
              );
              const elementsArray = [...elementsList];
              elementsArray.forEach((element) => {
                element?.classList.remove(
                  "wtd-statview-table-highlight-column-color"
                );
              });
            }
          });
        }

        searchParams.set("direction", sort);
        setSearchParams(searchParams);

        setSortConfig({ key, direction: sort });
      } else {
        searchParams.delete("sort");
        searchParams.delete("direction");
        fetachSeason();
      }
    } else {
      (isSpeacial ? specialColumns : columns).forEach((column) => {
        if (column.id == key) {
          const elementsList: any = document.querySelectorAll(`#points`);
          const elementsArray = [...elementsList];
          elementsArray.forEach((element) => {
            element?.classList.add("wtd-statview-table-highlight-column-color");
          });
        }
      });

      const currentSortDirection = searchParams.get("direction");
      if (sortConfig.key === key) {
        setSortConfig({
          key,
          direction: currentSortDirection === "asc" ? "desc" : "asc",
        });
        searchParams.set(
          "direction",
          currentSortDirection === "asc" ? "desc" : "asc"
        );
      } else {
        searchParams.set("direction", sort);
        setSortConfig({ key, direction: sort });
      }
      setSearchParams(searchParams);
    }
  };

  const filteredData = (data: any, plays: any) =>
    data?.filter((resItem: any) => plays.includes(resItem.plays));
  const filterDataCoches = (data: any) => {
    return data?.filter((resItem: any) => resItem.coach == "1");
  };

  const handleChangeFilter = (e: any) => {
    searchParams.set("standing_filter", e.target.value);
    const target = e.target.value;
    const value = target.split("-");

    if (value.includes("spacial")) {
      searchParams.set("special_team", "yes");
      searchParams.set("mode", value[1]);
      searchParams.set("sort", value[0]);
      fetchDataforSpecialTeam(
        searchParams.get("season_id"),
        searchParams.get("stat_class")
      );
      setTimeout(() => {
        const elementsList: any = document.querySelectorAll(`#${value[0]}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.add("wtd-statview-table-highlight-column-color");
        });
      }, 1);
      searchParams.set("direction", "desc");
      setSearchParams(searchParams);
    } else if (value.includes("filter")) {
      searchParams.set("mode", value[1]);
      searchParams.set("sort", value[0]);
      searchParams.delete("special_team");

      setTimeout(() => {
        const id: any = searchParams.get("sort");
        const elementsList: any = document.querySelectorAll(`#${id}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.add("wtd-statview-table-highlight-column-color");
        });
      }, 1);
      setSearchParams(searchParams);
    } else {
      searchParams.set("mode", value[1]);
      searchParams.set("type", value[0]);
      setSearchParams(searchParams);
      fetachSeason();
    }
  };
  const handleChange = (event: any) => {
    const params: any = {
      season_id: event.target.value,
      league_id: window?.customconfiguration?.league_id || 4,
    };
    const filterData = league?.filter(
      (el: any) => el.season_id == event.target.value
    );

    setCurrentLeague(filterData[0]);
    if (isSpeacial) {
      fetchDataforSpecialTeam(
        event.target.value,
        searchParams.get("stat_class")
      );
    } else {
      fetchData(event.target.value, searchParams.get("stat_class"));
    }

    setMultiplaleParams(params);
  };

  const handleStatChange = (event: any) => {
    const params: any = {
      season_id: searchParams.get("season_id"),
      league_id: window?.customconfiguration?.league_id || 4,
      stat_class: event.target.value,
    };
    if (isSpeacial) {
      fetchDataforSpecialTeam(
        searchParams.get("season_id"),
        event.target.value
      );
    } else {
      fetchData(searchParams.get("season_id"), event.target.value);
    }

    setMultiplaleParams(params);
  };

  const fetchDataforSpecialTeam = async (
    season_id: any,
    stat: string | null
  ) => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id,
      season_id: season_id,
      do_conf: 1,
    };
    setIsSkeleton(true);
    if (stat) {
      qurey.stat_class = stat;
    }
    try {
      const res: any = await makeGetRequest(
        "get_special_teams_stats",
        new URLSearchParams(qurey).toString()
      );
      setTimeout(() => {
        const id: any = searchParams.get("sort") || "pts";
        const elementsList: any = document.querySelectorAll(`#${id}`);
        const elementsArray = [...elementsList];
        elementsArray.forEach((element) => {
          element?.classList.add("wtd-statview-table-highlight-column-color");
        });
      }, 1);
      if (searchParams.get("type") == "league") {
        const StaticArray: any = new Array(1).fill(0);
        setSpecialTeamData([{ conferences: StaticArray }]);
        let mapedArray: any = [];

        res?.special_teams_stats?.conferences?.forEach((el: any) => {
          el?.teams?.forEach((elment: any) => mapedArray.push(elment));
        });
        setTimeout(() => {
          const id: any = searchParams.get("sort") || "pts";
          const elementsList: any = document.querySelectorAll(`#${id}`);
          const elementsArray = [...elementsList];
          elementsArray.forEach((element) => {
            element?.classList.add("wtd-statview-table-highlight-column-color");
          });
        }, 1);
        fetchData(
          searchParams.get("season_id"),
          searchParams.get("stat_class")
        );

        setLeagueDataForSpecialTeam(mapedArray);
      } else {
        setTimeout(() => {
          const id: any = searchParams.get("sort") || "pts";
          const elementsList: any = document.querySelectorAll(`#${id}`);
          const elementsArray = [...elementsList];
          elementsArray.forEach((element) => {
            element?.classList.add("wtd-statview-table-highlight-column-color");
          });
        }, 1);
        setSpecialTeamData([res?.special_teams_stats]);
      }

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  const fetchData = async (season_id: any, stat_id: any) => {
    let qurey: any = {
      league_id: window?.customconfiguration?.league_id || 4,
      season_id: season_id,
      stat_class: stat_id,
    };
    if (level_id) {
      qurey.level_id = level_id;
    }
    setIsSkeleton(true);
    try {
      const res = await makeGetRequest(
        "get_standings",
        new URLSearchParams(qurey).toString()
      );
      if (!searchParams.get("type")) {
        searchParams.set("type", "division");
        setSearchParams(searchParams);
      }
      if (!searchParams.get("mode")) {
        searchParams.set("mode", "overall");
        setSearchParams(searchParams);
      }

      if (searchParams.get("type") == "league") {
        const StaticArray: any = new Array(1).fill(0);
        setStandingData([{ conferences: StaticArray }]);
        let mapedArray: any = [];

        res?.standings?.leagues[0].levels?.forEach((ele: any) => {
          ele?.conferences?.forEach((el: any) => {
            el?.teams?.forEach((elment: any) => mapedArray.push(elment));
          });
        });
        setTimeout(() => {
          const id: any = searchParams.get("sort") || "pts";
          const elementsList: any = document.querySelectorAll(`#${id}`);
          const elementsArray = [...elementsList];
          elementsArray.forEach((element) => {
            element?.classList.add("wtd-statview-table-highlight-column-color");
          });
        }, 1);
        setLeagueName(res?.standings?.leagues[0]?.league_name);
        setLeagueData(mapedArray);
      } else {
        setTimeout(() => {
          const id: any = searchParams.get("sort") || "pts";
          const elementsList: any = document.querySelectorAll(`#${id}`);
          const elementsArray = [...elementsList];
          elementsArray.forEach((element) => {
            element?.classList.add("wtd-statview-table-highlight-column-color");
          });
        }, 1);

        setStandingData(res?.standings?.leagues[0].levels);
      }
      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  const fetachSeason = async () => {
    setIsSkeleton(true);
    const qureyforSeason: any = {
      league_id: window?.customconfiguration?.league_id,
    };
    if (level_id) {
      qureyforSeason.level_id = level_id;
    }
    try {
      const response = await makeGetRequest(
        "get_leagues",
        new URLSearchParams(qureyforSeason).toString()
      );
      const defaultStat = response.leagues[0].stat_classes.filter(
        (stat: any) =>
          stat.stat_class_id == response.leagues[0].default_stat_class_tag
      );
      setStats(response.leagues[0].stat_classes);
      setLeague(response.leagues[0].seasons);

      let filterdSeasonIdForTeam;
      if (
        searchParams.get("team_id") != "all-teams" &&
        searchParams.get("team_id")
      ) {
        const res = await makeGetRequest(
          "get_team_info",
          `team_id=${searchParams.get("team_id")}`
        );

        filterdSeasonIdForTeam = res?.leagues[0]?.current_season;
      }
      const filterSeasonId =
        searchParams.get("season_id") ||
        response?.leagues[0]?.current_season ||
        filterdSeasonIdForTeam ||
        response.leagues[0].seasons[0].season_id;
      const qurey: any = {
        league_id: window?.customconfiguration?.league_id || 4,
        season_id: filterSeasonId,
        stat_class:
          searchParams.get("stat_class") || defaultStat[0].stat_class_id,
      };

      if (!searchParams.get("season_id") || !searchParams.get("stat_class")) {
        setMultiplaleParams(qurey);
      }

      const filterData = response.leagues[0].seasons?.filter(
        (el: any) => el.season_id == filterSeasonId
      );

      setCurrentLeague(filterData[0]);
      if (isSpeacial) {
        fetchDataforSpecialTeam(
          filterSeasonId,
          searchParams.get("stat_class") || defaultStat[0].stat_class_id
        );
      } else {
        await fetchData(
          filterSeasonId,
          searchParams.get("stat_class") || defaultStat[0].stat_class_id
        );
      }

      setIsSkeleton(false);
    } catch (error) {
      setIsSkeleton(false);
    }
  };
  const setMultiplaleParams = (params: any) => {
    for (const key in params) {
      searchParams.set(key, params[key]);
      setSearchParams(searchParams);
    }
  };

  const division =
    searchParams.get("type") == "division" || !searchParams.get("type")!
      ? true
      : false;
  useEffect(() => {
    fetachSeason();
  }, []);

  const newStandingData = (data: any) => {
    return data?.map((league: any) => ({
      ...league,
      teams: league?.teams?.map((item: any) => ({
        ...item,
        rw: item.wins,
        row: item.wins + item.otwins,
        percentage: (Number(item.games_played) !== 0
          ? Number(item.wins) / Number(item.games_played)
          : 0
        )?.toFixed(3),
      })),
    }));
  };

  const newLeagueData = leagueData?.map((league: any) => ({
    ...league,
    rw: league.wins,
    row: league.wins + league.otwins,
    percentage: (Number(league?.games_played) !== 0
      ? Number(league.wins) / Number(league.games_played)
      : 0
    )?.toFixed(3),
  }));
  const newLeagueDataSpeacialTeam = leagueDataForSpeacilTeams?.map(
    (league: any) => ({
      ...league,
    })
  );

  const newSpecialTeamData = (teams: any) => {
    const filterdArray = teams?.map((team: any) => ({
      ...team,
    }));
    return filterdArray;
  };

  return (
    <>
      <Box
        className="wtd-statview-filter"
        sx={{
          ...CommonStyle.tableControl,
          display: "flex",
          gap: 1,
          px: 1,
          flexWrap: "wrap",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Select
          id="demo-simple-select-autowidth"
          autoWidth
          displayEmpty
          value={searchParams.get("standing_filter") || ""}
          size="small"
          onChange={(e: any) => handleChangeFilter(e)}
          defaultValue={""}
          sx={{
            minWidth: "180px",
            ".MuiSelect-select": {
              py: "4px",
            },
            border: "none",
            backgroundColor: "#fff",
            borderRadius: "0",
          }}
        >
          {optionForStanding?.map((el) => (
            <MenuItem key={el.key} value={el.key}>
              {el.lable}
            </MenuItem>
          ))}
        </Select>
        <SelectDropdownLeague league={league} handleChange={handleChange} />
        <SelectDropdownStat stats={stats} handleChange={handleStatChange} />
        <ButtonGroup
          sx={{
            boxShadow: "none",
            borderColor: "transparent",
            border: "1px solid transparent",
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "transparent",
            },
          }}
          variant="contained"
        >
          <Button
            className={`wtd-statview-button-color ${division ? "active" : ""}`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: division ? "#ffb931" : "#000000",
              fontWeight: division ? 600 : 200,
              color: division ? "#000000" : "",
            }}
            onClick={() => {
              handleChangeButton("division");
              setStandingType("division");
            }}
          >
            Division
          </Button>

          <Button
            className={`wtd-statview-button-color ${isLegue ? "active" : ""}`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: isLegue ? "#ffb931" : "#000000",
              fontWeight: isLegue ? 600 : 200,
              color: isLegue ? "#000000" : "",
            }}
            onClick={() => {
              setStandingType("league");
              if (
                (searchParams.get("type") != "league" &&
                  (searchParams.get("direction") == "asc" &&
                    searchParams.get("sort")) != "pts") ||
                !searchParams.get("direction")
              ) {
                sortTable("pts", "table", "desc");
              }
              handleChangeButton("league");
            }}
          >
            League
          </Button>
        </ButtonGroup>
        <ButtonGroup
          sx={{
            boxShadow: "none",
            borderColor: "transparent",
            border: "1px solid transparent",
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "transparent",
            },
          }}
          variant="contained"
        >
          <Button
            className={`wtd-statview-button-color ${
              standingMode == "overall" ? "active" : ""
            }`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor:
                standingMode == "overall" ? "#ffb931" : "#000000",
              fontWeight: standingMode == "overall" ? 600 : 200,
              color: standingMode == "overall" ? "#000000" : "",
            }}
            onClick={() => {
              handleChangeMode("overall");
            }}
          >
            Overall
          </Button>
          <Button
            className={`wtd-statview-button-color ${
              standingMode == "home" ? "active" : ""
            }`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: standingMode == "home" ? "#ffb931" : "#000000",
              fontWeight: standingMode == "home" ? 600 : 200,
              color: standingMode == "home" ? "#000000" : "",
            }}
            onClick={() => {
              handleChangeMode("home");
            }}
          >
            Home
          </Button>
          <Button
            className={`wtd-statview-button-color ${
              standingMode == "away" ? "active" : ""
            }`}
            sx={{
              ...CommonStyle.btnSwitch,
              backgroundColor: standingMode == "away" ? "#ffb931" : "#000000",
              fontWeight: standingMode == "away" ? 600 : 200,
              color: standingMode == "away" ? "#000000" : "",
            }}
            onClick={() => {
              handleChangeMode("away");
            }}
          >
            Away
          </Button>
        </ButtonGroup>
        <Button
          className={`wtd-statview-button-color ${isSpeacial ? "active" : ""}`}
          sx={{
            ...CommonStyle.btnSwitch,
            backgroundColor: isSpeacial ? "#ffb931" : "#000000",
            fontWeight: isSpeacial ? 600 : 200,
            color: isSpeacial ? "#000000" : "#ffffff",
          }}
          onClick={() => {
            if (
              searchParams.get("special_team") == "yes" ||
              (isTeam && !searchParams.get("special_team"))
            ) {
              searchParams.set("special_team", "no");
              setSearchParams(searchParams);
              fetchData(
                searchParams.get("season_id"),
                searchParams.get("stat_class")
              );
            } else {
              searchParams.set("special_team", "yes");
              setSearchParams(searchParams);
              fetchDataforSpecialTeam(
                searchParams.get("season_id"),
                searchParams.get("stat_class")
              );
            }
          }}
        >
          Special Teams
        </Button>
      </Box>
      {isSkeleton ? (
        <Box sx={{ position: "relative", height: "80vh" }}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
      ) : (
        (isSpeacial ? specialTeamData : standingData)?.map((ele: any) => (
          <>
            {ele?.level_name &&
              window?.customconfiguration?.show_level_name && (
                <TableRow>
                  <TableCell
                    sx={CommonStyle.tableTitle}
                    className="wtd-statview-group-header-color"
                    colSpan={
                      isSpeacial ? specialColumns?.length : columns?.length
                    }
                  >
                    {(isSpeacial ? specialTeamData : standingData)?.length === 1
                      ? window?.customconfiguration?.show_level_name
                      : ele?.level_name}
                  </TableCell>
                </TableRow>
              )}
            {newStandingData(ele.conferences)?.map((el: any) => {
              return (
                <Paper
                  sx={{
                    width: "100%",
                    overflow: "hidden",
                    borderRadius: 0,
                  }}
                >
                  <TableContainer sx={{ maxHeight: "auto" }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {(isSpeacial
                            ? specialColumns
                            : Object.keys(
                                (isLegue && isSpeacial
                                  ? newLeagueDataSpeacialTeam
                                  : isSpeacial
                                  ? newSpecialTeamData(el.teams)
                                  : isLegue
                                  ? newLeagueData
                                  : el?.teams)[0]
                              ).includes("cbd")
                            ? [
                                ...columns.slice(0, 13),
                                {
                                  id: "cbd",
                                  label: "CBD",
                                  align: "center",
                                  sort: "desc",
                                },
                                ...columns.slice(13),
                              ]
                            : columns
                          )?.map((column) => (
                            <TableCell
                              id={column.id}
                              key={column.id}
                              align={
                                column.align as "center" | "right" | "left"
                              }
                              sx={{
                                ...CommonStyle.tableHeader,
                                padding: "0px 5px 0px 5px",
                                backgroundColor:
                                  column.id !== "" &&
                                  sortConfig.key === column.id
                                    ? "#cecece"
                                    : "#ffb931",
                              }}
                              className="wtd-statview-header-color"
                            >
                              {["rank", "name", "streak", "past_10"].includes(
                                column.id
                              ) ? (
                                column.id == "name" ? (
                                  isSpeacial && isLegue ? (
                                    leagueName
                                  ) : isSpeacial ? (
                                    el?.conf_name
                                  ) : isLegue ? (
                                    leagueName
                                  ) : (
                                    el?.name
                                  )
                                ) : (
                                  column.label
                                )
                              ) : (
                                <a
                                  style={{
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: column.align,
                                  }}
                                  onClick={() =>
                                    sortTable(
                                      column.id,
                                      "table",
                                      column.sort ? column.sort : "desc"
                                    )
                                  }
                                >
                                  {column.label}
                                  {column.id !== "" &&
                                    sortConfig.key === column.id && (
                                      <span style={{ display: "flex" }}>
                                        {sortConfig.direction === "asc" ? (
                                          <ArrowDropUpIcon />
                                        ) : (
                                          <ArrowDropDownIcon />
                                        )}
                                      </span>
                                    )}
                                </a>
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {(isLegue && isSpeacial
                          ? newLeagueDataSpeacialTeam
                          : isSpeacial
                          ? newSpecialTeamData(el.teams)
                          : isLegue
                          ? newLeagueData
                          : el?.teams
                        )
                          ?.sort((a: any, b: any) =>
                            handleSort(a, b, sortConfig, standingMode)
                          )
                          ?.map((standingItem: any, index: number) => {
                            if (!isSpeacial) {
                              return (
                                <StyledTableRow
                                  hover
                                  key={index + 1}
                                  role="checkbox"
                                  tabIndex={-1}
                                  className="wtd-statview-table-row-color"
                                >
                                  <TableCell sx={{ py: 1 }} align={"center"}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell sx={{ py: 1 }} align={"center"}>
                                    {standingItem?.playoff_flag}
                                  </TableCell>
                                  <TableCell sx={{ py: 1 }} align={"left"}>
                                    <Link
                                      href={`${PageRoutes.TeamDetail}?team_id=${
                                        standingItem.id
                                      }&season_id=${season_id}${
                                        stat_class
                                          ? `&stat_class=${stat_class}`
                                          : ""
                                      }`}
                                      sx={{
                                        cursor: "pointer",
                                        display: "flex",
                                        color: "black",
                                        textDecoration: "none",
                                        alignItems: "center",
                                        gap: 1,
                                        width: "300px",
                                        "&:hover": {
                                          fontWeight: 600,
                                          textDecoration: "underline",
                                        },
                                      }}
                                    >
                                      <Avatar src={standingItem?.logo} />
                                      {standingItem.name}
                                    </Link>
                                  </TableCell>
                                  <TableCell
                                    id={"games_played"}
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "games_played"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.games_played}
                                  </TableCell>
                                  <TableCell
                                    id="total_wins"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "total_wins"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_total_wins
                                      : standingMode == "away"
                                      ? standingItem.away_total_wins
                                      : standingItem.total_wins}
                                  </TableCell>
                                  <TableCell
                                    id="losses"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "losses"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_losses
                                      : standingMode == "away"
                                      ? standingItem.away_losses
                                      : standingItem.losses}
                                  </TableCell>
                                  <TableCell
                                    id="otlosses"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "otlosses"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_otlosses
                                      : standingMode == "away"
                                      ? standingItem.away_otlosses
                                      : standingItem.otlosses}
                                  </TableCell>
                                  <TableCell
                                    id="so_losses"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "so_losses"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_so_losses
                                      : standingMode == "away"
                                      ? standingItem.away_so_losses
                                      : standingItem.so_losses}
                                  </TableCell>
                                  <TableCell
                                    id="pts"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "pts"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.pts}
                                  </TableCell>
                                  <TableCell
                                    id="otwins"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "otwins"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_otwins
                                      : standingMode == "away"
                                      ? standingItem.away_otwins
                                      : standingItem.otwins}
                                  </TableCell>
                                  <TableCell
                                    id="goals_for"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "goals_for"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.goals_for}
                                  </TableCell>
                                  <TableCell
                                    id="goals_against"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "goals_against"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.goals_against}
                                  </TableCell>
                                  <TableCell
                                    id="plusminus"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "plusminus"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.plusminus}
                                  </TableCell>
                                  {Object.keys(
                                    (isLegue && isSpeacial
                                      ? newLeagueDataSpeacialTeam
                                      : isSpeacial
                                      ? newSpecialTeamData(el.teams)
                                      : isLegue
                                      ? newLeagueData
                                      : el?.teams)[0]
                                  ).includes("cbd") && (
                                    <TableCell
                                      id="cbd"
                                      sx={{
                                        py: 1,
                                        backgroundColor:
                                          sortConfig.key === "cbd"
                                            ? "#cecece"
                                            : "",
                                      }}
                                      align={"center"}
                                    >
                                      {standingItem.cbd}
                                    </TableCell>
                                  )}
                                  <TableCell
                                    id="pts_pct"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "pts_pct"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem?.pts_pct}
                                  </TableCell>
                                  <TableCell
                                    id="pims"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "pims"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.pims}
                                  </TableCell>
                                  <TableCell
                                    id="rw"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "rw"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.rw}
                                  </TableCell>
                                  <TableCell
                                    id="row"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "row"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.row}
                                  </TableCell>
                                  <TableCell
                                    id="streak"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "streak"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.streak}
                                  </TableCell>
                                  <TableCell
                                    id="past_10"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "past_10"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.past_10}
                                  </TableCell>
                                </StyledTableRow>
                              );
                            } else {
                              return (
                                <StyledTableRow
                                  hover
                                  key={index + 1}
                                  role="checkbox"
                                  tabIndex={-1}
                                  className="wtd-statview-table-row-color"
                                >
                                  <TableCell sx={{ py: 1 }} align={"center"}>
                                    {index + 1}
                                  </TableCell>
                                  <TableCell sx={{ py: 1 }} align={"center"}>
                                    {standingItem?.playoff_flag}
                                  </TableCell>
                                  <TableCell sx={{ py: 1 }} align={"left"}>
                                    <Link
                                      href={`${PageRoutes.TeamDetail}?team_id=${
                                        standingItem.team_id
                                      }&season_id=${season_id}${
                                        stat_class
                                          ? `&stat_class=${stat_class}`
                                          : ""
                                      }`}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        color: "black",
                                        textDecoration: "none",
                                        gap: 1,
                                        minWidth: "300px",
                                      }}
                                    >
                                      <Avatar src={standingItem.smlogo} />
                                      {standingItem.team_name}
                                    </Link>
                                  </TableCell>
                                  <TableCell
                                    id="games_played"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "games_played"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingItem.games_played}
                                  </TableCell>
                                  <TableCell
                                    id="power_play_opps"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "power_play_opps"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_power_play_opps
                                      : standingMode == "away"
                                      ? standingItem.away_power_play_opps
                                      : standingItem.power_play_opps}
                                  </TableCell>
                                  <TableCell
                                    id="power_play_goals"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "power_play_goals"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_power_play_goals
                                      : standingMode == "away"
                                      ? standingItem.away_power_play_goals
                                      : standingItem.power_play_goals}
                                  </TableCell>
                                  <TableCell
                                    id="power_play_ga"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "power_play_ga"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_power_play_ga
                                      : standingMode == "away"
                                      ? standingItem.away_power_play_ga
                                      : standingItem.power_play_ga}
                                  </TableCell>
                                  <TableCell
                                    id="power_play_pct"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "power_play_pct"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_power_play_pct
                                      : standingMode == "away"
                                      ? standingItem.away_power_play_pct
                                      : standingItem.power_play_pct}
                                  </TableCell>
                                  <TableCell
                                    id="penalty_kills"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "penalty_kills"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_penalty_kills
                                      : standingMode == "away"
                                      ? standingItem.away_penalty_kills
                                      : standingItem.penalty_kills}
                                  </TableCell>
                                  <TableCell
                                    id="penalty_kill_ga"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "penalty_kill_ga"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_penalty_kill_ga
                                      : standingMode == "away"
                                      ? standingItem.away_penalty_kill_ga
                                      : standingItem.penalty_kill_ga}
                                  </TableCell>
                                  <TableCell
                                    id="penalty_kill_goals"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "penalty_kill_goals"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_penalty_kill_goals
                                      : standingMode == "away"
                                      ? standingItem.away_penalty_kill_goals
                                      : standingItem.penalty_kill_goals}
                                  </TableCell>
                                  <TableCell
                                    id="penalty_kill_pct"
                                    sx={{
                                      py: 1,
                                      backgroundColor:
                                        sortConfig.key === "penalty_kill_pct"
                                          ? "#cecece"
                                          : "",
                                    }}
                                    align={"center"}
                                  >
                                    {standingMode == "home"
                                      ? standingItem.home_penalty_kill_pct
                                      : standingMode == "away"
                                      ? standingItem.away_penalty_kill_pct
                                      : standingItem.penalty_kill_pct}
                                  </TableCell>
                                </StyledTableRow>
                              );
                            }
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              );
            })}
          </>
        ))
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          py: 2,
          px: 1,
        }}
      >
        <Box>x = Clinched Playoff Position</Box>
        <Box>y = Clinched Division</Box>
        <Box>z = Regular Season Champion</Box>
      </Box>
    </>
  );
};

export default StandingStat;
