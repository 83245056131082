import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  TableCell,
  Avatar,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Link,
} from "@mui/material";
import CommonStyle from "../../../Common/style/Style";
import { StyledTableRow } from "../../TableComponent/Table";
import { useState } from "react";
import { PageRoutes } from "../../../utils/constant";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const ScoringTableAccordian = ({ element, index }: any) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  function returnPlusMinusData(array1: any, array2: any) {
    let mixedArray = [];
    const length = Math.min(array1.length, array2.length);

    for (let i = 0; i < length; i++) {
      let mixedObject = { ...array1[i], ...array2[i] };
      mixedArray.push(mixedObject);
    }

    return mixedArray;
  }

  return (
    <Accordion expanded={isExpanded}>
      <AccordionSummary
        className={`wtd-statview-header-color`}
        sx={{
          maxHeight: "auto",

          p: 0,
          ".MuiAccordionSummary-content.Mui-expanded": {
            p: 0,
          },
        }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <StyledTableRow
          hover
          key={index + 1}
          tabIndex={-1}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: 1,
          }}
          className={`wtd-statview-table-row-color`}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <TableCell
              sx={{
                py: 1,
                width: "20px",
              }}
              align={"left"}
            >
              {element?.time_in}
            </TableCell>

            <TableCell
              sx={{
                py: 1,
              }}
              align={"left"}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box>
                  <Avatar
                    sx={{ width: "40px", height: "40px" }}
                    src={
                      element?.team_logo
                        ? element?.team_logo
                        : "https://images-us-east.htptv.net/wtd/assets/no-image-icon-6.png"
                    }
                  />
                </Box>
                <Box>
                  <Avatar
                    src={element?.goal_player_image}
                    sx={{ width: "58px", height: "58px" }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    sx={{ whiteSpace: { xs: "nowrap", md: "normal" } }}
                  >
                    <strong>
                      <Link
                        sx={{ color: "black", textDecoration: "none" }}
                        href={`${PageRoutes.PlayerProfile}?player_id=${element.goal_player_id}`}
                      >
                        {element?.goal_player_id && element?.goal_player_name}(
                        {element?.goal_prior})
                      </Link>
                    </strong>{" "}
                    <span>
                      <Link
                        sx={{ color: "black", textDecoration: "none" }}
                        href={`${PageRoutes.PlayerProfile}?player_id=${element.ass1_player_id}`}
                      >
                        {element?.ass1_player_id != 0 &&
                          element?.ass1_player_name != 0 &&
                          `${element?.ass1_player_name} (${element?.ass1_prior})`}
                      </Link>{" "}
                    </span>
                    <span>
                      <Link
                        sx={{ color: "black", textDecoration: "none" }}
                        href={`${PageRoutes.PlayerProfile}?player_id=${element.ass2_player_id}`}
                      >
                        {element?.ass2_player_id != 0 &&
                          `${element?.ass2_player_name} (${element?.ass2_prior})`}
                      </Link>
                    </span>
                  </Typography>
                </Box>
              </Box>
            </TableCell>
          </Box>
          <TableCell
            sx={{
              py: 1,
              visibility:
                element?.plus?.length > 0 && element?.minus?.length > 0
                  ? "visible"
                  : "hidden",
            }}
            align={"left"}
          >
            {element?.plus?.length > 0 && element?.minus?.length > 0 && (
              <Typography
                variant="body1"
                sx={{ fontWeight: 600, cursor: "pointer" }}
                onClick={() => setIsExpanded(!isExpanded)}
              >
                +/-
              </Typography>
            )}
          </TableCell>
        </StyledTableRow>
      </AccordionSummary>
      <AccordionDetails>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell
                className={"wtd-statview-header-color"}
                align={"left"}
                sx={{
                  ...CommonStyle.tableHeader,
                }}
              >
                Plus
              </TableCell>
              <TableCell
                className={"wtd-statview-header-color"}
                align={"left"}
                sx={{
                  ...CommonStyle.tableHeader,
                }}
              >
                Minus
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returnPlusMinusData(element?.minus, element?.plus)?.map(
              (data: any, i: any) => (
                <StyledTableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index + 1}
                  className={`wtd-statview-table-row-color`}
                >
                  <TableCell
                    sx={{
                      py: 1,
                    }}
                    align="left"
                  >
                    #{data?.plus_player_jersey} {data?.plus_player_name}
                  </TableCell>
                  <TableCell
                    sx={{
                      py: 1,
                    }}
                    align="left"
                  >
                    #{data?.minus_player_jersey} {data?.minus_player_name}
                  </TableCell>
                </StyledTableRow>
              )
            )}
          </TableBody>
        </Table>
      </AccordionDetails>
    </Accordion>
  );
};
export default ScoringTableAccordian;
