import { SxProps } from "@mui/material";

const CommonStyle: { [key: string]: SxProps } = {
  tableTitle: {
    backgroundColor: "#6d451b",

    color: "#ffffff",
    height: "30px",

    lineHeight: "30px !important",
    padding: "0 7px",
  },
  tableHeader: {
    backgroundColor: "#ffb931",
    color: "#000000",
    py: 0,
    whiteSpace: "nowrap",
    lineHeight: "30px",
    verticalAlign: "middle",
    fontSize: "100%",
    fontWeight: "400",
  },
  tableControl: {
    backgroundColor: "#000000",
    color: "#ffffff",
    py: 1,
  },
  btnSwitch: {
    backgroundColor: "#000000",
    borderRadius: "0",

    "&:hover": {
      backgroundColor: "#ffb931",
      color: "#000000",
    },
  },
};

export default CommonStyle;
