import { Box, Typography } from "@mui/material";
import { onValue, ref } from "firebase/database";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { db } from "../../../service";

const Centers = ({ element }: any) => {
  const [liveData, setLiveData] = useState<any>({});
  function startup_new(channels: any) {
    var i = 0;

    for (i = 0; i < channels.length; i++) {
      /* Firebase mode */
      if (channels[i].game_id > 0)
        var firebase_channel =
          "games/" + channels[i].channel + "/" + channels[i].game_id;
      else var firebase_channel = "rinks/" + channels[i].channel;

      var rink_ref = ref(db, firebase_channel);
      console.log("firebase Starting " + firebase_channel);
      (function (prefix, game) {
        var last_game_id = 0;
        var requested_game = game;
        if (requested_game) {
          // GetGameData(prefix + "_", requested_game);
          // GetRosterData(requested_game);
          last_game_id = requested_game;
        }

        onValue(rink_ref, (snapshot: any) => {
          const fb_data = snapshot.val();
          if (fb_data == null) return;

          const data = fb_data.clock_data;

          if (fb_data.clock_data == null) return;

          if (requested_game && data.game_id != requested_game) {
            return;
          }
          if (data.rosters == 1 || data.events == 1) {
            // getGameDetailsLive();
          }
          setLiveData(data);
          // process_scoreboard_data(prefix + "_", data);
          if (last_game_id != data.game_id) {
            last_game_id = data.game_id;
          }
        });
      })(channels[i].prefix, channels[i].game_id);
    }
  }

  useEffect(() => {
    const channel = {
      game_id: element.game_id,
      channel_type: 2,
      prefix: `${element?.game_channel}_${element?.game_id}`,
      channel: element?.game_channel,
    };
    const array = [channel];

    startup_new(array);
  }, [element]);
  return (
    <Box
      sx={{
        flexBasis: "40%",
        width: "40%",
        fontSize: "12px !important",
        textAlign: "center",
        padding: "0 4px",
      }}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: "12px",
          fontWeight: "700",
          lineHeight: "1.5",
        }}
      >
        {liveData?.period != " " && liveData?.period
          ? liveData?.period
          : element?.game_status == "NOT STARTED"
          ? `${moment(element?.time, "hh:mm a").format("hh:mm A")} ${
              element?.timezn_ab
            }`
          : element?.status}
      </Typography>
      {liveData?.clock && element?.game_status == "OPEN" && (
        <Typography variant="h1">{liveData?.clock}</Typography>
      )}
      {(liveData?.homescore || liveData?.awayscore) &&
        `${moment(element?.time, "hh:mm a").format("hh:mm A")} 
      ${element?.timezn_ab}`}
    </Box>
  );
};

export default Centers;
