import React from "react";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  styled,
  useMediaQuery,
} from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "100%",
  border: "1px solid black",
  borderRadius: "1.5rem",
}));

const CardDetail = ({ title, data }: any) => {
  const isMobile = useMediaQuery("(max-width:500px)");
  return (
    <StyledCard>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontSize: { lg: "1.5rem", md: "1.3rem" },
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {data?.map((el: any) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  px: 2,
                  py: 1,
                  display: "flex",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                {el?.player_image ? (
                  <img
                    style={{
                      objectFit: "cover",
                      width: isMobile ? 60 : 70,
                      height: isMobile ? 60 : 70,
                    }}
                    src={el?.player_image}
                    alt={el?.player_name}
                  />
                ) : (
                  <Avatar
                    sx={{
                      borderRadius: "0px",
                      width: isMobile ? 60 : 70,
                      height: isMobile ? 60 : 70,
                    }}
                  ></Avatar>
                )}

                <Box>
                  <Typography sx={{ fontSize: "1.2rem" }}>
                    {el?.fname}
                  </Typography>
                  <Typography sx={{ fontSize: "1.2rem" }}>
                    {el?.lname}
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: { md: "1.6rem", xs: "1.3rem" },
                  fontWeight: 600,
                }}
              >
                {title === "Goals"
                  ? el?.goals
                  : title === "Points"
                  ? el?.points
                  : title === "Assists"
                  ? el?.assists
                  : title === "Game Winning Goals"
                  ? el?.gwg
                  : title === "Wins"
                  ? el?.wins
                  : title === "Save Percentage"
                  ? el?.save_pct
                  : title === "Shutouts"
                  ? el?.shutouts
                  : el?.goals_against_ave}
              </Typography>
            </Box>
          );
        })}
      </CardContent>
    </StyledCard>
  );
};

export default CardDetail;
