import React from "react";
import { Box, Card, CardContent, Typography, styled } from "@mui/material";

const StyledCard = styled(Card)(({ theme }) => ({
  width: "100%",
  border: "1px solid black",
  borderRadius: "1.5rem",
}));

const SpecialCardDetail = ({ title, subtitle, data }: any) => {
  return (
    <StyledCard>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontSize: { lg: "1.3rem", md: "0.8rem", xs: "1.2rem" },
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            py: { md: 5, xs: 4 },
          }}
        >
          <Typography
            sx={{
              fontSize: { md: "4rem", xs: "2.5rem" },
            }}
          >
            {data}
          </Typography>
        </Box>
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: { md: "1.2rem", xs: "1.1rem" },
          }}
        >
          {subtitle}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default SpecialCardDetail;
