import React from "react";
import { DisplayFlag } from "../../../utils/constant";
import { Box, Typography } from "@mui/material";

const TableDetails = () => {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
          py: 1,
          px: 1,
        }}
      >
        {DisplayFlag?.map((el: any) => (
          <Box>
            <Box
              sx={{
                display: "flex",

                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  border: "1px solid black",
                  borderRadius: "50%",

                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "18px",
                  height: "18px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 800, fontSize: "14px" }}
                >
                  {el.sign}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{ fontWeight: 800, fontSize: "14px" }}
              >
                {el.name}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default TableDetails;
